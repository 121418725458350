import { Badge, Group, LoadingOverlay, Modal, Pagination, Select, SimpleGrid, Stack, Title } from "@mantine/core";
import React, { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { ExpressAmbientPreview } from "../ExpressAmbientPreview";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { Check, X } from "tabler-icons-react";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";

export function ExpressProjectAmbientEdit({expressProject, projectAmbient, isVisible, setVisibility}) {
    
    const resourcePermission = useContext(ResourcePermissionContext);

    const queryClient = useQueryClient();

    const form = useForm({
        initialValues: {
            price: ''
        }
    });

    const [currentPage, setCurrentPage] = useState(0);
    const {data: ambients, isLoading: isLoadingAmbients} = useQuery(['express-ambients-list-data', projectAmbient?.category, currentPage, form.values.price], () => {
        if(!projectAmbient?.category)
            return Promise.resolve();
        
        let url = `/express/search?page=${currentPage}&category=${projectAmbient?.category?.id}`;
        
        if(form.values.price)
            url += '&price=' + form.values.price
        
        return BackendServiceV2.get(url);
    });

    const onAmbientSelect = useMutation((ambient) => {
        return BackendServiceV2.put('/express/project/ambient/' + projectAmbient?.id, {
            idExpressAmbient: ambient?.id
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('express-project-data');
            setVisibility.close();
            showNotification({
                title: 'Ambiente selecionado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {            
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (<>
        <Modal
            opened={isVisible}
            onClose={setVisibility.close}
            title={
                <Stack>
                    <Group position="center">
                        <Title order={2}>{projectAmbient.name}</Title>
                        {projectAmbient?.category && <Badge variant="filled">{projectAmbient?.category?.name}</Badge>}
                    </Group>
                </Stack>
            }
            size="auto"
            centered
            styles={{
                header: {
                    backgroundColor: '#FFF',
                    zIndex: 10,
                    paddingBottom: 0
                },
                close: {
                    alignSelf: 'baseline'
                }
            }}
            closeOnClickOutside={false}
        >
            <Stack>
                <LoadingOverlay visible={isLoadingAmbients} />

                <Group>
                    <Select
                        clearable
                        allowDeselect
                        size="sm"
                        label="Preço"
                        data={[
                            {value: '1', label: '$'},
                            {value: '2', label: '$$'},
                            {value: '3', label: '$$$'},
                        ]}
                        {...form.getInputProps('price', { type: 'select' })}
                    />
                </Group>
                
                <SimpleGrid cols={4}>
                    {ambients?.result?.map(ambient => (
                        <ExpressAmbientPreview 
                            key={ambient.id} 
                            ambient={ambient} 
                            cardHeader={<Badge variant="filled">{ambient?.code}</Badge>}
                            onAmbientSelect={(expressProject?.status === 'OPEN' || resourcePermission.containsPermission('EXPRESS_PROJECT_OPEN')) && resourcePermission.containsPermission('EXPRESS_PROJECT_UPDATE') ? onAmbientSelect : undefined}
                        />
                    ))}
                </SimpleGrid>

                {ambients?.result?.length > 0 &&
                    <Pagination 
                        value={ambients?.pageNumber + 1} 
                        onChange={(page) => {setCurrentPage(page - 1); queryClient.invalidateQueries('express-ambients-list-data')}} 
                        total={ambients?.totalPages} 
                    />
                }
            </Stack>
        </Modal>
    </>)
}