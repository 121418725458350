import { Button, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import { PlayerContext } from "context/Player/PlayerContext";
import Quill from "quill";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";

export function TeleportDetail({teleport}) {

    const queryClient = useQueryClient();

    const playerContext = useContext(PlayerContext);

    const form = useForm({
        initialValues: {
            title: teleport?.metadata?.detail?.title || '',
            description: teleport?.metadata?.detail?.description || ''
        }
    });

    useEffect(() => {
        if(!teleport?.metadata?.detail) {
            createTeleportDetail();
        }
    // eslint-disable-next-line
    }, []);

    const {mutate: createTeleportDetail} = useMutation(() => {
        return BackendServiceV2.post('/detail/', {
            title: '',
            description: '',
            idTeleport: teleport?.id
        });
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-data');
            playerContext?.player?.reload();
        },
        onError: (error) => {

        }
    });

    const {mutate: updateTeleportDetail, isLoading} = useMutation((values) => {
        return BackendServiceV2.put('/detail/' + teleport?.metadata?.detail?.idDetail, values);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-data');
            playerContext?.player?.reload();

            showNotification({
                title: 'Teleport atualizado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    // QUILL CONFIGURATION
    const [quill, setQuill] = useState(null);
    const descriptionEditorRef = useRef(null);

    useEffect(() => {
        if(quill !== null)
            quill.root.innerHTML = teleport?.metadata?.detail?.description ? teleport?.metadata?.detail?.description : '';
    // eslint-disable-next-line
    }, [quill, teleport])

    useEffect(() => {
        if(descriptionEditorRef.current !== null && !quill) {
            let editor = new Quill(descriptionEditorRef.current, {
                theme: 'snow',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'align': [] }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'size': ['small', false, 'large', 'huge'] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['link'],
                        ['image'],
                        ['clean']
                    ]
                }
            });

            setQuill(editor);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descriptionEditorRef.current]);

    const onEditorTextChange = useCallback(async (value, delta, source) => {
        form.setFieldValue('description', quill.root.innerHTML)
    // eslint-disable-next-line
    }, [quill]);

    useEffect(() => {
        if(quill != null) {
            quill.on('text-change', onEditorTextChange);
        }
    }, [quill, onEditorTextChange, form?.values?.description]);

    // AUTO SAVE
    const [debouncedFormValues] = useDebouncedValue(form?.values, 3000);

    useEffect(() => {
        if(!teleport?.metadata?.detail?.idDetail) return;

        if(!teleport?.metadata?.detail && !form.validate())
            return;

        if (teleport?.metadata?.detail?.title !== debouncedFormValues.title || teleport?.metadata?.detail?.description !== debouncedFormValues.description)
            updateTeleportDetail(debouncedFormValues);
    // eslint-disable-next-line
    }, [debouncedFormValues])

    return (
        <SectionBox
            title="Detalhes" 
            description="Descrição detalhada do seu Teleport" 
            className="full-width"
            isLoading={isLoading}
            right={(
                <Button form="detail-teleport-data-form" type="submit">Salvar</Button>
            )}
        >
            <form id="detail-teleport-data-form" onSubmit={form.onSubmit(values => updateTeleportDetail(values))}>
                <Stack spacing={15}>
                    <TextInput
                        label="Título"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('title')}
                    />

                    <Stack spacing={0}>
                        <Text weight={500} style={{fontFamily: "'Inter', Helvetica, Arial, sans-serif"}}>Descrição</Text>
                        <div className="text-editor" ref={descriptionEditorRef}></div>
                    </Stack>
                </Stack>
            </form>
        </SectionBox>
    )
}