import React from 'react';
import { SectionBox } from 'components/v2/SectionBox';
import { useForm } from '@mantine/form';
import { Plus, X } from 'tabler-icons-react';
import { Button, Select, Stack, TextInput } from '@mantine/core';
import { useMutation } from 'react-query';
import BackendServiceV2 from 'services/v2/BackendService';
import { showNotification } from '@mantine/notifications';

export function TeleportCreate({idDevelopment}) {

    const form = useForm({
        initialValues: {
            type: '',
            metadata: {
                title: ''
            },
            idDevelopment: idDevelopment,
            embed: ''
        },
        validate: {
            metadata: {
                title: (value) => (!value ? 'Título não pode ser vazio' : null)
            }
        }
    });

    const {mutate: createTeleport, isLoading} = useMutation((data) => {
        return BackendServiceV2.post('/teleport', data)
    }, {
        onSuccess: (response) => {
            window.location.href = window.ENV.BaseURL + '/teleport/' + response?.id;
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (
        <SectionBox title="Novo Teleport" icon={<Plus />} isLoading={isLoading}>
            <form onSubmit={form.onSubmit(values => createTeleport(values))}>
                <Stack>
                    <TextInput
                        required
                        label="Título"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('metadata.title')}
                    />

                    <Select
                        required
                        label="Tipo"
                        placeholder="Selecione um"
                        size="md"
                        data={[
                            {value: 'PROJECT', label: 'Project'},
                            {value: 'SCAN', label: 'Scan'}
                        ]}
                        {...form.getInputProps('type', { type: 'select' })}
                    />

                    {form.getInputProps('type').value === 'SCAN' &&
                        <TextInput
                            required
                            label="Embed"
                            placeholder=""
                            size="md"
                            {...form.getInputProps('embed')}
                        />
                    }

                    <Button type="submit">Criar</Button>
                </Stack>
            </form>
        </SectionBox>
    );
}