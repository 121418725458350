import { ActionIcon, Button, Group, Image, LoadingOverlay, Modal, Progress, Space, Switch, Table, Text, Textarea, Title, useMantineTheme } from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { useDebouncedValue, useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, FileUpload, Mail, Trash, Variable, X } from "tabler-icons-react";

export function SalesTeamEmailConfiguration({salesTeam}) {

    const theme = useMantineTheme();
    const queryClient = useQueryClient();

    // HTML INPUT
    const [useHTML, useHTMLHandle] = useDisclosure(salesTeam.useHTML);
    const [htmlContent, setHTMLContent] = useState(salesTeam.html);

    const [debounceHTML] = useDebouncedValue(htmlContent, 1000);

    // VARIABLE MODAL
    const [variableModal, variableModalHandler] = useDisclosure(false);

    // UPDATE SALES TEAM
    const updateSalesTeam = useMutation((values) => {
        return BackendServiceV2.put('/salesteam/' + salesTeam.id, values);
    }, {
        onSuccess: success => {
            queryClient.invalidateQueries('sale-team');
        },
        onError: error => {
            showNotification({
                color: 'red',
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                icon: <X />
            });
        }
    })

    // UPDATE HTML STATE
    useEffect(() => {
        updateSalesTeam.mutate({useHTML: useHTML});
    // eslint-disable-next-line
    }, [useHTML]);

    // HTML CONTENT
    useEffect(() => {
        updateSalesTeam.mutate({html: debounceHTML});
    // eslint-disable-next-line
    }, [debounceHTML])

    // UPLOAD LOGO
    const [uploadProgress, setUploadProgress] = useState(0);

    const uploadSalesTeamLogo = useMutation((formData) => {
        const config = {
            onUploadProgress: progressEvent => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);
            }
        }

        return BackendServiceV2.put('/salesteam/' + salesTeam.id + '/logo', formData, config);
    }, {
        onSuccess: success => {
            showNotification({
                color: 'green',
                title: 'Imagem enviada com sucesso',
                message: '',
                autoClose: 5000,
                icon: <Check />
            });
            setUploadProgress(0);
            queryClient.invalidateQueries('sale-team');
        },
        onError: error => {
            showNotification({
                color: 'red',
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                icon: <X />
            });
        }
    })

    // DELETE LOGO
    const deleteSalesTeamLogo = useMutation(() => {
        return BackendServiceV2.delete('/salesteam/' + salesTeam.id + '/logo');
    }, {
        onSuccess: success => {
            showNotification({
                color: 'green',
                title: success?.message,
                message: '',
                autoClose: 5000,
                icon: <Check />
            });
            queryClient.invalidateQueries('sale-team');
        },
        onError: error => {
            showNotification({
                color: 'red',
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                icon: <X />
            });
        }
    })

    return (
        <SectionBox
            title="Email"
            icon={<Mail />}
            right={<></>}
        >
            <LoadingOverlay visible={updateSalesTeam.isLoading} />

            <Dropzone
                onDrop={(files) => {
                    if(files.length >= 1) {
                        let formData = new FormData();
                        formData.append('image', files[0]);

                        uploadSalesTeamLogo.mutate(formData);
                    } else {
                        showNotification({
                            color: 'red',
                            title: 'Um erro ocorreu',
                            message: 'Nenhum arquivo encontrado para enviar',
                            autoClose: 5000,
                            icon: <X />
                        });
                    }
                }}
                onReject={(files) => {
                    showNotification({
                        color: 'red',
                        title: 'Formato de arquivo inválido',
                        message: '',
                        autoClose: 5000,
                        icon: <X />
                    });
                }}
                maxSize={3 * 1024 ** 2}
                accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
                multiple={false}
            >
                {uploadSalesTeamLogo.isLoading ? 
                    <Progress value={uploadProgress} size="xl" label={uploadProgress + "%"} />
                :
                    <Group position="center" spacing="xl" style={{ minHeight: 100, pointerEvents: 'none' }}>
                        <FileUpload size={40} />
                        <div>
                            <Text size="xl" inline>
                                Solte sua logo ou clique aqui para selecionar um arquivo
                            </Text>
                            <Text size="sm" color="dimmed" inline mt={7}>
                                O arquivo tem quer ter no máximo 5mb e ser no formato .png ou .jpg
                            </Text>
                        </div>
                    </Group>
                }
            </Dropzone>

            {salesTeam.logo && <>
                <Space h="sm" />
                <Group position="apart" style={{padding: theme.spacing.lg, border: '1px solid #eeeeee', borderRadius: '10px'}}>
                    <Image src={window.ENV.StaticURL + salesTeam.logo} height={50} />
                    <ActionIcon title="Remover" onClick={deleteSalesTeamLogo.mutate}><Trash /></ActionIcon>
                </Group>
            </>}

            <Space h="sm" />
            <Group position="apart">
                <Switch 
                    label="Usar HTML personalizado" 
                    checked={useHTML ? useHTML : false}
                    onChange={useHTMLHandle.toggle} 
                />
                {useHTML && <Button variant="subtle" component="a" onClick={variableModalHandler.open}>Variáveis</Button>}
            </Group>

            <Space h="sm" />
            {useHTML &&
                <Textarea
                    autosize
                    minRows={3}
                    value={htmlContent}
                    onChange={(e) => setHTMLContent(e.currentTarget.value)}
                >

                </Textarea>
            }

            <Modal
                opened={variableModal}
                onClose={variableModalHandler.close}
                centered
                size="lg"
                title={<Group><Variable /><Title order={2}>Variáveis</Title></Group>}
            >
                <Table>
                    <thead>
                        <tr>
                            <th>Variável</th>
                            <th>Descrição</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{'{{LOGO_URL}}'}</td>
                            <td>URL para a imagem enviada como logo</td>
                        </tr>
                        <tr>
                            <td>{'{{USER_NAME}}'}</td>
                            <td>Nome do usuário que esta recebendo o email</td>
                        </tr>
                        <tr>
                            <td>{'{{TELEPORT_TITLE}}'}</td>
                            <td>Titulo to Teleport agendado</td>
                        </tr>
                        <tr>
                            <td>{'{{EVENT_DATE}}'}</td>
                            <td>Data do agendamento</td>
                        </tr>
                        <tr>
                            <td>{'{{EVENT_URL}}'}</td>
                            <td>URL para a chamada</td>
                        </tr>
                    </tbody>
                </Table>
            </Modal>
        </SectionBox>
    )
}