import { Badge, Group, LoadingOverlay, Text, Timeline } from "@mantine/core";
import { Error404 } from "components/v2/Dashboard/404";
import { ContentWrapper, DashboardContent, LeftContent, RightContent } from "components/v2/Dashboard/Layout";
import { ExpressAddAmbient } from "components/v2/Express/ExpressAddAmbient";
import { ExpressChangeStatus } from "components/v2/Express/ExpressChangeStatus";
import { ExpressDeleteButton } from "components/v2/Express/ExpressDeleteButton";
import { ExpressProjectAmbientCard } from "components/v2/Express/ExpressProjectAmbientCard";
import { PermissionMenu } from "components/v2/PermissionMenu";
import { SectionBox } from "components/v2/SectionBox";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import BackendServiceV2 from "services/v2/BackendService";
import { Bolt } from "tabler-icons-react";

export function ExpressEditPage() {

    // PARAMS
    const idExpressProject = useParams().idExpressProject;

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    useEffect(() => {
        resourcePermission.setResource('EXPRESS_PROJECT', idExpressProject);
    // eslint-disable-next-line
    }, [idExpressProject]);

    // GET EXPRESS PROJECT
    const {data: expressProject, isLoading, isError} = useQuery(['express-project-data', idExpressProject], () => {
        return BackendServiceV2.get('/express/project/' + idExpressProject);
    });

    if(isLoading || !expressProject) return (
        <DashboardContent>
            <LoadingOverlay visible={isLoading} />
        </DashboardContent>
    )

    if(isError) return (
        <Error404 />
    )

    return (
        <DashboardContent 
            resourceId={expressProject?.id}
            resourceClass="EXPRESS_PROJECT"
            title={expressProject.name} 
            icon={<Bolt size={30} />}
            description={
                <Group spacing={3}>
                    <Badge variant='outline'>{expressProject?.id}</Badge>
                    <Badge variant='filled' color={expressProject?.status === 'OPEN' ? 'green' : 'red'}>{expressProject?.status}</Badge>
                </Group>
            }
            right={<>
                {resourcePermission.containsGroup(['ADMIN']) && <>
                    <ExpressDeleteButton expressProject={expressProject} />
                </>}

                <PermissionMenu resourceClass='EXPRESS_PROJECT' resourceId={expressProject?.id} />
            </>}
        >
            <ContentWrapper>
                <LeftContent>
                    <SectionBox 
                        title="Ambientes" 
                        right={resourcePermission.containsGroup(['ADMIN']) && <ExpressAddAmbient expressProject={expressProject} />}
                    >
                        <Group>
                            {expressProject?.ambients?.map(projectAmbient => (
                                <ExpressProjectAmbientCard key={projectAmbient.id} expressProject={expressProject} projectAmbient={projectAmbient} />
                            ))}
                        </Group>
                    </SectionBox>
                </LeftContent>
                
                <RightContent>
                    {resourcePermission.containsPermission('EXPRESS_PROJECT_CLOSE') && <>
                        <Text weight={600}>O passo a passo do seu projeto</Text>
                        <Timeline lineWidth={3} active={expressProject?.status === 'OPEN' ? 1 : 2}>
                            <Timeline.Item title="Projeto Express iniciado" >
                                <Text color="dimmed" size="sm">O seu projeto foi criado e os ambientes contratados configurados.</Text>
                            </Timeline.Item>
                            <Timeline.Item title="Selecionar ambientes">
                                <Text color="dimmed" size="sm">Clique em cada ambiente contratado e selecione o melhor ambiente para o seu projeto.</Text>
                            </Timeline.Item>
                            <Timeline.Item title="Finalizar projeto">
                                <Text color="dimmed" size="sm" mb={5}>Depois de escolher <b>TODOS</b> os ambientes contratados, finalize a seleção de ambientes do seu projeto para que possamos iniciar a produção do seu Teleport!</Text>
                                <ExpressChangeStatus expressProject={expressProject} />
                            </Timeline.Item>
                        </Timeline>
                    </>}
                </RightContent>
            </ContentWrapper>
        </DashboardContent>
    )
}