import { ActionIcon, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Trash, X } from "tabler-icons-react";

export function ExpressProjectAmbientDelete({projectAmbient}) {

    const queryClient = useQueryClient();
    const modals = useModals();

    const deleteExpressProject = useMutation(() => {
        return BackendServiceV2.delete('/express/project/ambient/' + projectAmbient?.id)
    }, {
        onSuccess: () => {       
            queryClient.invalidateQueries('express-project-data');
            showNotification({
                title: 'Ambiente deletado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const deleteExpressProjectAmbientConfirmation = () => modals.openConfirmModal({
        title: 'Deletar Ambiente',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja deletar o ambiente <b>{projectAmbient.name}</b>?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            deleteExpressProject.mutate();
        }
    });

    return (
        <ActionIcon 
            color="#ff5e00" 
            variant="transparent" 
            onClick={(e) => {
                deleteExpressProjectAmbientConfirmation();
                e.stopPropagation();
            }} 
            title="Deletar"
        >
            <Trash color={projectAmbient?.ambient ? 'white' : 'black'} />
        </ActionIcon>
    )
}