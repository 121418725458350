import { useForceUpdate } from "@mantine/hooks";
import { Client } from "@stomp/stompjs";
import React, { useEffect, useRef } from "react";
import SockJS from "sockjs-client";
import { WebSocketContext } from "./WebSocketContext";

export function WebSocketContextProvider({children}) {

    // FORCE UPDATE
    const forceUpdate = useForceUpdate();

    // WEB SOCKET
    const stompConfig = {
        connectHeaders: {'Authorization': 'Bearer ' + localStorage.getItem('accessToken')},
        brokerURL: window.ENV.BackendURL + '/websocket',
        webSocketFactory: () => {
            return new SockJS(window.ENV.BackendURL + '/websocket');
        },
        debug: function (str) {
            //console.log('STOMP: ' + str);
        },
        reconnectDelay: 200,
        onConnect: (frame) => {
            forceUpdate();
        }
    };

    const stompClient = useRef(new Client(stompConfig));

    useEffect(() => {
        if(!stompClient.current.active)
            stompClient.current.activate();

        forceUpdate();
    // eslint-disable-next-line 
    }, [])

    return (
        <WebSocketContext.Provider value={{client: stompClient.current}}>
            {children}
        </WebSocketContext.Provider>
    );
}