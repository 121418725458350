import { Button, Group, Modal, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React from "react";
import { Plus } from "tabler-icons-react";
import { TeleportLayerCreateAndEdit } from "../TeleportLayerCreateAndEdit";

export function TeleportLayerCreateLayerChildButton({idTeleport, layer}) {

    const [opened, openedHandler] = useDisclosure(false);

    return (<>
        <Button onClick={openedHandler.open}>Adicionar Layer</Button>
        <Modal
            opened={opened}
            onClose={openedHandler.close}
            title={
                <Group><Plus /><Title order={2}>Adicionando layer</Title></Group>
            }
            centered
            size="lg"
        >
            <TeleportLayerCreateAndEdit idTeleport={idTeleport} parentLayer={layer} handler={openedHandler} />
        </Modal>
    </>)
}