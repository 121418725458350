import React, {useCallback, useEffect, useState} from 'react';
import AuthService from '../../services/AuthService';
import { Button, Group, LoadingOverlay, Select, Space, TextInput } from '@mantine/core';
import { At, Check, Users, X } from 'tabler-icons-react';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useMutation } from 'react-query';

function UserForm({ notify }) {

    // ROLES
    const [roles, setRoles] = useState([]);

    const getRoles = useCallback(async () => {
        let response = await AuthService.get('/roles');
        setRoles(response.data); 
    }, []);

    useEffect(() => {
        getRoles();
    }, [getRoles]);

    const form = useForm({
        initialValues: {
          email: '',
          idRole: undefined
        },
    
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'E-mail inválido'),
        },
    });

    const createNewUser = useMutation(values => {
        return AuthService.post('/account/', values);
    }, {
        onSuccess: (response) => {
            showNotification({
                title: <>Usuário adicionado</>,
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            });

            form.reset();
            notify();
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            });         
        }
    })

    return (
        <form onSubmit={form.onSubmit(values => {createNewUser.mutate(values)})}>
            <LoadingOverlay visible={createNewUser.isLoading} />

            <TextInput 
                label="E-mail"
                size="md"
                icon={<At />}
                name="email"
                placeholder="example@example.com"
                required
                {...form.getInputProps('email')}
            />

            <Select
                required
                label="Cargo"
                icon={<Users />}
                placeholder="Selecione um"
                size="md"
                data={roles.map(role => {return {value: role.id, label: role.name}})}
                {...form.getInputProps('idRole')}
            />

            <Space h="lg" />

            <Group position="right">
                <Button type="submit">Criar</Button>
            </Group>
        </form>
    );
}

export default UserForm;