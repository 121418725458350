import { Card, Group, LoadingOverlay, Select, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React, { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";
import { TagsManagerCategoryDeleteTag } from "../TagsManagerCategoryDeleteTag";
import equal from'fast-deep-equal';

export function TagsManagerTagCard({tag}) {

    const queryClient = useQueryClient();

    const form = useForm({
        initialValues: {
            name: tag?.name || ''
        },
        validate: {
            
        }
    });

    const {mutate: editTag, isLoading} = useMutation((data) => {
        return BackendServiceV2.put(`/resource/metadata/${tag.id}`, data);
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries('metadata-category-list-data');
            showNotification({
                title: 'Tag atualizada com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    });

    const [debouncedFormValues] = useDebouncedValue(form.values, 3000);

    useEffect(() => {
        if(!equal({name: tag?.name || ''}, debouncedFormValues))
            editTag(debouncedFormValues);
    // eslint-disable-next-line
    }, [debouncedFormValues])

    return (
        <Card withBorder>
            <LoadingOverlay visible={isLoading} />

            <Stack>
                <form id="metadata-category-create" onSubmit={form.onSubmit(values => editTag(values))}>
                    <Stack>
                        <Group position="apart" grow noWrap >
                            <TextInput 
                                label={<Text>Nome</Text>}
                                {...form.getInputProps('name')}
                            />

                            <Select 
                                label="Tipo"
                                defaultValue={tag.type} 
                                data={[
                                    {value: 'NUMBER', label: 'Número'},
                                    {value: 'TEXT', label: 'Texto'},
                                    {value: 'BOOLEAN', label: 'Booleano'},
                                ]}
                                disabled
                            />
                        </Group>
                    </Stack>
                </form>

                <TagsManagerCategoryDeleteTag tag={tag} />
            </Stack>
        </Card>
    )
}