import { ActionIcon, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Copy, X } from "tabler-icons-react";

export function TeleportDuplicateButton({teleport}) {

    const modals = useModals();

    const duplicateTeleport = useMutation(() => {
        return BackendServiceV2.post('/teleport/' + teleport?.id + '/duplicate')
    }, {
        onSuccess: () => {            
            showNotification({
                title: 'Duplicação de Teleport iniciada',
                message: 'Você será notificado por email quando a duplicação terminar',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const duplicateTeleportConfirmation = () => modals.openConfirmModal({
        title: 'Duplicar Teleport',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja duplicar esse Teleport?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            duplicateTeleport.mutate();
        }
    });

    return (<>
        <ActionIcon color="#ff5e00" variant="subtle" onClick={duplicateTeleportConfirmation} title="Duplicar"><Copy /></ActionIcon>
    </>)
}