import { Button, Group, Pagination, Space, Stack } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { SearchBox } from "components/v2/SearchBox";
import { SectionBox } from "components/v2/SectionBox";
import { TeleportSelectCard } from "components/v2/Teleport/TeleportSelectCard";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Plus, X } from "tabler-icons-react";

export function SalesTeamTeleportLinkList({salesTeam}) {

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // ALL TELEPORTS
    const [currentPage, setCurrentPage] = useState(1);
    const [title, setTitle] = useState(undefined);

    const {data: teleports} = useQuery(['sales-team-all-teleports', currentPage, title], () => {
        let requestURL = '/teleport/?page=' + (currentPage - 1);

        if (title)
            requestURL += '&title=' + title;

        return BackendServiceV2.get(requestURL);
    })

    // LINK TELEPORT
    const linkTeleport = useMutation((idTeleport) => {
        return BackendServiceV2.post('/salesteam/teleport', {idTeleport, idSalesTeam: salesTeam.id});
    }, {
        onSuccess: success => {
            showNotification({
                title: <>Teleport adicionado</>,
                message: 'Teleport adicionado ao time de vendas',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })

            queryClient.invalidateQueries('sales-team-linked-teleports')
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    return (
        <SectionBox>
            <SearchBox onSearch={setTitle} />

            <Stack spacing="lg">
                {teleports && teleports.result.map(teleport => (
                    <Group key={teleport.id} position="apart" noWrap>
                        <TeleportSelectCard value={teleport.id} label={teleport.metadata.title} description={teleport.metadata.subtitle} />
                        
                        <Button 
                            variant="subtle" 
                            leftIcon={<Plus />} 
                            className="no-text" 
                            onClick={(e) => {
                                e.preventDefault(); linkTeleport.mutate(teleport.id);
                            }}
                        >Adicionar</Button>
                    </Group>
                ))}
            </Stack>

            {teleports?.result?.length > 0 && <>
                <Space h="lg" />
                <Pagination 
                    value={teleports.pageNumber + 1} 
                    onChange={(page) => {setCurrentPage(page); queryClient.invalidateQueries('sales-team-all-teleports')}} 
                    total={teleports.totalPages} 
                />
            </>}
        </SectionBox>
    )
}