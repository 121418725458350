import { Stack } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { DndContext, closestCenter,KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove,SortableContext,sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { DraggrableCard } from "components/v2/Draggrable/DraggrableCard";
import Scrollbars from "react-custom-scrollbars-2";
import { restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers";
import { TeleportCarouselAddAndEditGroupButton } from "../TeleportCarouselAddAndEditGroupButton";
import BackendServiceV2 from "services/v2/BackendService";
import { useMutation, useQueryClient } from "react-query";
import { showNotification } from "@mantine/notifications";
import { Check, X } from "tabler-icons-react";
import { TeleportCarouselGroupsDeleteButton } from "../TeleportCarouselGroupsDeleteButton";
import { FixedHeightLeftContent } from "components/v2/Dashboard/Layout";

export function TeleportCarouselGroups({groups, setActiveGroup, idCarousel}) {

    const queryClient = useQueryClient();

    const {mutate: updateCarouselGroupOrder} = useMutation((data) => {
        console.log("SETTING TO ORDER " + data.order);
        return BackendServiceV2.put(`/carousel/group/${data.idCarouselGroup}`, {
            order: data.order
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-data');

            showNotification({
                title: 'Teleport atualizado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 2000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 2000,
            });
        }
    })

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    )

    const [sortableItems, setSortableItems] = useState(groups?.map(entry => (entry?.id)))

    useEffect(() => {
        setSortableItems(groups?.map(entry => (entry?.id)));
    }, [groups])

    function onDragEnd(event) {
        const {active, over} = event;

        if (active.id !== over.id) {
            setSortableItems((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);
                return arrayMove(items, oldIndex, newIndex);
            });

            updateCarouselGroupOrder({idCarouselGroup: active.id, order: over.data.current.sortable.index + 1});
        }
    }

    if(!groups || !sortableItems) return <></>

    return (
        <FixedHeightLeftContent
            title="Grupos"
            right={<TeleportCarouselAddAndEditGroupButton idCarousel={idCarousel} /> }
        >
            <DndContext collisionDetection={closestCenter} sensors={sensors} onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}>
                <SortableContext items={sortableItems}>
                    <Scrollbars height="100%">
                        <Stack>
                            {sortableItems?.map((id) => {
                                let group = groups?.find(entry => entry?.id === id);

                                if(!group) return <></>

                                return (
                                    <DraggrableCard 
                                        onClick={e => setActiveGroup(group)}
                                        key={group.id}
                                        id={group.id}
                                        title={group.title}
                                        image={group.thumb}
                                        actions={[
                                            <TeleportCarouselAddAndEditGroupButton group={group} />,
                                            <TeleportCarouselGroupsDeleteButton group={group} />
                                        ]}
                                    />
                                )
                            })}
                        </Stack>
                    </Scrollbars>
                </SortableContext>
            </DndContext>
        </FixedHeightLeftContent>
    )
}