import { LoadingOverlay, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import React, { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, InfoCircle, X } from "tabler-icons-react";

export function TotemEdit({totem}) {

    const queryClient = useQueryClient();

    const form = useForm({
        initialValues: {
            name: totem?.name
        },
        validate: {
            name: (value) => (!value ? 'Nome não pode ser vazio' : null)
        }
    });

    const [debouncedForm] = useDebouncedValue(form.values, 3000)

    useEffect(() => {
        if(debouncedForm.name !== totem.name)
            editTotem(debouncedForm)
    // eslint-disable-next-line
    }, [debouncedForm])

    const {mutate: editTotem, isLoading} = useMutation((data) => {
        return BackendServiceV2.put('/totem/' + totem.id, data)
    }, {
        onSuccess: (response) => {
            queryClient.invalidateQueries('totem-data');
            showNotification({
                title: 'Showcase editado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (
        <SectionBox title="Informações" icon={<InfoCircle />}>
            <LoadingOverlay visible={isLoading} />
            <form>
                <Stack>
                    <TextInput
                        required
                        label="Nome"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('name')}
                    />
                </Stack>
            </form>
        </SectionBox>
    )
}