import React, {useState, useEffect, useCallback, useContext} from 'react';
import AuthService from '../../../../services/AuthService';
import UserForm from '../../../../components/UserForm';
import UserBox from '../../../../components/UserBox'
import UserService from '../../../../services/UserService';

import './style.css';
import { UserContext } from '../../../../context/User/UserContext';
import { Plus, Search,  Users } from 'tabler-icons-react';
import { Pagination, Space, Stack, TextInput } from '@mantine/core';
import { SectionBox } from 'components/v2/SectionBox';
import { ContentWrapper, DashboardContent, LeftContent, RightContent } from 'components/v2/Dashboard/Layout';

export function UserListPage() {

    // PERMISSIONS
    const [USER_MANAGER, setUserManager] = useState(false);

    useEffect(() => {
        async function getPermissions() {
            setUserManager(await UserService.hasAuthority("USER_MANAGER"));
        }

        getPermissions();
    }, []);

    // LOADING
    const [isLoading, setIsLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(undefined);
    const [totalPages, setTotalPages] = useState(undefined);
    
    const [users, setUsers] = useState([]);
    
    const getAllUsers = useCallback(async (page, name) => {
        try {
            if(page !== undefined) {
                setIsLoading(true);

                let requestURL = '/account?page=' + (page - 1);

                if (name)
                    requestURL += '&name=' + name;

                let response = await AuthService.get(requestURL);

                if(response.status === 200) {
                    setUsers(response.data.content);
                    setTotalPages(response.data.totalPages);
                    setCurrentPage(response.data.pageNumber);
                    setIsLoading(false);
                }
                
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    const userContext = useContext(UserContext);

    const getMe = useCallback(async () => {
        try {
            let response = await AuthService.get('/account/me');
            localStorage.setItem('me', JSON.stringify(response.data));
            userContext.setLoggedUserData(response.data);
            getAllUsers(0);
        } catch (error) {
            console.log(error);
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getMe();
    }, [getMe]);

    function onNewUserCreated() {
        getAllUsers(currentPage);
    }

    // SEARCH
    const [name, setName] = useState(undefined);

    useEffect(() => {
        if(!isLoading) {
            const delayDebounceFn = setTimeout(async () => {
                getAllUsers(0, name);
            }, 1000)
        
            return () => clearTimeout(delayDebounceFn);
        }
    // eslint-disable-next-line
    }, [name]);
    
    return (
        <DashboardContent title="Usuários" icon={<Users size={30} />}>
            <ContentWrapper>
                <LeftContent>
                    {USER_MANAGER &&                    
                        <SectionBox title="Todos os usuários" className="full-width" icon={<Users />} >
                            <TextInput 
                                placeholder="Procurar por nome" 
                                icon={<Search />}
                                size='md'
                                value={name ? name : ''}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />

                            {!isLoading && <>
                                <Space h="lg" />
                                <Stack spacing={0} className='group-link'>
                                    {users.map(user => ( 
                                        <UserBox key={user.id} user={user} />
                                    ))}
                                </Stack>
                            </>}

                            {isLoading &&
                                <div className="loading-data">
                                    <img src={require('../../../../assets/loading.gif')} alt="Loading" />
                                </div>
                            }

                            <Pagination value={currentPage} onChange={getAllUsers} total={totalPages} />
                        </SectionBox>
                    }
                </LeftContent>

                <RightContent>
                    {USER_MANAGER &&
                        <SectionBox title="Criar novo usuário" icon={<Plus />}>
                            <UserForm notify={onNewUserCreated} />
                        </SectionBox>
                    }
                </RightContent>
            </ContentWrapper>
        </DashboardContent>
    )
}