import { Button, Select, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import React from "react";
import { useMutation } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Plus, X } from "tabler-icons-react";

export function TotemInterfaceCreate() {

    const form = useForm({
        initialValues: {
            name: '',
            type: ''
        },
        validate: {
            name: (value) => (!value ? 'Nome não pode ser vazio' : null)
        }
    });

    const {mutate: createTotem, isLoading} = useMutation((data) => {
        return BackendServiceV2.post('/totem', data)
    }, {
        onSuccess: (response) => {
            window.location.href = window.ENV.BaseURL + '/showcase/' + response?.id;
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (
        <SectionBox title="Novo Showcase" icon={<Plus />} isLoading={isLoading}>
            <form onSubmit={form.onSubmit(values => createTotem(values))}>
                <Stack>
                    <TextInput
                        required
                        label="Nome"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('name')}
                    />

                    <Select
                        label="Tipo"
                        data={[
                            {value: 'CAVE', label: 'Cave'},
                            {value: 'TOTEM', label: 'Totem'}
                        ]}
                        {...form.getInputProps('type')}
                    />

                    <Button type="submit">Criar</Button>
                </Stack>
            </form>
        </SectionBox>
    )
}

export default TotemInterfaceCreate;