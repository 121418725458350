import { Accordion, Checkbox, LoadingOverlay, NumberInput, SegmentedControl, Select, Stack, TextInput } from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import React, { useEffect, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";
import equal from'fast-deep-equal';
import UploadArea from "components/v2/UploadArea";

export function TotemPageContentEdit({content, pages, currentPage}) {

    const queryClient = useQueryClient();

    const form = useForm({
        initialValues: {
            actionType: content?.actionType,
            actionContent: content?.actionContent,
            transition: content?.transition,
            transitionTime: content?.transitionTime,
            transitionDelay: content?.transitionDelay,
            media: {
                type: content?.media?.type,
                value: content?.media?.src,
            }
        }
    });
    const [debouncedForm] = useDebouncedValue(form.values, 1000)

    const uploadArea = useRef();
    function onDrop() {
        updateContentMedia(debouncedForm);
    }    

    useEffect(() => {
        if(equal({
            actionType: content?.actionType,
            actionContent: content?.actionContent,
            transition: content?.transition,
            transitionTime: content?.transitionTime,
            transitionDelay: content?.transitionDelay,
            media: {type: debouncedForm?.media?.type, value: content?.media?.src,}
        }, debouncedForm))
            return;
        
        updateContentMedia(debouncedForm)
    // eslint-disable-next-line
    }, [debouncedForm])

    const {mutate: updateContentMedia, isLoading} = useMutation((data) => {
        return BackendServiceV2.put('/totem/page/content/' + content.id, data)
    }, {
        onSuccess: () => {
            if(uploadArea?.current?.hasFile()) {
                uploadMedia();
            } else {
                queryClient.invalidateQueries('totem-data');
                showNotification({
                    title: 'Página atualizada com sucesso',
                    message: '',
                    icon: <Check />,
                    color: 'green',
                    autoClose: 5000,
                });
            }
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    function uploadMedia() {
        uploadArea.current.upload({
            mutate: async (files, config) => {
                let formData = new FormData();
                formData.append('content', files[0]);

                return BackendServiceV2.put('/totem/page/content/' + content.id + '/upload', formData)
            },
            success: () => {
                uploadArea.current.reset();
                queryClient.invalidateQueries('totem-data');
                showNotification({
                    title: 'Página atualizada com sucesso',
                    message: '',
                    icon: <Check />,
                    color: 'green',
                    autoClose: 5000,
                });
            },
            error: (error) => {
                showNotification({
                    title: 'Um erro ocorreu',
                    message: error?.response?.data?.message,
                    icon: <X />,
                    color: 'red',
                    autoClose: 5000,
                });
            }
        });
    }
    
    return (
        <form onSubmit={form.onSubmit(values => updateContentMedia(values))}>
            
            <LoadingOverlay visible={isLoading} />

            <SectionBox title={currentPage?.name + ' - ' + content?.position} padding="0" withBorder={false}>
                    <Accordion multiple defaultValue={['content']} variant="separated" chevronPosition="left">
                        <Accordion.Item value="content">
                            <Accordion.Control>Conteúdo</Accordion.Control>
                            <Accordion.Panel>
                                <SegmentedControl
                                    fullWidth
                                    data={[
                                        { label: 'Imagem', value: 'IMAGE' },
                                        { label: 'Embed', value: 'EMBED' },
                                        { label: 'Vídeo', value: 'VIDEO' }
                                    ]}
                                    {...form.getInputProps('media.type')}
                                />

                                {form.values?.media?.type === 'IMAGE' &&
                                    <UploadArea 
                                        label={form.values?.media?.type === 'IMAGE' ? 'Imagem' : 'Vídeo'}
                                        size="md"
                                        currentPreview={null}
                                        deleteCurrent={null}
                                        maxSize={form.values?.media?.type === 'IMAGE' ? 1024 * 5 : 1024 * 100}
                                        accept={form.values?.media?.type === 'IMAGE' ? [MIME_TYPES.png, MIME_TYPES.jpeg] : [MIME_TYPES.mp4]}
                                        multiple={false}
                                        ref={uploadArea}
                                        onDrop={onDrop}
                                    />
                                }

                                {form.values?.media?.type === 'VIDEO' && 
                                    <Stack>
                                        <UploadArea 
                                            label={form.values?.media?.type === 'IMAGE' ? 'Imagem' : 'Vídeo'}
                                            size="md"
                                            currentPreview={null}
                                            deleteCurrent={null}
                                            maxSize={form.values?.media?.type === 'IMAGE' ? 1024 * 5 : 1024 * 100}
                                            accept={form.values?.media?.type === 'IMAGE' ? [MIME_TYPES.png, MIME_TYPES.jpeg] : [MIME_TYPES.mp4]}
                                            multiple={false}
                                            ref={uploadArea}
                                            onDrop={onDrop}
                                        />

                                        <Checkbox
                                            label="Loop"
                                            size="md"
                                            checked={form.getInputProps('media.loop').value}
                                            {...form.getInputProps('media.loop')}
                                        />
                                    </Stack>
                                }

                                {form.values?.media?.type === 'EMBED' &&
                                    <TextInput
                                        required
                                        label="Link"
                                        placeholder=""
                                        size="md"
                                        {...form.getInputProps('media.value')}
                                    />
                                }
                            </Accordion.Panel>
                        </Accordion.Item>

                        <Accordion.Item value="transition">
                            <Accordion.Control>Transição</Accordion.Control>
                            <Accordion.Panel>
                                <Select
                                    clearable={true}
                                    unselectable="on"
                                    label="Tipo"
                                    data={[
                                        {value: 'FADE', label: 'FADE'},
                                        /*{value: 'SCALE', label: 'SCALE'},
                                        {value: 'SCALE_Y', label: 'SCALE-Y'},
                                        {value: 'SCALE_X', label: 'SCALE-X'},
                                        {value: 'SKEW_UP', label: 'SKEW-UP'},
                                        {value: 'SKEW_DOWN', label: 'SKEW-DOWN'},
                                        {value: 'ROTATE_LEFT', label: 'ROTATE-LEFT'},
                                        {value: 'ROTATE_RIGHT', label: 'ROTATE-RIGHT'},
                                        {value: 'SLIDE_DOWN', label: 'SLIDE-DOWN'},
                                        {value: 'SLIDE_UP', label: 'SLIDE-UP'},
                                        {value: 'SLIDE_LEFT', label: 'SLIDE-LEFT'},
                                        {value: 'SLIDE_RIGHT', label: 'SLIDE-RIGHT'},
                                        {value: 'POP', label: 'POP'},
                                        {value: 'POP_BOTTOM_LEFT', label: 'POP-BOTTOM-LEFT'},
                                        {value: 'POP_BOTTOM_RIGHT', label: 'POP-BOTTOM-RIGHT'},
                                        {value: 'POP_TOP_LEFT', label: 'POP-TOP-LEFT'},
                                        {value: 'POP_TOP_RIGHT', label: 'POP-TOP-RIGHT'}*/
                                    ]}
                                    {...form.getInputProps('transition')}
                                />

                                <NumberInput
                                    label="Duração"
                                    min={0}
                                    defaultValue={250}
                                    {...form.getInputProps('transitionTime')}
                                />

                                <NumberInput
                                    label="Delay inicial"
                                    min={0}
                                    defaultValue={0}
                                    {...form.getInputProps('transitionDelay')}
                                />
                            </Accordion.Panel>
                        </Accordion.Item>

                        <Accordion.Item value="events">
                            <Accordion.Control>Eventos</Accordion.Control>
                            <Accordion.Panel>
                                <Select
                                    label="Tipo"
                                    clearable={true}
                                    allowDeselect 
                                    data={[
                                        {value: 'CLICK', label: 'Click'}
                                    ]}
                                    {...form.getInputProps('actionType')}
                                />

                                {form.values['actionType'] === 'CLICK' &&
                                    <Select
                                        required
                                        searchable
                                        label="Ir para página"
                                        data={pages?.map(page => {return {value: page.id, label: page.name}})}
                                        {...form.getInputProps('actionContent')}
                                    />
                                }
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>
            </SectionBox>
        </form>
    )
}