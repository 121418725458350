import { Avatar, Space } from '@mantine/core';
import { ContentWrapper, DashboardContent } from 'components/v2/Dashboard/Layout';
import React, {useState, useEffect, useCallback} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserEditForm from '../../../../components/UserEditForm';
import AuthService from '../../../../services/AuthService';

import './style.css';

export function UserEditPage() {

    const navigate = useNavigate();
    const params = useParams();

    // USER
    const [userData, setUser] = useState(null);

    const getUser = useCallback(async () => {
        try {
            let response = await AuthService.get('/account/id/' + params.idUser);
            
            if(response.status === 200)
                setUser(response.data);
        } catch (e) {
            navigate('/404');
        }
    }, [params.idUser, navigate]);

    useEffect(() => {       
        getUser();
    }, [getUser, params.idUser]);
    
    return (
        <DashboardContent 
            title={userData?.name} 
            icon={<>
                <Avatar size={50} color="iteleport-orange" src={userData?.profilePicture?.webp ? window.ENV.StaticURL + userData?.profilePicture?.webp : null} >
                    {userData?.name.split(' ').map((letter, i) => (
                        i < 2 ? letter.charAt(0).toUpperCase() : ''
                    ))}
                </Avatar>
                <Space w="md" />
            </>}
        >
            <ContentWrapper>
                <UserEditForm user={userData} notify={getUser}></UserEditForm>
            </ContentWrapper>
        </DashboardContent>
    )
}