import { Button, Group, Modal, Select, Space, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { TeleportSelectCard } from "components/v2/Teleport/TeleportSelectCard";
import React, { forwardRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Building, Check, Edit, X } from "tabler-icons-react";

export function SalesTeamEventEdit({event, salesTeam, opened, openedHandler}) {

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // FORM
    const statusOptions = [
        {
            value: 'OPEN',
            label: 'Aberto'
        },
        {
            value: 'FINISHED',
            label: 'Fechado'
        }
    ]

    const form = useForm({
        initialValues: {
            idTeleport: event.idTeleport ? event.idTeleport : undefined,
            status: event.status ? event.status : undefined,
        },
    
        validate: {
        },
    })

    const editEvent = useMutation((values) => {
        return BackendServiceV2.put('/salesteam/event/' + event.id, values);
    }, {
        onSuccess: response => {
            showNotification({
                title: <>Evento atualizado com sucesso</>,
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })

            queryClient.invalidateQueries('sales-team-events');
            openedHandler.close();
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    // TELEPORT DATA
    const {data: linkedTeleports} = useQuery('sales-team-linked-teleports', () => {
        return BackendServiceV2.get('/salesteam/' + salesTeam.id + '/teleport/?size=100&page=0');
    })

    const SelectItem = forwardRef(
        ({ label, description, value, ...others }, ref) => ( <>
            <TeleportSelectCard value={value} label={label} description={description} others={others} reference={ref} />
        </>)
    );

    return (
        <Modal
            opened={opened}
            onClose={e => {
                openedHandler.close();
                form.reset();
            }}
            title={<Group><Edit /><Title order={2}>Editar</Title></Group>}
            centered
            size="lg"
        >
            <form onSubmit={form.onSubmit(values => {editEvent.mutate(values)})}>            
                <Select
                    required
                    label="Status"
                    placeholder="Selecione um"
                    size="md"
                    data={statusOptions}
                    value={event.status}
                    {...form.getInputProps('status')}
                />

                {linkedTeleports?.result && <>
                    <Select
                        required
                        label="Teleport"
                        placeholder=""
                        size="md"
                        itemComponent={SelectItem}
                        data={linkedTeleports?.result?.map(teleport => {
                            return {
                                label: teleport?.metadata?.title, 
                                description: teleport?.metadata?.subtitle, 
                                value: teleport?.id
                            }
                        })}
                        searchable
                        nothingFound="Nenhum Teleport encontrado"
                        value={event.idTeleport}
                        {...form.getInputProps('idTeleport')}
                        icon={<Building />}
                    />
                </>}

                <Space h="lg" />

                <Group position="right">
                    <Button type="submit">Salvar</Button>
                </Group>
            </form>
        </Modal>
    )
}