import React, {useCallback, useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';
import SockJS from "sockjs-client"
import {Client} from "@stomp/stompjs"
import { UserContext } from '../../context/User/UserContext';
import { Bell, BellRinging2, Bolt, Building, BuildingCommunity, BuildingStore, ExternalLink, Home, PhoneCall, Photo, Receipt2, Tool, User, Users } from 'tabler-icons-react';
import logo from '../../assets/iteleport-logo.svg';
import { Avatar, Group, Menu, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import './style.css';
import { IconUserScreen } from '@tabler/icons-react';

function Sidebar() {

    // PERMISSIONS
    const [ADMIN_ROLE, setAdminRole] = useState(false);
    //const [CONNECT_CREATE, setConnectCreate] = useState(false);

    useEffect(() => {
        async function getPermissions() {
            setAdminRole(await UserService.hasRole('ADMIN'));
            //setConnectCreate(await UserService.hasAuthority('CONNECT_CREATE'));
        }

        getPermissions();
    }, []);

    // USER DATA
    const userContext = useContext(UserContext);

    async function logout() {
        try {
            await AuthService.delete("/oauth/revoke");
        } catch (e) {
            console.log("FAILED TO REVOKE TOKEN");
        }

        localStorage.removeItem('me');
        localStorage.removeItem('accessToken');
        localStorage.removeItem("expireIn");
        localStorage.removeItem("redirectTo");

        window.location.replace(window.ENV.BaseURL + '/id/login');
    }

    // NOTIFICATION
    const [notifications, setNotifications] = useState([]);
    const [displayNewIcon, setDisplayNewIcon] = useState(false);

    useEffect(() => {
        if(userContext.loggedUserData !== undefined) {
            const stompConfig = {
                connectHeaders: {'Authorization': 'Bearer ' + localStorage.getItem('accessToken')},
                brokerURL: window.ENV.AuthURL.replace('/api', '') + '/websocket',
                webSocketFactory: () => {
                    return new SockJS(window.ENV.AuthURL.replace('/api', '') + '/websocket');
                },
                debug: function (str) {
                    //console.log('STOMP: ' + str);
                },
                reconnectDelay: 200,
                onConnect: (frame) => {
                    stompClient.subscribe('/user/' + userContext.loggedUserData?.email + '/notification', (message) => {
                        if (message.body) {
                            setNotifications(oldNotifications => {
                                if(oldNotifications.length < 5)
                                    return [JSON.parse(message.body), ...oldNotifications]

                                oldNotifications.pop();
                                return [JSON.parse(message.body), ...oldNotifications]
                            });
                            setDisplayNewIcon(true);
                        }
                    });
                }
            };

            const stompClient = new Client(stompConfig);
            stompClient.activate();

            async function getNotication() {
                let response = await AuthService.get('/me/notification');
                if(response.status === 200) {
                    setNotifications(response.data.result);
                    
                    response.data.result.forEach((notification) => {
                        if(notification.readAt === null || notification.readAt === undefined)
                            setDisplayNewIcon(true);
                    });
                }
            }

            getNotication();
        }
    // eslint-disable-next-line
    }, []);

    const markNotificationsAsRead = useCallback(async () => {
        for (let i = 0; i < notifications.length; i++) {
            await AuthService.put('/notification/' + notifications[i].id + '/read');
        }
    }, [notifications])

    const [opened, handlers] = useDisclosure(false);

    return (
        <div className="sidebar">
            <div className="sidebar-content">
                <div className="logo">
                    <img src={logo} alt="iTeleport"/>
                </div>

                <ul className="menu">
                    {ADMIN_ROLE && <Link to="/"><li><Home />Home</li></Link>}
                    {ADMIN_ROLE && <Link to="/user"><li><Users />Usuários</li></Link>}
                    <Link to={"/profile"}><li><User />Perfil</li></Link>
                    <Link to="/company"><li><BuildingStore />Empresas</li></Link>
                    <Link to="/development"><li><BuildingCommunity />Empreendimentos</li></Link>
                    <Link to="/teleport"><li><Building />Teleports</li></Link>
                    {false && <Link to="/pricing"><li><Receipt2 />Pricing Requests</li></Link>}
                    {ADMIN_ROLE && <Link to="/media"><li><Photo />Media</li></Link>}
                    {false && <Link to="/connect"><li><PhoneCall />Instantâneo</li></Link>}
                    <Link to="/connect"><li><PhoneCall />Connect</li></Link>
                    {false && <Link to="/tools"><li><Tool />Ferramentas</li></Link>}
                    <Link to="/showcase"><li><IconUserScreen />Showcase</li></Link>
                    <Link to="/express"><li><Bolt />Express</li></Link>
                </ul>

                <div className="bottom-menu">
                    <Group position='apart' className="full-width" noWrap>
                        <Group noWrap>
                            <Avatar src={userContext.loggedUserData?.profilePicture?.webp ? window.ENV.StaticURL + userContext.loggedUserData?.profilePicture?.webp : null} >
                                {userContext.loggedUserData?.name.split(' ').map((letter, i) => (
                                    i < 2 ? letter.charAt(0).toUpperCase() : ''
                                ))}
                            </Avatar>

                            <Stack spacing={0}>
                                <abbr><Text lineClamp={1} weight={600}>{userContext.loggedUserData?.name}</Text></abbr>
                                <Text className="action" onClick={logout} inline>Logout</Text>
                            </Stack >
                        </Group>

                        {false && <Group>
                            <Menu 
                                opened={opened} 
                                onOpen={() => {handlers.open(); markNotificationsAsRead()}} 
                                onClose={handlers.close} 
                                size="xl" 
                                menuButtonLabel="notificações" control={
                                    (displayNewIcon ? <button><BellRinging2 /></button> : <button><Bell /></button>)
                                }
                            >
                                <Menu.Label >Notificações</Menu.Label>
                                    {notifications && notifications.map((notification, i, data) => (
                                        <Menu.Item
                                            key={notification.id}
                                            onClick={() => {if(notification.link) window.open(window.ENV.BaseURL + notification.link, "_blank")}}
                                            rightSection={notification.link &&
                                                <ExternalLink />
                                            }
                                        >
                                            <span className="notification-content">
                                                <span className="notification-date">{notification.createdAt}</span>
                                                <span className="notification-content">{notification.message}</span>
                                            </span>
                                        </Menu.Item>
                                    ))}
                            </Menu>
                        </Group>}
                    </Group>
                </div>
            </div>  
        </div>
    )
}

export default Sidebar;