import { Center, Image, Stack, Text } from "@mantine/core";
import React from "react";

export function Error404() {
    return (
        <Center>
            <Stack>
                <Image width="50vh" src="/assets/images/404.svg" />
                <Text weight={600} size="xl" color="dimmed" align="center">A página não foi encontrada</Text>
            </Stack>
        </Center>
    )
}