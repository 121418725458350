import React from "react";
import { Link } from "react-router-dom";

export function SalesTeamCard({salesTeam}) {
    return (
        <Link className="list-entry" to={"/connect/" + salesTeam.id}>
            <div className="list-content">
                <div className="list-header">
                    <span className="list-name">{salesTeam.name}</span>
                </div>

                <div className="list-details">
                    <span>{}</span>
                </div>
            </div>
        </Link>
    )
}