import { ActionIcon, Group, Stack, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Plus, X } from "tabler-icons-react";
import { TeleportVideoTextureCard } from "../TeleportVideoTextureCard";

export function TeleportVideoTexture({idTeleport}) {

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // GET VIDEO TEXTURES
    const {data: videoTextures} = useQuery(['teleport-video-texture'], () => {
        return BackendServiceV2.get(`/teleport/${idTeleport}/videotexture`);
    })

    // CREATE DEFAULT VIDEO TEXTURE
    const createVideoTexture = useMutation(() => {
        return BackendServiceV2.post('/videotexture', {
            idTeleport
        });
    }, {
        onSuccess: success => {
            queryClient.invalidateQueries('teleport-video-texture');
            showNotification({
                title: 'Textura de vídeo criada com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })
    
    return (
        <SectionBox title="Video Texture">
            {videoTextures?.result?.length === 0 && <>
                <Text color="dimmed" align="center" size="xs">Nenhuma textura de vídeo adicionada</Text>
            </>}

            <Stack>
                {videoTextures?.result?.map(videoTexture => (
                    <TeleportVideoTextureCard key={videoTexture.id} videoTexture={videoTexture} />
                ))}

                <Group position="center">
                    <ActionIcon 
                        variant="filled" 
                        color="#FF5E00" 
                        onClick={e => {
                            createVideoTexture.mutate()
                        }}
                    >
                        <Plus color="white" />
                    </ActionIcon>
                </Group>
            </Stack>           
        </SectionBox>
    )    
}