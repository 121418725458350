import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TeleportList } from 'components/v2/Teleport/TeleportList';
import { ContentWrapper, DashboardContent, LeftContent, RightContent } from 'components/v2/Dashboard/Layout';
import { BuildingCommunity, InfoCircle, News } from 'tabler-icons-react';
import { Badge, Group, LoadingOverlay, Tabs } from '@mantine/core';
import BackendServiceV2 from 'services/v2/BackendService';
import { useQuery } from 'react-query';
import { TeleportCreate } from 'components/v2/Teleport/TeleportCreate';
import { DevelopmentDeleteButton } from '../../../../components/v2/Development/DevelopmentDeleteButton';
import { Error404 } from 'components/v2/Dashboard/404';
import { ResourcePermissionContext } from 'context/Permission/ResourcePermissionContext';
import { PermissionMenu } from 'components/v2/PermissionMenu';
import { DevelopmentEdit } from 'components/v2/Development/DevelopmentEdit';
import { Publication } from 'components/v2/Publication';
import { ResourceMove } from 'components/v2/ResourceMove';
import queryString from 'query-string';
import { TagsEdit } from 'components/v2/Tags/TagEdit';

export function DevelopmentEditPage() {

    // PARAMS
    const idDevelopment = useParams().idDevelopment;

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    useEffect(() => {
        resourcePermission.setResource('DEVELOPMENT', idDevelopment);
    // eslint-disable-next-line
    }, [idDevelopment]);

    // TABS CONTROL    
    const [currentTab, setCurrentTab] = useState('info');

    useEffect(() => {
        let tab = queryString.parse(window.location.search).tab;
        if(tab)
            setCurrentTab(tab);
    }, []);

    // DEVELOPMENT
    const {data: development, isLoading, isError} = useQuery(['development-data', idDevelopment], () => {
        return BackendServiceV2.get(`/development/${idDevelopment}`);
    });

    if(isLoading) return (
        <DashboardContent>
            <LoadingOverlay visible={isLoading} />
        </DashboardContent>
    )

    if(isError) return (
        <Error404 />
    )

    return (
        <DashboardContent 
            resourceId={development?.idDevelopment}
            resourceClass="DEVELOPMENT"
            title={development?.name} 
            icon={<BuildingCommunity size={30} />}
            description={
                <Group spacing={3}>
                    <Badge variant='outline'>{development?.idDevelopment}</Badge>
                </Group>
            }
            right={<>
                {resourcePermission.containsGroup(['ADMIN']) && <>
                    <ResourceMove resourceClass="DEVELOPMENT" idResource={idDevelopment} />
                    <DevelopmentDeleteButton development={development} />
                </>}
                <PermissionMenu resourceClass='DEVELOPMENT' resourceId={idDevelopment} />
            </>}
        >
            <Tabs 
                value={currentTab}
                keepMounted={false}
                styles={(theme) => ({
                    root: {
                        flex: 1
                    },
                    panel: {
                        height: '100%'
                    }
                })}
                onTabChange={value => {
                    let parameters = queryString.parse(window.location.search);
                    parameters['tab'] = value;
                    window.history.pushState('', '', '?' + queryString.stringify(parameters));
                    setCurrentTab(value);
                }}
            >
                <Tabs.List>
                    <Tabs.Tab value="info" icon={<InfoCircle />}>Geral</Tabs.Tab>
                    {resourcePermission.containsGroup(['ADMIN']) &&
                        <Tabs.Tab value="publication" icon={<News />}>Publicação</Tabs.Tab>
                    }
                </Tabs.List>

                <Tabs.Panel value="info" pt="xs">
                    <ContentWrapper>
                        <LeftContent>
                            {resourcePermission.containsPermission('DEVELOPMENT_UPDATE') &&
                                <DevelopmentEdit development={development} />
                            }

                            {resourcePermission.containsPermission('TELEPORT_READ') &&
                                <TeleportList idDevelopment={development?.idDevelopment} />
                            }
                        </LeftContent>

                        <RightContent>
                            {resourcePermission.containsPermission('TELEPORT_CREATE') &&
                                <TeleportCreate idDevelopment={development?.idDevelopment} />
                            }
                        </RightContent>
                    </ContentWrapper>
                </Tabs.Panel>

                <Tabs.Panel value="publication" pt="xs">
                    <ContentWrapper>
                        <LeftContent>
                            <TagsEdit resourceClass="DEVELOPMENT" idResource={development?.idDevelopment} />
                        </LeftContent>

                        <RightContent>
                            <Publication publicationType={'DEVELOPMENT'} idResource={development?.idDevelopment} />
                        </RightContent>
                    </ContentWrapper>
                </Tabs.Panel>
            </Tabs>
        </DashboardContent>
    );
}