import { ActionIcon, Badge, Button, Card, Group, LoadingOverlay, Modal, Pagination, Space, Stack, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Replace, X } from "tabler-icons-react";
import { SearchBox } from "../SearchBox";

export function ResourceMove({resourceClass, idResource}) {

    const queryClient = useQueryClient();

    const [opened, openedHandler] = useDisclosure(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [name, setName] = useState(undefined);

    useEffect(() => {
        setCurrentPage(0);
    }, [name]);

    const {data: parents, isLoading} = useQuery(['parents-list-data', currentPage, name, opened], () => {
        if(!opened) 
            return;
        
        let url = `/resource/parent/?resourceClass=${resourceClass}&page=${currentPage}`;

        if(name) {
            url += `&name=${name}`
        }

        return BackendServiceV2.get(url);
    });

    const moveResource = useMutation((parent) => {
        return BackendServiceV2.post('/resource/move/', {
            resourceClass: resourceClass,
            idResource: idResource,
            parentClass: parent.resourceClass,
            idParent: parent.id
        })
    }, {
        onSuccess: () => {            
            showNotification({
                title: 'Recurso movido com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
            window.location.reload();
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const modals = useModals();
    const resourceMoveConfirmation = (parent) => modals.openConfirmModal({
        title: 'Mover recurso',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja mover esse recurso?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            moveResource.mutate(parent);
        },
        zIndex: 1000
    });

    return (<>
        <ActionIcon color="#ff5e00" variant="subtle" onClick={openedHandler.open} title="Mover"><Replace /></ActionIcon>

        <Modal
            opened={opened}
            onClose={openedHandler.close}
            title={<Group><Replace /><Title order={2}>Mover</Title></Group>}
            centered
            size="lg"
        >
            <LoadingOverlay visible={isLoading} />

            <Stack spacing={5} style={{width: '100%'}}>

                <SearchBox onSearch={setName} />

                {parents?.result?.map(parent => (
                    <Card withBorder>
                        <Group noWrap position="apart">
                            <Group noWrap>
                                <Badge style={{textTransform: 'none'}}>{parent.id}</Badge>
                                <Text>{parent.name}</Text>
                            </Group>

                            <Group>
                                <Button variant="subtle" onClick={e => {resourceMoveConfirmation(parent)}}>Mover</Button>
                            </Group>
                        </Group>
                    </Card>
                ))}

                {parents?.result?.length > 0 && <>
                    <Space h="lg" />
                    <Pagination 
                        value={parents?.pageNumber + 1} 
                        onChange={(page) => {setCurrentPage(page - 1); queryClient.invalidateQueries('teleport-list-data')}} 
                        total={parents?.totalPages} 
                    />
                </>}
            </Stack>
        </Modal>
    </>)
}