import React, {useState, useEffect} from 'react';
import './style.css';

function Message({ message, type }) {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(true);
    }, [message])

    function hideMessage() {
        setVisible(false);
    }

    return (
        <>
            {message !== undefined && visible ? (
                <div className={type + " message-wrapper"}>
                    <span className="message">{message}</span>
                    <span onClick={hideMessage} className="close">X</span>
                </div>
            ) : ('')}
        </>
    );
}

export default Message;