import 'dayjs/locale/pt-br';
import { Button, Group, Modal, Select, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import React, { forwardRef } from "react";
import { Building, Check, Plus, SmartHome, X } from "tabler-icons-react";
import { useMutation, useQuery, useQueryClient } from 'react-query';
import BackendServiceV2 from 'services/v2/BackendService';
import { useDisclosure } from '@mantine/hooks';
import { TeleportSelectCard } from 'components/v2/Teleport/TeleportSelectCard';

export function SalesTeamEventCreate({salesTeam}) {

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // MODAL
    const [openCreateModal, openCreateModalHandler] = useDisclosure(false);

    // CREATE NEW LEAD
    const createNewLead = useMutation((values) => {
        return BackendServiceV2.post('/salesteam/event', {...values});
    }, {
        onSuccess: response => {
            showNotification({
                title: <>Evento criado</>,
                message: ``,
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })

            form.reset();
            openCreateModalHandler.close();
            queryClient.invalidateQueries('sales-team-events')
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    // LEAD FORM DATA
    const form = useForm({
        initialValues: {
            idTeleport: '',
            idSalesTeam: salesTeam.id,
        },
    
        validate: {
        },
    });

    // TELEPORT DATA
    const {data: linkedTeleports} = useQuery('sales-team-linked-teleports', () => {
        return BackendServiceV2.get('/salesteam/' + salesTeam.id + '/teleport/?size=100&page=0');
    })

    const SelectItem = forwardRef(
        ({ label, description, value, ...others }, ref) => (
            <TeleportSelectCard value={value} label={label} description={description} others={others} reference={ref} />
        )
    );
    
    return (<>
        <Button leftIcon={<Plus />} onClick={openCreateModalHandler.open}>Criar novo evento</Button>

        <Modal
            opened={openCreateModal}
            onClose={openCreateModalHandler.close}
            title={<Group><SmartHome /><Title order={2}>Criar novo evento</Title></Group>}
            centered
            size="lg"
        >
            <form onSubmit={form.onSubmit(values => {createNewLead.mutate(values)})}>
                {linkedTeleports?.result &&
                    <Select
                        required
                        label="Teleport"
                        placeholder=""
                        size="md"
                        itemComponent={SelectItem}
                        data={linkedTeleports?.result?.map(teleport => {
                            return {
                                label: teleport?.metadata?.title, 
                                description: teleport?.metadata?.subtitle, 
                                value: teleport?.id
                            }
                        })}
                        searchable
                        nothingFound="Nenhum Teleport encontrado"
                        {...form.getInputProps('idTeleport')}
                        icon={<Building />}
                    />
                }

                <Group position="right" mt="md">
                    <Button type="submit">Criar</Button>
                </Group>
            </form>
        </Modal>
    </>)    
}