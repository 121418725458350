import React, { useEffect, useState } from 'react';
import { BuildingStore } from 'tabler-icons-react';
import { ContentWrapper, DashboardContent, LeftContent, RightContent } from 'components/v2/Dashboard/Layout';
import './style.css';
import { CompanyList } from 'components/v2/Company/CompanyList';
import { CompanyCreate } from '../CompanyCreate';
import UserService from 'services/UserService';

export function CompanyListPage() {

    // PERMISSIONS
    const [COMPANY_CREATE, setCompanyCreate] = useState(false);

    useEffect(() => {
        async function getPermissions() {
            setCompanyCreate(await UserService.hasAuthority('COMPANY_CREATE'));
        }

        getPermissions();
    }, []);

    return (
        <DashboardContent title="Empresas" icon={<BuildingStore size={30} />}>
            <ContentWrapper>
                <LeftContent>
                    <CompanyList />
                </LeftContent>

                <RightContent>
                    {COMPANY_CREATE &&
                        <CompanyCreate />
                    }
                </RightContent>
            </ContentWrapper>
        </DashboardContent>
    );
}