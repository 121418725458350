import { Badge, Card, Group, Image, Text } from "@mantine/core";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import React, { useContext } from "react";
import { LayerSkyboxDeleteButton } from "../LayerSkyboxDeleteButton";
import { LayerSkyboxEditButton } from "../LayerSkyboxEditButton";

export function LayerSkyboxCard({skybox}) {

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    return (
        <Card withBorder key={skybox?.id}>
            <Card.Section>
                <Badge style={{position: 'absolute', margin: '10px', zIndex: 100, color: 'black'}}>{skybox.id}</Badge>
                <Image src={window.ENV.StaticURL + skybox?.texture?.webp?.low} height={200} fit="cover" />

                <Group position="apart" className="draggrable-title" noWrap>
                    
                    <Group>
                        <Text weight={600} lineClamp={1} color="white">{skybox?.originalName}</Text>
                    </Group>
                    <Group noWrap>
                        {resourcePermission.containsPermission('TELEPORT_LAYER_UPDATE') &&
                            <LayerSkyboxDeleteButton skybox={skybox} />
                        }
                        {resourcePermission.containsPermission('TELEPORT_LAYER_UPDATE') &&
                            <LayerSkyboxEditButton skybox={skybox} />
                        }
                    </Group>
                </Group>
            </Card.Section>
        </Card>
    )
}