import { Group, Text } from "@mantine/core";
import { FixedHeightContentWrapper } from "components/v2/Dashboard/Layout";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { TeleportCarouselContext } from "./Context/TeleportCarouselContext";
import { TeleportCarouselEntries } from "./TeleportCarouselEntries";
import { TeleportCarouselGroups } from "./TeleportCarouselGroups";

export function TeleportCarousel({teleport}) {

    const queryClient = useQueryClient();
    
    useEffect(() => {
        if(!teleport?.carousel) {
            createTeleportCarousel();
        }
    // eslint-disable-next-line
    }, []);

    const {mutate: createTeleportCarousel} = useMutation(() => {
        return BackendServiceV2.post(`/teleport/${teleport?.id}/carousel`);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-data');
        },
        onError: (error) => {

        }
    });

    // SLIDER DATA
    const [_items, setItems] = useState(teleport?.carousel);

    useEffect(() => {
        setItems(teleport?.carousel)
        iframeRef?.current?.contentWindow?.postMessage({type: "TELEPORT_DATA", data: teleport}, "*");
    // eslint-disable-next-line
    }, [teleport?.carousel]);

    // ACTIVE GROUP TO EDIT
    const [activeGroup, setActiveGroup] = useState();

    useEffect(() => {
        if(activeGroup)
            setActiveGroup(_items?.groups.find(group => group?.id === activeGroup?.id));
    // eslint-disable-next-line
    }, [_items]);

    // IFRAME COMMS
    const iframeRef = useRef();

    const [isPlayerLoaded, setIsPlayerLoaded] = useState(false);

    // CONTEXT
    const carouselContext = useContext(TeleportCarouselContext);

    useEffect(() => {
        carouselContext.setIframeRef(iframeRef?.current);
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        subscribeToIframeMessage(carouselContext.iFrameMessage);
    }, [carouselContext]);

    function subscribeToIframeMessage(message) {
        if(message?.type === 'FIRST_DISPLAY') {
            setIsPlayerLoaded(true);
        }
    };

    const [selectedTower, setSelectedTower] = useState();
    const [selectedFloor, setSelectedFloor] = useState();

    useEffect(() => {
        let _towers = [];
        let _floors = [];

        teleport?.layers?.forEach(layer => {
            layer?.skyboxesSet?.forEach(skyboxSet => {
                if(!_towers.includes(skyboxSet.tower))
                    _towers.push(skyboxSet.tower);

                if(!_floors.includes(skyboxSet.floor))
                    _floors.push(skyboxSet.floor);
            })
        })
        
        if(_towers.length > 0)
            setSelectedTower(_towers[0]);

        if(_floors.length > 0)
            setSelectedFloor(_floors[0]);
    // eslint-disable-next-line
    }, [])

    if(teleport?.layers.length === 0) {
        return (
            <FixedHeightContentWrapper>
                <Group align="center" position="center" style={{width: '100%'}}>
                    <Text color="dimmed">O Teleport ainda não foi configurado. Não é possível criar destaques.</Text>
                </Group>
            </FixedHeightContentWrapper>
        )
    }

    return (
        <FixedHeightContentWrapper>
            {isPlayerLoaded && !activeGroup && <TeleportCarouselGroups groups={_items?.groups} setActiveGroup={setActiveGroup} idCarousel={_items?.id} />}
            {isPlayerLoaded && activeGroup && <TeleportCarouselEntries group={activeGroup} setActiveGroup={setActiveGroup} />}
            
            <iframe 
                ref={iframeRef}
                style={{flex: 1, paddingBottom: 0, height: '100%'}}
                title="player" 
                allowFullScreen={true}
                src={window.ENV.PlayerURL + teleport?.id +
                    "?dev=" + window.ENV.IsDev +
                    "&idle-lock=true" +
                    "&auto-play=true" +
                    "&connect=false" +
                    "&help=false" + 
                    (selectedTower && selectedFloor ? `&u=G_T${selectedTower}_${selectedFloor}` : '')
                }
            >
            </iframe>
        </FixedHeightContentWrapper>
    )
}