import { LoadingOverlay } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { Rnd } from 'react-rnd';
import BackendServiceV2 from 'services/v2/BackendService';
import { X } from 'tabler-icons-react';

export function TotemRegion({region, selected, select}) {

    const regionRef = useRef();
    const [isVisible, setIsVisible] = useState(false);

    const [state, setState] = useState({
        x: region.posX,
        y: region.posY,
        width: region.width,
        height: region.height,
    })

    useEffect(() => {
        if(state.width !== region.width || state.height !== region.height) {
            setState((value) => {return {...value, width: region.width, height: region.height}});
            regionRef?.current?.updateSize({width: region.width, height: region.height})
        }
    // eslint-disable-next-line
    }, [region]);

    const updateRegion = useMutation((state) => {
        return BackendServiceV2.put('/totem/page/content/region/' + region.id, {
                posX: state?.x,
                posY: state?.y,
                width: state?.width,
                height: state?.height,
            }
        )
    }, {
        onSuccess: () => {
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    useEffect(() => {
        let tempState = state;

        if(typeof tempState?.width === 'string' || tempState?.width instanceof String) {
            tempState.width = state?.width ? parseInt(state?.width?.replace('px', '')) : null;
            tempState.height = state?.height ? parseInt(state?.height?.replace('px', '')) : null;
        }

        if(tempState.width) {           
            if(tempState.x === region.posX && tempState.y === region.posY && tempState.width === region.width && tempState.height === region.height)
                return;
        } else {
            if(tempState.x === region.posX && tempState.y === region.posY)
                return;
        }

        updateRegion.mutate(tempState);
    // eslint-disable-next-line
    }, [state])

    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
        }, 250);
    }, []);

    if(!isVisible)
        return <LoadingOverlay visible={true} />

    return (<>
        <Rnd
            ref={regionRef}
            style={{
                backgroundColor: region?.icon ? 'transparent' : 'rgba(0,0,0,0.7)',
                border: region.id === selected?.id ? '2px solid yellow' : 'none',
                backgroundImage: region?.icon ? 'url(' + window.ENV.StaticURL + region?.icon + ')' : 'inherit',
                backgroundPosition: 'center'
            }}
            position={{ x: state.x, y: state.y }}
            size={{ width: state.width, height: state.height }}
            onDragStop={(e, d) => {
                setState({ x: d.x, y: d.y });
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
                if(!region?.icon)
                    setState({ width: ref.style.width, height: ref.style.height, ...position});
            }}
            onMouseDown={() => {select(region)}}
            onClick={(e) => e.stopPropagation()}
            bounds="parent"
            enableResizing={{
                top: !region.icon,
                right: !region.icon,
                bottom: !region.icon,
                left: !region.icon,
                topRight: !region.icon,
                bottomRight: !region.icon,
                bottomLeft: !region.icon,
                topLeft: !region.icon
            }}
        >

        </Rnd>
    </>);
}