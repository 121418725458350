import { Button, Grid, Group, LoadingOverlay, Select, Space, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";

export function UserGroupAddForm({resourceClass, resourceId, invalidateQueryName}) {

    const {data: roleOptions} = useQuery(['teleport-data', resourceClass, resourceId], () => {
        return BackendServiceV2.get(`/resource/group/options?resourceClass=${resourceClass}&resourceId=${resourceId}`);
    });

    // FORM
    const form = useForm({
        initialValues: {
          email: '',
          role: ''
        },
    
        validate: {
          email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });

    const queryClient = useQueryClient()
    const modals = useModals();

    const retryWithCreate = () => modals.openConfirmModal({
        title: (<Text size="lg" weight={600}>Usuário não registrado</Text>),
        children: (
            <Text size="sm">
                O usuário com o email <b>{form.values.email}</b> ainda não tem uma conta iTeleport.
                Deseja enviar um convite e adicionar o usuário?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: () => {
            mutation.mutate({...form?.values, createUser: true});
        },
        zIndex: 500
    });

    const mutation = useMutation(values => {
        return BackendServiceV2.post('/resource/group', {email: values.email, idGroup: values.role, resourceClass, resourceId, createUser: values.createUser});
    }, {
        onSuccess: (response) => {
            showNotification({
                title: <>Permissão adicionada</>,
                message: `Cargo ${response?.data?.group?.name} adicionado ao usuário ${response?.data?.user?.name}`,
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            });

            form.setFieldValue('email', '');
            queryClient.invalidateQueries(invalidateQueryName);
            queryClient.invalidateQueries('sale-team-user-with-access');
        },
        onError: (error) => {
            if(error?.response?.data?.retryWithCreate) {
                retryWithCreate();
            } else {
                showNotification({
                    title: 'Um erro ocorreu',
                    message: error?.response?.data?.message,
                    autoClose: 5000,
                    color: 'red',
                    icon: <X />
                });
            }            
        }
    })

    return (
        <form onSubmit={form.onSubmit(values => {mutation.mutate(values)})}>
            <LoadingOverlay visible={mutation.isLoading} />

            <Grid columns={3}>
                <Grid.Col span={2}>
                    <TextInput
                        required
                        label="Email"
                        placeholder="user@email.com"
                        size="md"
                        {...form.getInputProps('email')}
                    />
                </Grid.Col>

                {roleOptions &&
                    <Grid.Col span={1}>
                        <Select
                            required
                            label="Cargo"
                            placeholder="Selecione um"
                            size="md"
                            data={roleOptions?.map(role => {return {value: role.id, label: role.name}})}
                            {...form.getInputProps('role', { type: 'select' })}
                        />
                    </Grid.Col>
                }
            </Grid>

            <Space h="lg" />

            <Group position="right">
                <Button type="submit">Adicionar</Button>
            </Group>
        </form>
    )
}