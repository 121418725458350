import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import BackendService from '../../services/BackendService';
import Message from '../Message';

function Media({ media, notify }) {

    const [resultMessage, setResultMessage] = useState(undefined);
    const [typeMessage, setTypeMessage] = useState(undefined);

    async function deleteMedia(idMedia) {
        try {
            let response = await BackendService.delete('/media/' + idMedia);
            setTypeMessage('success')
            setResultMessage(response.data.message);
            notify();
        } catch (error) {
            if(error.response !== undefined) {
                setTypeMessage('error')
                setResultMessage(error.response.data.message);
            } else {
                setTypeMessage('error')
                setResultMessage(error.message);
            }
        }
    }

    return (
        <div className="grid-entry">
            <Message message={resultMessage} type={typeMessage} />           
            
            <div className="grid-content">
                <div className="grid-header">
                    <b>{media.id}</b>
                    <button className="secundary right" onClick={() => {deleteMedia(media.id)}}>Delete</button>
                </div>

                <Link className="media-preview" to={"/media/player/" + media.id} target="_blank">
                    <div className="image-preview" style={{backgroundImage: "url(" + window.ENV.StaticURL + media.path + ")"}}></div>
                </Link>
            </div>
             
        </div>
    );
}

export default Media;