import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { ResourcePermissionContext } from "./ResourcePermissionContext";

export function ResourcePermissionContextProvider({children}) {

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // RESOURCE DATA
    const [resourceClass, setResourceClass] = useState();
    const [resourceId, setResourceId] = useState();

    const {data: userGroup } = useQuery(['resource-permission-user-group', resourceClass, resourceId], () => {
        if(!resourceClass && !resourceId) return;

        return BackendServiceV2.get('/resource/group/user', {
            params: {resourceClass, resourceId}
        });
    })

    async function setResource(resourceClass, resourceId) {
        setResourceClass(resourceClass);
        setResourceId(resourceId);
    }

    useEffect(() => {
        queryClient.invalidateQueries('resource-permission-user-group');
    // eslint-disable-next-line
    }, [resourceClass, resourceId]);

    function containsPermission(permission) {
        if(['ADMIN'].includes(userGroup?.name?.toUpperCase()))
            return true;

        let result = false;
        userGroup?.permissions?.forEach(p => {
            if(p.value.toUpperCase() === permission.toUpperCase()) {
                result = true;
            }
        });

        return result;
    }

    function containsGroup(group) {
        if(['ADMIN'].includes(userGroup?.name?.toUpperCase()))
            return true;
            
        return group.includes(userGroup?.name?.toUpperCase())
    }

    return (
        <ResourcePermissionContext.Provider 
            value={{
                setResource,
                userGroup,
                containsPermission,
                containsGroup,
                resourceClass,
                resourceId
            }}
        >
            {children}
        </ResourcePermissionContext.Provider>
    )
}