import { ActionIcon, TextInput } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { Copy } from "tabler-icons-react";

export function CopyTextInput({label, value, notificationMessage = 'URL copiada com sucesso'}) {

    const clipboard = useClipboard();

    return (
        <TextInput
            label={label}
            readOnly
            value={value}
            rightSection={
                <ActionIcon 
                    onClick={e => {
                        clipboard.copy(value);
                        showNotification({
                            color: "green",
                            title: notificationMessage,
                            message: '',
                            autoClose: 5000,
                        });
                    }}
                ><Copy /></ActionIcon>
            }
            onClick={e => {
                clipboard.copy(value);
                showNotification({
                    color: "green",
                    title: notificationMessage,
                    message: '',
                    autoClose: 5000,
                });
            }}
            styles={{
                input: {
                    cursor: 'pointer',
                    userSelect: 'none',
                    backgroundColor: '#f6f7f8',
                    color: '#c3c4c6',
                    '&:focus': {
                        border: '1px solid #ced4da'
                    }
                }
            }}
        />
    )
}