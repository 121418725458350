import { ActionIcon, Badge, Button, Group, Text, Title } from "@mantine/core";
import React, { useContext } from "react";
import { ExpressProjectAmbientDelete } from "../ExpressProjectAmbientDelete";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import { ExpressAmbientPreview } from "../ExpressAmbientPreview";
import { useDisclosure } from "@mantine/hooks";
import { ExpressProjectAmbientEdit } from "../ExpressProjectAmbientEdit";
import { Edit } from "tabler-icons-react";

export function ExpressProjectAmbientCard({expressProject, projectAmbient}) {

    const resourcePermission = useContext(ResourcePermissionContext);

    const [isProjectAmbientEditVisible, setProjectAmbientEditVisibility] = useDisclosure(false);

    return (<>
        <ExpressProjectAmbientEdit
            expressProject={expressProject}
            projectAmbient={projectAmbient}
            isVisible={isProjectAmbientEditVisible}
            setVisibility={setProjectAmbientEditVisibility}
        />

        <ExpressAmbientPreview 
            title={
                <Group>
                    <Title order={2}>{projectAmbient?.name} - {projectAmbient?.ambient?.code}</Title>
                    {projectAmbient?.ambient && resourcePermission.containsPermission('EXPRESS_PROJECT_UPDATE') &&
                        <Button
                            variant="subtle" 
                            onClick={(e) => {
                                setProjectAmbientEditVisibility.open();
                                e.stopPropagation();
                            }} 
                            title="Editar"
                            leftIcon={<Edit />}
                        >
                            Editar
                        </Button>
                    }
                </Group>
            }
            isEditVisible={isProjectAmbientEditVisible}
            ambient={projectAmbient?.ambient}
            setProjectAmbientEditVisibility={setProjectAmbientEditVisibility}
            cardHeader={<>
                {projectAmbient?.ambient && <Badge variant="filled">{projectAmbient?.ambient?.category?.name} - {projectAmbient?.ambient?.code}</Badge>}
            </>}
            cardFooter={<Group position="apart">
                <Text color={projectAmbient?.ambient ? 'white' : 'black'}>{projectAmbient.name}</Text>

                <Group>
                    {projectAmbient?.ambient && resourcePermission.containsPermission('EXPRESS_PROJECT_UPDATE') &&
                        <ActionIcon 
                            color="#ff5e00" 
                            variant="transparent" 
                            onClick={(e) => {
                                setProjectAmbientEditVisibility.open();
                                e.stopPropagation();
                            }} 
                            title="Editar"
                        >
                            <Edit color={projectAmbient?.ambient ? 'white' : 'black'} />
                        </ActionIcon>
                    }

                    {resourcePermission.containsPermission('EXPRESS_PROJECT_DELETE') && 
                        <ExpressProjectAmbientDelete projectAmbient={projectAmbient} />
                    }
                </Group>
            </Group>}
        />
    </>)
}