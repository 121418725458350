import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Code from './pages/Code';
import Onboard from './pages/Onboard';
import Dashboard from './pages/Dashboard';
import MediaPlayer from './pages/MediaPlayer';
import UserContextProvider from './context/User/UserContextProvider';

import './assets/style.css';
import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';

const queryClient = new QueryClient();

function App() {  
    
    const myTheme = {
        colorScheme: 'light',
        colors: {
            'iteleport-orange': [
                "#EAE0DA",
                "#DEC9BD",
                "#D7B39F",
                "#D59E7E",
                "#DB895A",
                "#E87430",
                "#FF5E00",
                "#CF5B17",
                "#A95625",
                "#8D502D",
                "#764B31",
                "#644533",
                "#563F32"
              ]
        },
        primaryColor: 'iteleport-orange',
        defaultRadius: 5
    };

    return (
        <BrowserRouter basename="/">
            <UserContextProvider>
                <MantineProvider 
                    theme={myTheme}
                    styles={{}}
                >
                    <ModalsProvider>
                        <Notifications />
                        <QueryClientProvider client={queryClient}>
                            <Routes>
                                <Route path="/media/player/:media" element={<MediaPlayer />} />
                                <Route path="/code" element={<Code />} />
                                <Route path="/onboard/:code" element={<Onboard />} />
                                <Route path="*" element={<Dashboard />} />
                            </Routes>

                            <ReactQueryDevtools />
                        </QueryClientProvider>
                    </ModalsProvider>
                </MantineProvider>
            </UserContextProvider>
        </BrowserRouter>
    );
}

export default App;
