import React, {useEffect, useRef} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useForm } from '@mantine/form';
import BackendServiceV2 from 'services/v2/BackendService';
import { showNotification } from '@mantine/notifications';
import { Check, InfoCircle, X } from 'tabler-icons-react';
import { useModals } from '@mantine/modals';
import { Button, Stack, Text, TextInput } from '@mantine/core';
import equal from'fast-deep-equal';
import { SectionBox } from 'components/v2/SectionBox';
import { useDebouncedValue } from '@mantine/hooks';
import UploadArea from 'components/v2/UploadArea';
import { MIME_TYPES } from '@mantine/dropzone';

export function CompanyEdit({company}) {

    const queryClient = useQueryClient();

    const form = useForm({
        initialValues: {
            name: company?.name || '',
            site: company?.site || '',
            phone: company?.phone || '',
            financialContact: company?.financialContact || ''
        },
        validate: {
            
        }
    });

    // UPDATE AND UPLOAD
    const uploadArea = useRef(null);

    const {mutate: updateCompany, isLoading} = useMutation((data) => {
        return BackendServiceV2.put(`/company/${company?.idCompany}`, data);
    }, {
        onSuccess: () => {
            if(uploadArea.current.hasFile()) {
                uploadArea.current.upload({
                    mutate: async (files, config) => {
                        let formData = new FormData();
                        formData.append('logo', files[0]);
    
                        return BackendServiceV2.put(`/company/${company?.idCompany}/logo`, formData, config)
                    },
                    success: () => {
                        queryClient.invalidateQueries('company-data');

                        showNotification({
                            title: 'Empresa atualizada com sucesso',
                            message: '',
                            icon: <Check />,
                            color: 'green',
                            autoClose: 5000,
                        });
                    },
                    error: (error) => {
                        showNotification({
                            title: 'Um erro ocorreu',
                            message: error?.response?.data?.message,
                            icon: <X />,
                            color: 'red',
                            autoClose: 5000,
                        });
                    }
                });
            } else {
                queryClient.invalidateQueries('company-data');

                showNotification({
                    title: 'Empresa atualizada com sucesso',
                    message: '',
                    icon: <Check />,
                    color: 'green',
                    autoClose: 5000,
                });
            }
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    // DELETE
    const {mutate: deleteLogo} = useMutation(() => {
        return BackendServiceV2.delete(`/company/${company?.idCompany}/logo`);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('company-data');

            showNotification({
                title: 'Empresa atualizada com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const modals = useModals();
    const deleteLogoConfirmation = () => modals.openConfirmModal({
        title: 'Deletar logo',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja deletar a logo?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            deleteLogo();
        }
    });

    // AUTO UPDATE
    const [debouncedFormValues] = useDebouncedValue(form.values, 3000);

    useEffect(() => {
        form.validate();

        if(!form.isValid()) return;

        if(!equal({
            name: company?.name || '',
            site: company?.site || '',
            phone: company?.phone || '',
            financialContact: company?.financialContact || ''
        }, debouncedFormValues)) {
            updateCompany(debouncedFormValues);
        }
    // eslint-disable-next-line
    }, [debouncedFormValues])

    function onDrop() {
        updateCompany(debouncedFormValues);
    }

    return (
        <SectionBox 
            title="Informações" 
            icon={<InfoCircle />} 
            className="full-width"
            isLoading={isLoading}
            right={
                <Button type="submit" form="development-edit-form">Salvar</Button>
            }
        >
            <form id="company-edit-form" onSubmit={form.onSubmit(values => updateCompany(values))}>
                <Stack>
                    <TextInput 
                        label="Nome"
                        size="md"
                        required
                        {...form.getInputProps('name')}
                    />

                    <TextInput 
                        label="Site"
                        size="md"
                        {...form.getInputProps('site')}
                    />

                    <TextInput 
                        label="Telefone"
                        size="md"
                        {...form.getInputProps('phone')}
                    />
                    
                    <TextInput 
                        label="Contato financeiro"
                        size="md"
                        {...form.getInputProps('finacialContact')}
                    />

                    <UploadArea 
                        label="Logo"
                        size="md"
                        currentPreview={company.logo}
                        deleteCurrent={deleteLogoConfirmation}
                        maxSize={1024 * 5}
                        accept={[MIME_TYPES.png]}
                        multiple={false}
                        ref={uploadArea}
                        onDrop={onDrop}
                    />
                </Stack>
            </form>
        </SectionBox>
    );
}