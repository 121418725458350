import { ActionIcon, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Trash, X } from "tabler-icons-react";

export function LayerSkyboxDeleteButton({skybox}) {

    const queryClient = useQueryClient();

    const modals = useModals();

    const deleteSkybox = useMutation(() => {
        return BackendServiceV2.delete(`layer/skybox/${skybox.id}`)
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-layer-data');

            showNotification({
                title: 'Foto deletada com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const deleteSkyboxConfirmationModal = () => modals.openConfirmModal({
        title: 'Deletar Skybox',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja deletar a skybox {skybox.originalName}?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            deleteSkybox.mutate();
        }
    });

    return (
        <ActionIcon variant='transparent' onClick={deleteSkyboxConfirmationModal}><Trash color='white' /></ActionIcon>
    )
}