import { ActionIcon, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Copy, X } from "tabler-icons-react";

export function TotemDuplicateButton({totem}) {

    const modals = useModals();

    const duplicateTotem = useMutation(() => {
        return BackendServiceV2.post('/totem/' + totem?.id + '/duplicate')
    }, {
        onSuccess: (response) => {            
            showNotification({
                title: 'Showcase duplicado',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
            window.location.replace('/showcase/' + response.id);
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const duplicateTotemConfirmation = () => modals.openConfirmModal({
        title: 'Duplicar Showcase',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja duplicar o showcase <b>{totem?.name}</b>?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            duplicateTotem.mutate();
        }
    });

    return (<>
        <ActionIcon color="#ff5e00" variant="subtle" onClick={duplicateTotemConfirmation} title="Duplicar"><Copy /></ActionIcon>
    </>)
}