import { Alert, Button, Group, LoadingOverlay, Modal, Stack, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { AlertCircle, Check, Trash, X } from "tabler-icons-react";

export function TagsManagerCategoryDeleteTag({tag}) {

    const [opened, openedHandler] = useDisclosure(false);

    const queryClient = useQueryClient();

    const form = useForm({
        initialValues: {
            deleteConfirmation: ''
        },
        validate: {
            deleteConfirmation: (value) => (value.toUpperCase() === tag?.name?.toUpperCase() ? null : "O valor não é igual a " + tag?.name?.toUpperCase())
        }
    });

    const {mutate: deleteTag, isLoading} = useMutation(() => {
        return BackendServiceV2.delete(`/resource/metadata/${tag.id}`);
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries('metadata-category-list-data');
            openedHandler.close();
            form.reset();
            showNotification({
                title: 'Tag deletada com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    });

    return (<>
        <Button variant="subtle" onClick={openedHandler.open} leftIcon={<Trash color="#ff5e00" />} title="Deletar Tag">Deletar</Button>

        <Modal
            opened={opened}
            onClose={openedHandler.close}
            title={
                <Group>
                    <Group><Trash /><Title order={2}>Deletar Tag {tag.name}</Title></Group>
                </Group>
            }
            centered
            size="lg"
            styles={theme => ({
                title: {
                    width: '100%'
                }
            })}
        >
            <LoadingOverlay visible={isLoading} />
            <form id="metadata-category-create" onSubmit={form.onSubmit(values => deleteTag(values))}>
                <Stack>
                    <Alert icon={<AlertCircle size={16} />} title="Atenção!" color="red">
                        Deletar uma tag irá deletar todos os valores já configurados. Isso não poderá ser desfeito!
                    </Alert>

                    <TextInput 
                        label={`Digite "${tag?.name?.toUpperCase()}" para confirmar a exclusão.`}
                        {...form.getInputProps('deleteConfirmation')}
                    />
                    
                    <Group>
                        <Button variant="outline" onClick={openedHandler.close}>Voltar</Button>
                        <Button type="submit">Deletar</Button>
                    </Group>
                </Stack>
            </form>
        </Modal>
    </>)
}