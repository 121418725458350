import React, { forwardRef, useImperativeHandle, useState } from "react";

const TeleportScanEmbed = forwardRef(function TeleportScanEmbed({teleport, visible}, ref) {

    const [key, setKey] = useState(0);

    useImperativeHandle(ref, () => {
        return {
            reload() {
                setKey(k => k + 1);
            }
        }
    }, []);

    if(!visible) return <></>

    return (
        <iframe
            className="player"
            key={key}
            title="player" 
            allowFullScreen={true}
            allow="fullscreen; camera; microphone; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay"
            src={window.ENV.PlayerURL + teleport?.id + "?background-load=false"}
        >
        </iframe>
    )
});

export default TeleportScanEmbed;