import { Button, Checkbox, Select, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import React, { forwardRef, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";

export function TeleportLayerCreateAndEdit({idTeleport = undefined, parentLayer = undefined, updateLayerData = undefined, handler = undefined}) {

    const queryClient = useQueryClient();

    // FORM CONFIG
    const form = useForm({
        initialValues: {
            name: updateLayerData?.name || '',
            technical: updateLayerData?.technical || '',
            delivered: updateLayerData?.delivered || '',
            //type: updateLayerData?.type || '',
            childDisplayBehavior: updateLayerData?.childDisplayBehavior || ''
        }
    });

    useEffect(() => {
        if(updateLayerData) {
            form?.setFieldValue('name', updateLayerData?.name);
            form?.setFieldValue('technical', updateLayerData?.technical);
            form?.setFieldValue('delivered', updateLayerData?.delivered);
            //form?.setFieldValue('type', updateLayerData?.type);
            form?.setFieldValue('childDisplayBehavior', updateLayerData?.childDisplayBehavior);
        }
    // eslint-disable-next-line
    }, [updateLayerData])

    const {mutate: createNewLayer, isLoading} = useMutation((data) => {
        if(parentLayer)
            data.parent = parentLayer.id

        if(!updateLayerData)
            delete data?.childDisplayBehavior;

        if(updateLayerData) {
            return BackendServiceV2.put(`/layer/${updateLayerData.id}`, data)
        } else {
            return BackendServiceV2.post(`/teleport/${idTeleport}/layer`, data)
        }
    }, {
        onSuccess: (response) => {
            queryClient.invalidateQueries('teleport-layer-data');
            queryClient.invalidateQueries('teleport-data');

            if(updateLayerData) {
                showNotification({
                    title: 'Layer atualizada com sucesso',
                    message: '',
                    autoClose: 5000,
                    color: 'green',
                    icon: <Check />
                });
            } else {
                showNotification({
                    title: 'Layer criada com sucesso',
                    message: '',
                    autoClose: 5000,
                    color: 'green',
                    icon: <Check />
                });
                form.reset();
                handler?.close();
            }
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    return (
        <SectionBox
            title={handler ? '' : updateLayerData ? 'Configurações' : 'Criar nova Layer'}
            description={updateLayerData ? 'Modifique as informações da layer' : ''}
            isLoading={isLoading}
        >
            <form onSubmit={form.onSubmit(values => createNewLayer(values))}>
                <Stack>
                    <TextInput
                        required
                        label="Nome"
                        {...form.getInputProps('name')}
                    />

                    <Checkbox
                        label="Entregue"
                        name="delivered"
                        size="md"
                        checked={form.getInputProps('delivered').value}
                        {...form.getInputProps('delivered')}
                    />

                    <Checkbox
                        label="Técnico"
                        name="technical"
                        size="md"
                        checked={form.getInputProps('technical').value}
                        {...form.getInputProps('technical')}
                    />

                    {false && <Select
                        required
                        label="Tipo"
                        placeholder="Selecione um"
                        data={[
                            {value: 'DEVELOPMENT', label: 'Empreendimento'},
                            {value: 'BUILDING', label: 'Construção'},
                            {value: 'EXTERIOR_FLOOR', label: 'Andares exteriores'},
                            {value: 'APARTMENT', label: 'Apartamento'},
                            {value: 'TYPOLOGY', label: 'Tipologia'},
                            {value: 'INTERIOR_FLOOR', label: 'Andares interior'},
                            {value: 'AMBIENT', label: 'Ambiente'},
                            {value: 'LAYOUT', label: 'Layout'},
                            {value: 'GROUP', label: 'Grupo'},
                        ]}
                        {...form.getInputProps('type', { type: 'select' })}
                    />}

                    {updateLayerData?.childs?.length > 0 &&
                        <Select
                            required
                            label="Comportamento de exibição das layer filhas"
                            placeholder="Selecione um"
                            itemComponent={CustomChildDisplayBehavioSelectItem}
                            data={[
                                {value: 'INCLUSIVE', label: 'Inclusivo', description: 'Todas as layers filhas são exibidas simultaneamente'},
                                {value: 'EXCLUSIVE', label: 'Exclusivo', description: 'Apenas uma layer filha é exibida por vez, as demais são ocultadas'},
                                {value: 'FLOOR', label: 'Andares', description: 'As layers filhas se comportam como andares. Ativa o botão de troca de andar'},
                                {value: 'SLICE', label: 'Corte', description: 'As layers filhas são ocultadas de cima para baixo, mantendo as layer ocultadas'},
                            ]}
                            {...form.getInputProps('childDisplayBehavior', { type: 'select' })}
                        />
                    }

                    <Button type="submit">{updateLayerData ? 'Salvar' : 'Criar'}</Button>
                </Stack>
            </form>
            
        </SectionBox>
    )
}

const CustomChildDisplayBehavioSelectItem = forwardRef (
    ({ image, label, description, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Stack spacing={3}>  
            <Text size="sm" lineClamp={1}>{label}</Text>
            <Text size="xs" opacity={0.65} lineClamp={1}>
                {description}
            </Text>
        </Stack>
      </div>
    )
  );