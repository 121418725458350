import React, {useState, useEffect, useRef} from 'react';

function FileUploader({ hiddenInput, progress, autoSubmit}) {

    const projectInputPercentRef = useRef(null);
    const submitUpload = useRef(null);

    const [fileList, setFilesList] = useState('No file(s) selected');
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        if(hiddenInput !== null) {
            hiddenInput.current.addEventListener('change', () => {                
                let fileNameList = Array.prototype.map.call(hiddenInput.current.files, file => {
                    return file.name;
                })

                setFilesList(fileNameList.join(', '));

                if(autoSubmit) {
                    submitUpload.current.click();
                    hiddenInput.current.disabled = true;
                }
            });
        }
    }, [autoSubmit, hiddenInput]);

    useEffect(() => {
        if(progress !== undefined) 
            setIsUploading(true);
    }, [progress]);

    useEffect(() => {
        if(isUploading) {
            projectInputPercentRef.current.parentElement.style.width =  progress + '%';

            if(progress < 100) {
                projectInputPercentRef.current.innerHTML = progress + '%';
            } else {
                projectInputPercentRef.current.innerHTML = 'Finishing...';
                hiddenInput.current.disabled = false;
                hiddenInput.current.value = '';
                projectInputPercentRef.current.parentElement.style.width = "auto";
                setFilesList('No file(s) selected');
                setIsUploading(false);
            }
        }
    }, [hiddenInput, isUploading, progress]);

    function chooseFileUpload() {
        hiddenInput.current.click();        
    }

    return (
        <>
            { !isUploading ? (
                <>
                    <span className="file-upload" onClick={() => chooseFileUpload()}>
                        <span className="choose-btn">Upload a file</span>
                        {!autoSubmit && <span className="file-list">{fileList}</span>}
                    </span>
                    <button style={{display: "none"}} ref={submitUpload}>Upload</button>
                </>
            ) : (
                <>
                    <span className="progress-bar">
                        <span className="progress"><span className="percent" ref={projectInputPercentRef}>0%</span></span>
                    </span>
                </>
            )}
        </>
    );
}

export default FileUploader;