import { Button, Checkbox, Group, Image, LoadingOverlay, Modal, Stack, TextInput, Textarea, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Plus, X } from "tabler-icons-react";
import { TeleportCarouselContext } from "../../Context/TeleportCarouselContext";

export function CarouselEntryFromSkyboxModal({opened, handle, idCarouselGroup}) {

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // CONFIGURE FORM
    const form = useForm({
        initialValues: {
            title: '',
            description: '',
            betweenEntry: false
        }
    });

    // CREATE ENTRY
    const {mutate: createCarouselEntryFromSkybox} = useMutation((data) => {
        return BackendServiceV2.post(`/carousel/group/${idCarouselGroup}/entry`, {
            title: data.title || crypto.randomUUID(),
            pitch: currentLocation?.pitch,
            yaw: currentLocation?.yaw,
            idSkybox: currentLocation?.idSkybox,
            description: data.description,
            betweenEntry: data.betweenEntry,
            skyboxSet: currentLocation?.isSkyboxSet
        });
    }, {
        onSuccess: (response) => {
            uploadCarouselEntryThumb(response.id);
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 2000,
            });
        }
    });

    const {mutate: uploadCarouselEntryThumb, isLoading} = useMutation((idCarouselEntry) => {
        const file = DataURIToBlob(skyboxScreenshot)

        let formData = new FormData();
        formData.append('data', file);

        return BackendServiceV2.put(`/carousel/group/entry/${idCarouselEntry}/thumb`, formData);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-data');
            form.reset();
            handle.close()

            showNotification({
                title: 'Nova entrada adicionada com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 2000,
            });
        },
        onError: (error, data) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 2000,
            });

            BackendServiceV2.delete(`/carousel/group/entry/${data.idCarouselEntry}`);
        }
    });

    function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }

    // IFRAME DATA
    const [currentLocation, setCurrentLocation] = useState();
    const [skyboxScreenshot, setSkyboxScreenshot] = useState();

    // IFRAME CONTEXT
    const carouselContext = useContext(TeleportCarouselContext);

    useEffect(() => {
        subscribeToIframeMessage(carouselContext.iFrameMessage);
    // eslint-disable-next-line
    }, [carouselContext]);

    function subscribeToIframeMessage(message) {        
        if(message?.type === 'CURRENT_LOCATION_DATA') {
            setCurrentLocation(message.data);
        }

        if(message?.type === 'SCREENSHOT') {
            setSkyboxScreenshot(message.data);
        }
    };

    useEffect(() => {
        if(!opened) return;

        carouselContext.sendIframeMessage('CURRENT_LOCATION_REQUEST');
        carouselContext.sendIframeMessage('TAKE_SCREENSHOT');
    // eslint-disable-next-line
    }, [opened]);

    return (
        <Modal
            opened={opened}
            onClose={handle.close}
            title={
                <Group><Plus /><Title order={2}>Nova entrada do ponto de vista atual</Title></Group>
            }
            centered
            size="lg"
        >
            <LoadingOverlay visible={isLoading} />

            <form onSubmit={form.onSubmit(values => createCarouselEntryFromSkybox(values))}>
                <Stack style={{width: '100%'}}>
                    <Image src={skyboxScreenshot} width="100%" />
                    <TextInput
                        required={!form.getInputProps('betweenEntry').value}
                        label="Título"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('title')}
                    />

                    <Textarea
                        label="Descrição"
                        size="md"
                        {...form.getInputProps('description')}
                    />

                    <Checkbox
                        label="Definir como skybox de transição"
                        size="md"
                        checked={form.getInputProps('betweenEntry').value}
                        {...form.getInputProps('betweenEntry')}
                    />

                    <Button type="submit">Salvar</Button>
                </Stack>
            </form>
        </Modal>
    )
}