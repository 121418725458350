import { Avatar, Badge, Button, Group, Stack, Text } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import React from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import AuthService from 'services/AuthService';
import { Check, DoorEnter, Mail, X } from 'tabler-icons-react';

function UserBox({ user }) {

    const resendOnboardMail = useMutation((email) => {
        return AuthService.post(`/account/${email}/onboard`);
    }, {
        onSuccess: data => {
            showNotification({
                title: 'Onboarding reenviado com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    const modal = useModals();

    const resendOnboardMailModal = (email) => modal.openConfirmModal({
        title: 'Você tem certeza que deseja reenviar o email de convite para ' + email + '?',
        children: (<></>),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: () => {
            resendOnboardMail.mutate(email);
        },
        zIndex: 1000
    });

    return (
        <Group position="apart">
            <Group noWrap>
                <Avatar color="iteleport-orange" size={50} src={user?.profilePicture?.webp ? window.ENV.StaticURL + user?.profilePicture?.webp : null} >
                    {user?.name.split(' ').map((letter, i) => (
                        i < 2 ? letter.charAt(0).toUpperCase() : ''
                    ))}
                </Avatar>

                <Stack spacing={0}>
                    <Group><Link to={"/user/" + user.id}><Text weight={600} size="xl">{user.name}</Text></Link><Badge variant="outline">{user.enabled ? user.roles[0].name : 'Pendente'}</Badge></Group>
                    <Link to={"/user/" + user.id}><Text color="dimmed">{user.email}</Text></Link>
                </Stack >
            </Group>
                         

            <Group position="right">
                {!user.enabled && <>
                    <Button
                        leftIcon={<Mail color="white" />}
                        title="Onboarding"
                        style={{color: '#FFF'}}
                        onClick={e => resendOnboardMailModal(user.email)}
                    >Reenviar convite</Button>

                    <Button 
                        component="a"
                        target="_blank"
                        leftIcon={<DoorEnter color="white" />}
                        href={"/onboard/" + user.id}
                        title="Onboarding"
                        style={{color: '#FFF'}}
                    >Onboarding</Button>
                </>}
            </Group>
        </Group>
    )
}

export default UserBox;