import { Button, Checkbox, Group, Image, LoadingOverlay, Menu, Modal, Stack, TextInput, Textarea, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Edit, Plus, X } from "tabler-icons-react";

export function TeleportCarouselEditEntryButton({entry}) {

    const queryClient = useQueryClient();

    const [opened, openedHandler] = useDisclosure(false);

    const form = useForm({
        initialValues: {
            title: entry?.title || '',
            description: entry?.description || '',
            betweenEntry: entry?.betweenEntry || false
        },
        validate: {
            
        }
    });

    const {mutate: updateEntry, isLoading} = useMutation((data) => {
        return BackendServiceV2.put('/carousel/group/entry/' + entry.id, data);
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries('teleport-data');
            openedHandler.close();
            
            showNotification({
                title: 'Destaque editado com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    return (<>
        <Menu.Item icon={<Edit />} onClick={openedHandler.open}>Editar</Menu.Item>

        <Modal
            opened={opened}
            onClose={openedHandler.close}
            title={
                <Group><Plus /><Title order={2}>Editanto destaque</Title></Group>
            }
            centered
            size="lg"
        >
            <LoadingOverlay visible={isLoading} />

            <form onSubmit={form.onSubmit(values => updateEntry(values))}>
                <Stack style={{width: '100%'}}>
                    <Image src={window.ENV.StaticURL + entry.thumbWebp} width="100%" />
                    <TextInput
                        required
                        label="Título"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('title')}
                    />

                    <Textarea
                        label="Descrição"
                        size="md"
                        {...form.getInputProps('description')}
                    />

                    <Checkbox
                        label="Definir como skybox de transição"
                        size="md"
                        checked={form.getInputProps('betweenEntry').value}
                        {...form.getInputProps('betweenEntry')}
                    />

                    <Button type="submit">Salvar</Button>
                </Stack>
            </form>
        </Modal>
    </>)
}