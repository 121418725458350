import { ActionIcon, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Trash, X } from "tabler-icons-react";

export function DevelopmentDeleteButton({development}) {

    const modals = useModals();

    const deleteDevelopment = useMutation(() => {
        return BackendServiceV2.delete(`/development/${development?.idDevelopment}`)
    }, {
        onSuccess: () => {
            showNotification({
                title: 'Empreendimento deletado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });

            window.location.replace('/');
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const deleteDevelopmentConfirmation = () => modals.openConfirmModal({
        title: 'Deletar foto',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja deletar o empreendimento {development?.name}?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            deleteDevelopment.mutate();
        }
    });


    return (
        <ActionIcon variant="transparent" onClick={deleteDevelopmentConfirmation}><Trash color="#ff5e00" /></ActionIcon>
    )
}