import { Button, Group, Modal, Select, Space, Textarea, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { updateValue, valueOf } from "hooks/usePhoneMask";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Edit, X } from "tabler-icons-react";

export function SalesTeamLeadEdit({lead, opened, openedHandler, finishOnClose, setFinishOnClose}) {

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // FORM
    const qualificationOptions = [
        {
            value: 'HOT',
            label: 'Quente'
        },
        {
            value: 'WARM',
            label: 'Morno'
        },
        {
            value: 'COLD',
            label: 'Frio'
        },
        {
            value: 'TEST',
            label: 'Teste'
        }
    ]

    const form = useForm({
        initialValues: {
            name: lead.name ? lead.name : undefined,
            phone: lead.phone ? lead.phone : undefined,
            email: lead.email ? lead.email : undefined,
            observations: lead.observations ? lead.observations : undefined,
            leadQualification: lead.leadQualification ? lead.leadQualification : undefined,
        },
    
        validate: {
            leadQualification: (value) => value ? null : 'A qualificação não pode ser vazia'
        },
    })

    const editLead = useMutation((values) => {
        return BackendServiceV2.put('/salesteam/lead/' + lead.id, values);
    }, {
        onSuccess: response => {
            showNotification({
                title: <>Chamada atualizada com sucesso</>,
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })

            if(finishOnClose) {
                closeLead();
            } else {
                queryClient.invalidateQueries('sales-team-leads');
            }
            
            openedHandler.close();
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    async function closeLead() {
        await BackendServiceV2.put('/salesteam/lead/' + lead.id, {status: 'FINISHED'});
        setFinishOnClose(false);
        queryClient.invalidateQueries('sales-team-leads');
    }

    return (
        <Modal
            opened={opened}
            withCloseButton={!finishOnClose}
            closeOnClickOutside={!finishOnClose}
            onClose={e => {
                openedHandler.close();
                if(finishOnClose)
                    closeLead();
            }}
            title={<Group><Edit /><Title order={2}>Editar</Title></Group>}
            centered
            size="lg"
        >
            <form onSubmit={form.onSubmit(values => {editLead.mutate(values)})}>
                <TextInput
                    required
                    label="Nome"
                    placeholder=""
                    size="md"
                    {...form.getInputProps('name')}
                />

                <TextInput
                    label="Telefone"
                    placeholder=""
                    size="md"
                    value={valueOf(form.getInputProps('phone').value)}
                    onChange={e => {
                        e.preventDefault();
                        updateValue(e.target.value, form.getInputProps('phone').onChange);
                    }}
                    maxLength={15}
                    minLength={14}
                />

                <TextInput
                    label="Email"
                    placeholder=""
                    size="md"
                    {...form.getInputProps('email')}
                />

                <Textarea
                    required={finishOnClose}
                    label="Observações"
                    placeholder=""
                    size="md"
                    {...form.getInputProps('observations')}
                />
            
                <Select
                    required={finishOnClose}
                    allowDeselect={!finishOnClose}
                    label="Qualificação"
                    placeholder="Selecione um"
                    size="md"
                    data={qualificationOptions}
                    {...form.getInputProps('leadQualification')}
                />

                <Space h="lg" />

                <Group position="right">
                    <Button type="submit">Salvar</Button>
                </Group>
            </form>
        </Modal>
    )
}