import { Button, Group, Modal, Stack, TextInput, Title } from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import UploadArea from "components/v2/UploadArea";
import React, { useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Check, Plus, X } from "tabler-icons-react";
import queryString from 'query-string';
import BackendServiceV2 from "services/v2/BackendService";

export function CarouselEntryFromTeleportModal({opened, handle, idCarouselGroup}) {

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // CONFIGURE FORM
    const form = useForm({
        initialValues: {
            title: '',
            shareUrl: ''
        }
    });

    // CREATE ENTRY
    const uploadArea = useRef(null);

    const {mutate: createCarouselEntryFromTeleport} = useMutation((data) => {
        uploadArea.current.validate();

        let parsedUrl = queryString.parseUrl(data.shareUrl);

        return BackendServiceV2.post(`/carousel/group/${idCarouselGroup}/entry`, {
            title: data.title,
            pitch: parsedUrl?.query['pitch'],
            yaw: parsedUrl?.query['yaw'],
            idSkybox: parsedUrl?.query['skybox'],
            idTeleport: parsedUrl?.url?.split('/')?.pop(),
        });
    }, {
        onSuccess: (response) => {
            uploadArea.current.upload({
                mutate: async (files, config) => {
                    let formData = new FormData();
                    formData.append('data', files[0]);

                    return BackendServiceV2.put(`/carousel/group/entry/${response.id}/thumb`, formData, config)
                },
                success: () => {
                    queryClient.invalidateQueries('teleport-data');
                    form.reset();
                    handle.close();
                    
                    showNotification({
                        title: 'Nova entrada adicionada com sucesso',
                        message: '',
                        icon: <Check />,
                        color: 'green',
                        autoClose: 2000,
                    });
                },
                error: (error) => {
                    showNotification({
                        title: 'Um erro ocorreu',
                        message: error?.response?.data?.message,
                        icon: <X />,
                        color: 'red',
                        autoClose: 2000,
                    });
        
                    BackendServiceV2.delete(`/carousel/group/entry/${response.id}`);
                }
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 2000,
            });
        }
    });

    return (
        <Modal
            opened={opened}
            onClose={handle.close}
            title={
                <Group><Plus /><Title order={2}>Nova entrada para outro Teleport</Title></Group>
            }
            centered
            size="lg"
        >
            <form onSubmit={form.onSubmit(values => createCarouselEntryFromTeleport(values))}>
                <Stack style={{width: '100%'}}>
                    <TextInput
                        required
                        label="Título"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('title')}
                    />

                    <TextInput
                        required
                        label="URL de compartilhamento"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('shareUrl')}
                    />

                    <UploadArea 
                        rejectMessage="Erro"
                        maxSize={1024 * 5}
                        accept={[MIME_TYPES.jpeg]}
                        multiple={false}
                        ref={uploadArea}
                    />

                    <Button type="submit">Salvar</Button>
                </Stack>
            </form>
        </Modal>
    )
}