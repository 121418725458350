import { Text } from "@mantine/core";
import React from "react";
import './style.css';

export function Grid({children}) {
    return (
        <div className="grid">
            {children}
        </div>
    )
}

export function GridEntry({title, right = undefined, actions = undefined, children}) {
    return (
        <div className="grid-entry">
            <div className="grid-entry-header">
                <div className="grid-entry-header-left">
                    <abbr title={title}><Text weight={600} lineClamp={1}>{title}</Text></abbr>
                </div>

                <div className="grid-entry-header-right">
                    {right}
                </div>
            </div>

            <div className="grid-entry-content">
                {children}
            </div>

            <div className="grid-entry-actions">
                {actions}
            </div>
        </div>
    )
}