import { Group, Progress, Space, Stack, Text, Tooltip } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { Help } from "tabler-icons-react";

import './style.css'

export function SectionBox({icon = undefined, 
                            title = undefined, 
                            description = undefined, 
                            tooltip = undefined, 
                            className = undefined, 
                            right = undefined, 
                            top = undefined, 
                            background = true, 
                            fullWidth = false,
                            isLoading = false,
                            withBorder = true,
                            padding = "20px",
                            children
    }) {

    const [classes, setClasses] = useState(undefined);

    useEffect(() => {       
        if(className !== undefined) {
            setClasses('iteleport-section ' + className);
        } else {
            setClasses('iteleport-section');
        }
    }, [className]);

    return (
        <div className={(fullWidth ? 'full-width ' : '') + (withBorder ? '' : 'without-border ') + classes} >
            <Group mb={15} position="apart">
                <Stack spacing={0}>
                    <Group noWrap align="center">
                        {icon && icon}
                        <Text weight={600} size={25} lineClamp={1}>{title}</Text>
                        {tooltip && <Tooltip label={tooltip} withArrow arrowSize={5}><Help size={18} /></Tooltip>}
                    </Group>
                    <Text color="dimmed" size="sm">{description}</Text>
                </Stack>
                <Group>
                    {right}
                </Group>
            </Group>

            {top && <>
                {top}
                <Space h="lg" />
            </>}
 
            <div className={"section-content " + (!background ? 'no-background' : '')}>
                <Progress size="md" value={100} hidden={!isLoading} striped animate />

                <div className="section-content-body" style={{padding: padding}}>
                    {children}
                </div>
            </div>
        </div>
    )
}