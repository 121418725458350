import React, { useEffect, useState } from 'react';
import UserService from '../../../../services/UserService';
import { ContentWrapper, DashboardContent, LeftContent, RightContent } from 'components/v2/Dashboard/Layout';
import { IconUserScreen } from '@tabler/icons-react';
import './style.css';
import TotemInterfaceCreate from 'components/v2/Totem/TotemInterfaceCreate';
import { TotemList } from 'components/v2/Totem/TotemList';

export function TotemListPage() {

    // PERMISSIONS
    const [ADMIN_ROLE, setAdminRole] = useState(false);

    useEffect(() => {
        async function getPermissions() {
            setAdminRole(await UserService.hasRole('ADMIN'));
        }

        getPermissions();
    }, []);
    
    return (
        <DashboardContent 
            title="Showcase"
            icon={<IconUserScreen size={30} />}
        >
            <ContentWrapper>
                <LeftContent>
                    <TotemList />
                </LeftContent>
                <RightContent>
                    {ADMIN_ROLE && <>
                        <TotemInterfaceCreate />
                    </>}
                </RightContent>
            </ContentWrapper>
        </DashboardContent>
    );
}