import { ContentWrapper, DashboardContent, LeftContent, RightContent } from "components/v2/Dashboard/Layout";
import { ExpressCreate } from "components/v2/Express/ExpressCreate";
import { ExpressList } from "components/v2/Express/ExpressList";
import React, { useEffect, useState } from "react";
import UserService from "services/UserService";
import { Bolt } from "tabler-icons-react";

export function ExpressListPage() {

    // PERMISSIONS
    const [ADMIN_ROLE, setAdminRole] = useState(false);

    useEffect(() => {
        async function getPermissions() {
            setAdminRole(await UserService.hasRole('ADMIN'));
        }

        getPermissions();
    }, []);

    return (
        <DashboardContent 
            title="Express"
            icon={<Bolt size={30} />}
        >
            <ContentWrapper>
                <LeftContent>
                    <ExpressList />
                </LeftContent>

                {ADMIN_ROLE && <>
                    <RightContent>
                        <ExpressCreate />
                    </RightContent>
                </>}
            </ContentWrapper>
        </DashboardContent>
    )
}