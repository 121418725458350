import React, { useEffect, useState } from "react";
import { Center, LoadingOverlay, Pagination, Space } from "@mantine/core";
import { SalesTeamCard } from "../SalesTeamCard";
import { SearchBox } from "components/v2/SearchBox";
import { useNavigate } from "react-router-dom";
import BackendServiceV2 from "services/v2/BackendService";
import { useQuery, useQueryClient } from "react-query";

export function SalesTeamList() {

    const queryClient = useQueryClient();

    const navigate = useNavigate();

    // SALES TEAM DATA
    const [currentPage, setCurrentPage] = useState(1);
    const [title, setTitle] = useState(undefined);

    useEffect(() => {
        setCurrentPage(0);
    }, [title]);

    const {data: salesTeams, isLoading} = useQuery(['sales-team-list-data', currentPage, title], () => {
        let url = `/salesteam/?page=${currentPage}`;
                
        if(title)
            url += `&name=${title}`

        return BackendServiceV2.get(url);
    }, {
        onSuccess: data => {
            if(data.result.length === 1)
                navigate('/connect/' + data.result[0].id);
        }
    });

    return (
        <>
            <LoadingOverlay visible={isLoading} />

            <SearchBox onSearch={setTitle} />

            {salesTeams?.result.map(salesTeam => (
                <SalesTeamCard key={salesTeam.id} salesTeam={salesTeam} />
            ))}

            {salesTeams?.result?.length === 0 && <Center>Nenhum Time de Vendas encontrado</Center>}

            {salesTeams?.result?.length > 0 && <>
                <Space h="lg" />
                <Pagination 
                    value={salesTeams?.pageNumber + 1} 
                    onChange={(page) => {setCurrentPage(page - 1); queryClient.invalidateQueries('teleport-list-data')}} 
                    total={salesTeams?.totalPages} 
                />
            </>}
        </>
    )
}