import React, {useEffect, useRef} from 'react';
import { Check, InfoCircle, X } from 'tabler-icons-react';
import { SectionBox } from 'components/v2/SectionBox';
import { Button, Stack, Text, Textarea, TextInput } from '@mantine/core';
import { useMutation, useQueryClient } from 'react-query';
import BackendServiceV2 from 'services/v2/BackendService';
import { useForm } from '@mantine/form';
import { MIME_TYPES } from '@mantine/dropzone';
import UploadArea from 'components/v2/UploadArea';
import { showNotification } from '@mantine/notifications';
import { useDebouncedValue } from '@mantine/hooks';
import equal from'fast-deep-equal';
import { useModals } from '@mantine/modals';

export function DevelopmentEdit({development}) {

    const queryClient = useQueryClient();

    const form = useForm({
        initialValues: {
            name: development?.name || '',
            site: development?.site || '',
            description: development?.description || '',
            address: development?.address || ''
        },
        validate: {
            
        }
    });

    // UPDATE AND UPLOAD
    const uploadArea = useRef(null);

    const {mutate: updateDevelopment, isLoading} = useMutation((data) => {
        return BackendServiceV2.put(`/development/${development?.idDevelopment}`, data);
    }, {
        onSuccess: () => {
            if(uploadArea.current.hasFile()) {
                uploadArea.current.upload({
                    mutate: async (files, config) => {
                        let formData = new FormData();
                        formData.append('logo', files[0]);
    
                        return BackendServiceV2.put(`/development/${development?.idDevelopment}/logo`, formData, config)
                    },
                    success: () => {
                        queryClient.invalidateQueries('development-data');

                        showNotification({
                            title: 'Empreendimento atualizado com sucesso',
                            message: '',
                            icon: <Check />,
                            color: 'green',
                            autoClose: 5000,
                        });
                    },
                    error: (error) => {
                        showNotification({
                            title: 'Um erro ocorreu',
                            message: error?.response?.data?.message,
                            icon: <X />,
                            color: 'red',
                            autoClose: 5000,
                        });
                    }
                });
            } else {
                queryClient.invalidateQueries('development-data');

                showNotification({
                    title: 'Empreedimento atualizado com sucesso',
                    message: '',
                    icon: <Check />,
                    color: 'green',
                    autoClose: 5000,
                });
            }
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    // DELETE
    const {mutate: deleteLogo} = useMutation(() => {
        return BackendServiceV2.delete(`/development/${development?.idDevelopment}/logo`);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('development-data');

            showNotification({
                title: 'Empreendimento atualizado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const modals = useModals();
    const deleteLogoConfirmation = () => modals.openConfirmModal({
        title: 'Deletar logo',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja deletar a logo?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            deleteLogo();
        }
    });

    // AUTO UPDATE
    const [debouncedFormValues] = useDebouncedValue(form.values, 3000);

    useEffect(() => {
        form.validate();

        if(!form.isValid()) return;

        if(!equal({
            name: development?.name || '',
            site: development?.site || '',
            description: development?.description || '',
            address: development?.address || ''
        }, debouncedFormValues)) {
            updateDevelopment(debouncedFormValues);
        }
    // eslint-disable-next-line
    }, [debouncedFormValues])

    function onDrop() {
        updateDevelopment(debouncedFormValues);
    }

    const [debouncedAddress] = useDebouncedValue(form.getInputProps('address').value, 1000);

    return (
        <SectionBox 
            title="Informações" 
            icon={<InfoCircle />} 
            className="full-width"
            isLoading={isLoading}
            right={
                <Button type="submit" form="development-edit-form">Salvar</Button>
            }
        >
            <form id="development-edit-form" onSubmit={form.onSubmit(values => updateDevelopment(values))}>
                <Stack>
                    <TextInput 
                        label="Nome"
                        size="md"
                        required
                        {...form.getInputProps('name')}
                    />

                    <TextInput 
                        label="Site"
                        size="md"
                        {...form.getInputProps('site')}
                    />

                    <Stack spacing={0}>
                        <TextInput 
                            label="Localização"
                            size="md"
                            radius={0}
                            {...form.getInputProps('address')}
                        />
                        <iframe
                            title="Development Address"
                            width="100%"
                            height="350"
                            loading="lazy"
                            allowFullScreen
                            referrerPolicy="no-referrer-when-downgrade"
                            src={"https://www.google.com/maps/embed/v1/place?key=" + window.ENV.GoogleMapsEmbedKey + "&q=" + (debouncedAddress ? debouncedAddress : 'São Paulo')}>
                        </iframe>
                    </Stack>
                    
                    <Textarea
                        size="md"
                        label="Descrição"
                        {...form.getInputProps('description')}
                    />

                    <UploadArea 
                        label="Logo"
                        size="md"
                        currentPreview={development.logo}
                        deleteCurrent={deleteLogoConfirmation}
                        maxSize={1024 * 5}
                        accept={[MIME_TYPES.png]}
                        multiple={false}
                        ref={uploadArea}
                        onDrop={onDrop}
                    />
                </Stack>
            </form>
        </SectionBox>
    );
}