import React, { useState } from "react";
import { UserContext } from "./UserContext";

function UserContextProvider({children}) {

    const [loggedUserData, setLoggedUserData] = useState();

    return (
        <UserContext.Provider value={{loggedUserData, setLoggedUserData}}>
            {children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;