import { ActionIcon, Avatar, Badge, Button, Group, Menu, Select, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useModals } from "@mantine/modals";
import { showNotification, updateNotification } from "@mantine/notifications";
import { CopyTextInput } from "components/v2/Form/CopyTextInput";
import { GridEntry } from "components/v2/Grid";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import dayjs from "dayjs";
import { valueOf } from "hooks/usePhoneMask";
import React, { forwardRef, useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Dots, Trash, X } from "tabler-icons-react";
import { SalesTeamLeadEdit } from "../SalesTeamLeadEdit";

export function SalesTeamLeadCard({lead, userData}) {

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    // QUERY CLIENT
    const queryClient = useQueryClient();

    function processStatusText(status) {
        switch (status) {
            case 'OPEN':
                return 'Aberta'
            case 'FINISHED':
                return 'Encerrada';
            case 'SCHEDULED':
                return 'Agendada';
            case 'CANCELED':
                return 'Cancelada';
            default:
                return null;
        }
    }

    function processQualificationText(qualification) {
        switch (qualification) {
            case 'HOT':
                return 'Quente'
            case 'WARM':
                return 'Morno';
            case 'COLD':
                return 'Frio';
            default:
                return null;
        }
    }

    // CONTROL EDITING MODAL
    const [editModal, editModalHandler] = useDisclosure(false);
    const [finishOnClose, setFinishOnClose] = useState(false);

    // ASSIGN USER
    const SelectItem = forwardRef(
        ({ image, label, email, ...others }, ref) => (
            <div ref={ref} {...others}>
                <Group noWrap>
                <Avatar src={image ? window.ENV.StaticURL + image : null}>
                    {label.split(' ').map((letter, i) => (
                        i < 2 ? letter.charAt(0).toUpperCase() : ''
                    ))}
                </Avatar>
        
                <div>
                    <Text size="sm">{label}</Text>
                    <Text size="xs" color="dimmed">{email}</Text>
                </div>
                </Group>
            </div>
        )
    );

    const assignUser = useMutation((value) => {
        showNotification({
            id: 'assign-user',
            loading: true,
            title: 'Definindo atendente',
            message: '',
            autoClose: false,
            disallowClose: true,
        });

        return BackendServiceV2.post(`/salesteam/lead/${lead.id}/user`, {idUser: value});
    }, {
        onSuccess: response => {
            updateNotification({
                id: 'assign-user',
                title: 'Atendente definido',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });

            queryClient.invalidateQueries('sales-team-leads')
        },
        onError: error => {
            updateNotification({
                id: 'assign-user',
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            });
        }
    });

    const unassignUser = useMutation(() => {
        showNotification({
            id: 'assign-user',
            loading: true,
            title: 'Definindo atendente',
            message: '',
            autoClose: false,
            disallowClose: true,
        });

        return BackendServiceV2.delete('/salesteam/lead/' + lead.id + '/user');
    }, {
        onSuccess: response => {
            updateNotification({
                id: 'assign-user',
                title: 'Atendente removido',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });

            queryClient.invalidateQueries('sales-team-leads')
        },
        onError: error => {
            updateNotification({
                id: 'assign-user',
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            });
        }
    });

    const modals = useModals();

    const deleteLeadModal = () => modals.openConfirmModal({
            title: 'Deletar chamada',
            centered: true,
            children: (
                <Text size="sm">
                    Você tem certeza que deseja deletar essa chamada?
                </Text>
            ),
            labels: { confirm: 'Sim', cancel: 'Não' },
            onConfirm: async () => {
                await BackendServiceV2.delete('/salesteam/lead/' + lead.id);
                queryClient.invalidateQueries('sales-team-leads');
                queryClient.invalidateQueries('sales-team-calendar');
            }
    });

    const cancelLeadModal = () => modals.openConfirmModal({
        title: 'Cancelar chamada',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja cancelar essa chamada?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            await BackendServiceV2.put('/salesteam/lead/' + lead.id, {status: 'CANCELED'});
            queryClient.invalidateQueries('sales-team-leads');
        }
    });

    //if(!userData)
    //    return <></>

    return (
        <GridEntry 
            title={lead.name}
            right={<>
                <Badge variant="outline">{processStatusText(lead.status)}</Badge>
                {resourcePermission.containsGroup(['ADMIN']) && 
                    <Menu>
                        <Menu.Target>
                            <ActionIcon><Dots /></ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                            <Menu.Item icon={<Trash />} onClick={deleteLeadModal}>
                                Excluir
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                }
            </>}
            actions={<>
                <Stack spacing={5}>
                    <CopyTextInput
                        label="URL"
                        value={window.ENV.BaseURL + '/tour/connect/' + lead.connect.id}
                    />

                    {userData &&
                        <Select
                            label="Atendente"
                            size="md"
                            placeholder=""
                            itemComponent={SelectItem}
                            data={userData && userData.result.map(user => {
                                return {
                                    image: user?.user?.profilePicture?.webp,
                                    label: user?.user?.name,
                                    email: user?.user?.email,
                                    value: user?.user?.id
                                }
                            })}
                            searchable
                            maxDropdownHeight={400}
                            nothingFound="Nenhum usuário encontrado"
                            clearable
                            value={lead.assignedUser}
                            onChange={value => value ? assignUser.mutate(value) : unassignUser.mutate()}
                        />
                    }

                    {lead.status === 'OPEN' && 
                        <Group noWrap>
                            <Button variant="outline" fullWidth onClick={ e => {
                                setFinishOnClose(true);
                                editModalHandler.open();
                            }}>Encerrar</Button>

                            <Button fullWidth component="a" href={'/tour/connect/' + lead.connect.id} target="_blank" rel="noopener noreferrer">Entrar</Button>
                        </Group>
                    }

                    {lead.status === 'SCHEDULED' && 
                        <Group noWrap>

                            {new Date().getTime() >= new Date(lead.connect.createdAtDate).getTime() ?
                                <Button variant="outline" fullWidth onClick={ e => {
                                    setFinishOnClose(true);
                                    editModalHandler.open();
                                }}>Encerrar</Button>
                            :
                                <Button 
                                    variant="outline" 
                                    fullWidth 
                                    onClick={ e => {
                                        cancelLeadModal();
                                    }}
                                >Cancelar</Button>
                            }

                            {new Date().getTime() >= dayjs(new Date(lead.connect.createdAtDate)).subtract(1, 'hour').toDate().getTime() &&
                                <Button 
                                    fullWidth 
                                    component="a" 
                                    href={'/tour/connect/' + lead.connect.id} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >Entrar</Button>
                            }
                            
                        </Group>
                    }

                    <Button variant="subtle" onClick={e => {editModalHandler.open();}}>
                        Editar Lead
                    </Button>
                </Stack>
            </>}
        >
            <SalesTeamLeadEdit 
                lead={lead} 
                opened={editModal} 
                openedHandler={editModalHandler} 
                finishOnClose={finishOnClose} 
                setFinishOnClose={setFinishOnClose}
            />

            {lead.email && <>
                <Text weight={600}>Email</Text>
                <Text>{lead.email}</Text>
            </>}
        
            {lead.phone && <>
                <Text weight={600}>Telefone</Text>
                <Text>{valueOf(lead.phone)}</Text>
            </>}

            {lead?.connect?.createdAt && <>
                <Text weight={600}>{new Date().getTime() > new Date(lead.connect.createdAtDate).getTime() ? 'Iniciada' : 'Agendada'}</Text>
                <Text>{lead?.connect?.createdAt}</Text>
            </>}

            {lead?.connect?.closedAt && <>
                <Text weight={600}>Encerrada</Text>
                <Text>{lead?.connect?.closedAt}</Text>
            </>}

            {lead.leadQualification && <>
                <Text weight={600}>Qualificação</Text>
                <Text>{processQualificationText(lead.leadQualification)}</Text>
                </>}

            {lead.observations && <>
                <Text weight={600}>Observações</Text>
                <Text>{lead.observations}</Text>
            </>}
        </GridEntry>
    )
}