import React, { useContext } from "react";
import { ActionIcon, Menu } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Plus, SquarePlus } from "tabler-icons-react";
import { CarouselEntryFromSkyboxModal } from "./CarouselEntryFromSkyboxModal";
import { CarouselEntryFromTeleportModal } from "./CarouselEntryFromTeleportModal";
import { TeleportCarouselContext } from "../Context/TeleportCarouselContext";
import { showNotification } from "@mantine/notifications";

export function TeleportCarouselAddEntryButton({group}) {

    const [newFromSkyboxOpened, newFromSkyboxHandle] = useDisclosure(false);
    const [newFromTeleportOpened, newFromTeleportHandle] = useDisclosure(false);

    // IFRAME CONTEXT
    const carouselContext = useContext(TeleportCarouselContext);

    function openNewFromSkybox() {
        if(carouselContext.cameraState === 'FirstPerson') {
            newFromSkyboxHandle.open()
        } else {
            showNotification({
                title: 'A câmera precisa estar em um ponto de vista',
                message: '',
                autoClose: 2000,
            });
        }
    }

    return (<>
        <Menu>
            <Menu.Target>
                <ActionIcon color="#ff5e00" size={30} style={{alignSelf: 'center'}}><SquarePlus size={30} /></ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item icon={<Plus size={14} />} onClick={openNewFromSkybox}>Nova entrada do ponto de vista atual</Menu.Item>
                <Menu.Item icon={<Plus size={14} />} onClick={newFromTeleportHandle.open}>Nova entrada para outro Teleport</Menu.Item>
            </Menu.Dropdown>
        </Menu>

        <CarouselEntryFromSkyboxModal opened={newFromSkyboxOpened} handle={newFromSkyboxHandle} idCarouselGroup={group?.id} />
        <CarouselEntryFromTeleportModal opened={newFromTeleportOpened} handle={newFromTeleportHandle} idCarouselGroup={group?.id} />
    </>)
}