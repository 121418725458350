import { Badge, Button, Card, Group, Stack, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";

export function RenderFarmCard({vm, canChangeState = true}) {

    const modals = useModals();
    const queryClient = useQueryClient();

    const startVm = useMutation(() => {
        return BackendServiceV2.post('/renderfarm/start', {
            id: vm.providerId,
            providerName: vm.providerName
        })
    }, {
        onSuccess: () => {            
            showNotification({
                title: 'VM iniciando. Isso pode levar até 5 minutos.',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
            queryClient.invalidateQueries('renderfarm-virtualmachines-list-data');
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const stopVm = useMutation(() => {
        return BackendServiceV2.post('/renderfarm/stop', {
            id: vm.providerId,
            providerName: vm.providerName
        })
    }, {
        onSuccess: () => {            
            showNotification({
                title: 'VM desligando. Isso pode levar até 5 minutos.',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
            queryClient.invalidateQueries('renderfarm-virtualmachines-list-data');
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const vmStartConfirmation = () => modals.openConfirmModal({
        title: 'Iniciar Virtual Machine',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja ligar <b>{vm.name}</b>?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: () => {
            startVm.mutate();
        },
        zIndex: 1000
    });

    const vmStopConfirmation = () => modals.openConfirmModal({
        title: 'Iniciar Virtual Machine',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja desligar <b>{vm.name}</b>?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: () => {
            stopVm.mutate();
        },
        zIndex: 1000
    });

    return (
        <Card withBorder>
            <Stack spacing={5}>
                <Group position="apart" spacing={0} grow>
                    <Stack spacing={0}>
                        <Group position="apart">
                            <Text fw={500}>{vm.name}</Text>
                            <Badge styles={{inner: {textTransform: 'capitalize !important'}}} >{vm.state.replace('PowerState/', '')}</Badge>
                        </Group>
                        
                        <Text color="dimmed" size="xs">{vm.size} - {vm.providerName}</Text>
                        <Text color="dimmed" size="xs">{vm.publicIpAddress}</Text>
                    </Stack>
                </Group>

                {canChangeState && <>
                    {vm.state.includes('Running') && 
                        <Button onClick={vmStopConfirmation} color="red">Desligar</Button>
                    }

                    {vm.state.includes('Stopped') &&
                        <Button onClick={vmStartConfirmation} color="green">Ligar</Button>
                    }
                </>}
            </Stack>
        </Card>
    )
}