import React, { useState } from "react";
import { Center, Pagination, Space } from "@mantine/core";
import { SectionBox } from "components/v2/SectionBox";
import { SmartHome } from "tabler-icons-react";
import { Grid } from "components/v2/Grid";
import { useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { SalesTeamEventCreate } from "../SalesTeamEventCreate";
import { SalesTeamEventCard } from "../SalesTeamEventCard";

export function SalesTeamEventList({salesTeam}) {

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // EVENTS
    const [currentPage, setCurrentPage] = useState(1);
    //const [statusFilter, setStatusFilter] = useState('OPEN');

    const {data: events} = useQuery(['sales-team-events', currentPage], () => { //, statusFilter], () => {
        return BackendServiceV2.get('/salesteam/' + salesTeam.id + '/event/?page=' + (currentPage - 1)); // + '&status=' + statusFilter);
    });
    
    return (
        <SectionBox
            title="Eventos"
            icon={<SmartHome />}
            fullWidth
            right={<SalesTeamEventCreate salesTeam={salesTeam} />}
        >
            {events && events.result.length === 0 && <Center>Nenhum evento encontrado</Center>}

            <Grid>
                {events && events.result.map(event => (
                    <SalesTeamEventCard key={event.id} event={event} salesTeam={salesTeam} />
                ))}
            </Grid>

            {events && events.result.length > 0 && <>
                <Space h="lg" />
                <Pagination 
                    value={events.pageNumber + 1} 
                    onChange={(page) => {setCurrentPage(page); queryClient.invalidateQueries('sales-team-events')}} 
                    total={events.totalPages} 
                />
            </>}
        </SectionBox>
    )
}