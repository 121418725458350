import React, { useEffect, useState } from 'react';
import UserService from '../../../services/UserService';
import { Home } from 'tabler-icons-react';
import { ContentWrapper, DashboardContent, LeftContent } from 'components/v2/Dashboard/Layout';
import { SectionBox } from 'components/v2/SectionBox';
import { Text } from '@mantine/core';
import BackendServiceV2 from 'services/v2/BackendService';
import { useQuery } from 'react-query';
import { TeleportCard } from 'components/TeleportCard';
import { RenderFarmList } from 'components/v2/RenderFarm/RenderFarmList';
import { RenderServiceList } from 'components/v2/RenderService/RenderServiceList';
import './style.css';

function HomePage() {

    // PERMISSIONS
    const [ADMIN_ROLE, setAdminRole] = useState(false);

    useEffect(() => {
        async function getPermissions() {
            setAdminRole(await UserService.hasRole('ADMIN'));
        }

        getPermissions();
    }, []);

    const {data: inactiveTeleports} = useQuery('inactive-teleport-data', () => {
        return BackendServiceV2.get('/teleport/inactive');
    });

    return (
        <DashboardContent 
            title="Home"
            icon={<Home size={30} />}
        >
            <ContentWrapper>
                <LeftContent>
                    {ADMIN_ROLE && <>     
                        <RenderFarmList />

                        <RenderServiceList />
                    </>}

                    {ADMIN_ROLE &&
                        <SectionBox title="Teleports Inativos">
                            {inactiveTeleports?.map(teleport => (
                                <TeleportCard key={teleport.id} teleport={teleport} />
                            ))}

                            {inactiveTeleports?.length === 0 &&
                                <Text color="dimmed" align="center" size="sm">Nenhum Teleport inativo</Text>
                            }
                        </SectionBox>
                    }

                    <SectionBox
                        title="Ajuda"
                    >
                        <a 
                            href="https://whimsical.com/dashboard-9UECuyghjcEAn3MXWeomQr"
                            style={{textDecoration: 'underline', color: '#f05f22'}}
                        >Aprenda a utilizar o sistema</a>
                    </SectionBox>
                </LeftContent>
            </ContentWrapper>
        </DashboardContent>
    );
}

export default HomePage;