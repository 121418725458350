import React, { useCallback, useEffect, useRef } from 'react';
import SectionBox from '../SectionBox';

function Modal(props) {

    const modalRef = useRef(null);

    useEffect(() => {
        if(props.openModal !== undefined || props.openModal !== false) {
            modalRef.current.style.display = "flex";
            document.body.style.overflowY = "hidden";
        }

        if(props.openModal === undefined || props.openModal === false) {
            closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.openModal]);

    const closeModal = useCallback(() => {
        if(modalRef !== null) {
            modalRef.current.style.display = "none";
            document.body.style.overflowY = "auto";
            props.setOpenModal(undefined);
        }
    }, [props]);

    useEffect(() => {
        if(modalRef.current !== null)
            modalRef.current.addEventListener('click', (e) => {
                if(e.target === modalRef.current)
                    closeModal();
            });
    }, [closeModal]);

    return (
        <div className="modal" ref={modalRef}>
            <div className="modal-content">
                <SectionBox 
                    title={props.title}
                    description=""
                    right={(
                        <button className="secundary" onClick={closeModal}>X</button>
                    )}
                >
                    {props.children}
                </SectionBox>
                  
            </div>
        </div>
    );
}

export default Modal;