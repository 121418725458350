import { ConnectCreate } from "components/v2/Connect/ConnectCreate";
import { ContentWrapper, DashboardContent, LeftContent } from "components/v2/Dashboard/Layout";
import { SectionBox } from "components/v2/SectionBox";
import React from "react";
import { PhoneCall } from "tabler-icons-react";

export function Connect() {
    return (
        <DashboardContent title="Connect" icon={<PhoneCall size={30} />}>
            <ContentWrapper>
                <LeftContent>
                    <SectionBox 
                        title="Nova chamada instantânea" 
                        icon={<PhoneCall />}
                    >
                        <ConnectCreate />
                    </SectionBox>
                </LeftContent>
            </ContentWrapper>
        </DashboardContent>
    )
}