import { Button, Group,  Modal, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React from "react";
import { License, Lock, Plus} from "tabler-icons-react";
import { SectionBox } from "../SectionBox";
import { UserGroupAddForm } from "./UserGroupAddForm";
import { UserGroupList } from "./UserGroupList";

export function PermissionMenu({resourceClass, resourceId, invalidateQueryName = undefined}) {

    // CONTROL MODAL OPENING
    const [opened, openedHandler] = useDisclosure(false);

    return (<>
        <Button color="#ff5e00" variant="subtle" onClick={openedHandler.open} leftIcon={<Lock />} title="Permissões">Permissões</Button>

        <Modal
            opened={opened}
            onClose={openedHandler.close}
            title={<Group><Lock /><Title order={2}>Gerenciar Permissões</Title></Group>}
            centered
            size="lg"
        >
            <SectionBox title="Adicionar nova permissão" icon={<Plus />}>
                <UserGroupAddForm resourceClass={resourceClass} resourceId={resourceId} invalidateQueryName={invalidateQueryName} />
            </SectionBox>

            <SectionBox title="Permissões adicionadas" icon={<License />}>
                <UserGroupList resourceClass={resourceClass} resourceId={resourceId} invalidateQueryName={invalidateQueryName} />
            </SectionBox>
        </Modal>
    </>)
}