import { Badge, Loader, LoadingOverlay, Stack, Switch, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import React, { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { InfoCircle, X } from "tabler-icons-react";

export function SalesTeamEdit({salesTeam}) {

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // FORM
    const form = useForm({
        initialValues: {
          name: salesTeam.name,
          avatarEnabled: salesTeam.avatarEnabled,
        },
    
        validate: {
            name: (value) => (value.length === 0 ? 'O nome não pode ser vazio' : null)
        },
    });

    // REAL TIME FORM
    const [debouncedValues] = useDebouncedValue(form.values, 1000);
    const [isModified, setIsModified] = useState(false);

    useEffect(() => {
        if(!form.validate().hasErrors && !objectEquals(debouncedValues, salesTeam, ['name', 'avatarEnabled'])) {
            updateSalesTeam.mutate(debouncedValues);
        }
    // eslint-disable-next-line
    }, [debouncedValues]);

    useEffect(() => {
        if(!objectEquals(form.values, salesTeam, ['name']))
            setIsModified(true);
    // eslint-disable-next-line
    }, [form.values]);

    function objectEquals(object1, object2, values = []) {
        for (let i = 0; i < values.length; i++) {
            if (object1[values[i]] !== object2[values[i]])
                return false;
        }

        return true;
    }

    const updateSalesTeam = useMutation((values) => {
        return BackendServiceV2.put('/salesteam/' + salesTeam.id, values);
    }, {
        onSuccess: success => {
            setIsModified(false);
            queryClient.invalidateQueries('sale-team');
        },
        onError: error => {
            showNotification({
                color: 'red',
                title: 'Um erro ocorreu',
                autoClose: 5000,
                message: error?.response?.data?.message,
                icon: <X />
            });
        }
    })

    return (
        <SectionBox 
            title="Informações" 
            icon={<InfoCircle />}
            right={
                updateSalesTeam.isLoading ? <Loader variant="dots" /> : isModified ? <Badge variant="light">Mudanças não salva</Badge> : ''
            }
            fullWidth
        >
            <LoadingOverlay visible={updateSalesTeam.isLoading} />

            <form onSubmit={form.onSubmit(values => {updateSalesTeam.mutate(values)})}>
                <Stack>
                    <TextInput
                        required
                        label="Nome"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('name')}
                        maxLength={250}
                    />

                    <Switch
                        label="Usar avatares durante as chamadas"
                        size="md"
                        checked={form.getInputProps('avatarEnabled').value}
                        {...form.getInputProps('avatarEnabled')}
                    />
                </Stack>
            </form>
        </SectionBox>
    )
}