// eslint-disable-next-line
export default {

    hasAuthority(authorityNeed) {
        return new Promise(function(resolve, reject) {
            let me = JSON.parse(localStorage.getItem('me'));

            me.roles.forEach(role => {
                role.authorities.forEach(authority => {
                    if(authorityNeed === authority.name)
                        resolve(true);
                })
            });

            resolve(false);
        });
    },

    hasRole(roleNeed) {
        return new Promise(function(resolve, reject) {
            let me = JSON.parse(localStorage.getItem('me'));

            me.roles.forEach(role => {
                if(role.name === roleNeed)
                    resolve(true);
            });

            resolve(false);
        });
    }

}