import { Carousel } from "@mantine/carousel";
import { Button, Card, Group, Image, Modal, Stack, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import React, { useContext, useEffect } from "react";
import { HandClick } from "tabler-icons-react";

export function ExpressAmbientPreview({ambient, isEditVisible = false, setProjectAmbientEditVisibility = undefined, onAmbientSelect = undefined, cardHeader = undefined, cardFooter = undefined, title = undefined}) {
    
    const resourcePermission = useContext(ResourcePermissionContext);

    const [isAmbientPreviewOpen, setAmbientPreviewVisibility] = useDisclosure(false);

    useEffect(() => {
        if(isEditVisible)
            setAmbientPreviewVisibility.close();
    // eslint-disable-next-line
    }, [isEditVisible]);
    
    return (<>
        <Modal
            opened={isAmbientPreviewOpen}
            onClose={setAmbientPreviewVisibility.close}
            title={
                <Group>
                    {title ? title : <Title order={2}>{ambient?.code}</Title>}
                    {onAmbientSelect && resourcePermission.containsPermission('EXPRESS_PROJECT_UPDATE') && 
                        <Button onClick={() => {onAmbientSelect.mutate(ambient)}}>Escolher ambiente</Button>
                    }
                </Group>
            }
            centered
            closeOnClickOutside={false}
            withOverlay={true}
            size="auto"
        >
            <Carousel withIndicators loop maw={700}>
                {ambient?.images.map(image => (
                    <Carousel.Slide key={image.webp}>
                        <Image src={window.ENV.StaticURL + image.webp} fit="contain" />
                    </Carousel.Slide>
                ))}
            </Carousel>
        </Modal>

        <Card 
            withBorder
            p={0}
            style={{
                position: 'relative',
                cursor: 'pointer',
                height: '250px',
                aspectRatio: '1/1'
            }}
        >
            <Group
                style={{
                    position: 'absolute',
                    top: '10px',
                    left: '10px',
                    zIndex: 10
                }}
            >
                {cardHeader}
            </Group>

            {ambient ?
                <>
                    <Image src={window.ENV.StaticURL + ambient?.images[0].webp} height={250} onClick={setAmbientPreviewVisibility.open} />
                </>
            :
                <>
                    {resourcePermission.containsPermission('EXPRESS_PROJECT_UPDATE') ?
                        <Stack align="center" spacing={0} style={{height: '90%', justifyContent: 'center'}} onClick={setProjectAmbientEditVisibility?.open}>
                            <HandClick size={32} color="#979ea4" strokeWidth={1} />
                            <Text size="xs" color="dimmed">Clique para escolher o ambiente</Text>
                        </Stack>
                    :
                        <Stack align="center" spacing={0} style={{height: '90%', justifyContent: 'center'}}>
                            <Text size="xs" color="dimmed">Nenhum ambiente selecionado</Text>
                        </Stack>
                    }
                </>
            }

            <Group
                style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    background: ambient ? 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)' : '',
                    padding: '10px',
                    paddingTop: '10px',
                    zIndex: 10
                }}
                grow
                noWrap
            >
                {cardFooter}
            </Group>
        </Card>
    </>)
}