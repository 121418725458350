import { ActionIcon, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Trash, X } from "tabler-icons-react";

export function TeleportRenderDeleteButton({render}) {

    const resourcePermission = useContext(ResourcePermissionContext);
    
    const queryClient = useQueryClient();

    const modals = useModals();

    const {mutate: deleteRender} = useMutation(() => {
        return BackendServiceV2.delete('/render/' + render.id)
    }, {
        onSuccess: () => {       
            queryClient.invalidateQueries('teleport-render-list');
            showNotification({
                title: 'Render deletado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const deleteRenderConfirmation = () => modals.openConfirmModal({
        title: 'Deletar Render',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja deletar o render <b>{render.id}</b>?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            deleteRender();
        }
    });

    if(!resourcePermission.containsGroup(['ADMIN']))
        return <></>

    return (
        <ActionIcon variant="transparent" onClick={deleteRenderConfirmation}><Trash color="#ff5e00" /></ActionIcon>
    )
}