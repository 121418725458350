import React, { useEffect, useState } from 'react';
import { ContentWrapper, DashboardContent, LeftContent, RightContent } from 'components/v2/Dashboard/Layout';
import { SalesTeamCreate } from 'components/v2/SalesTeam/SalesTeamCreate';
import { SalesTeamList } from 'components/v2/SalesTeam/SalesTeamList';
import { SectionBox } from 'components/v2/SectionBox';
import { PhoneCall, Plus, Search } from 'tabler-icons-react';
import UserService from 'services/UserService';

export function SalesTeamListPage() {

    // PERMISSIONS
    const [ADMIN_ROLE, setAdminRole] = useState(false);

    useEffect(() => {
        async function getPermissions() {
            setAdminRole(await UserService.hasRole('ADMIN'));
        }

        getPermissions();
    }, []);

    return (
        <DashboardContent title="Connect" icon={<PhoneCall size={30} />}>
            <ContentWrapper>
                <LeftContent>
                    <SectionBox 
                        title="Procurar times" 
                        icon={<Search />}
                    >
                        <SalesTeamList />
                    </SectionBox>
                </LeftContent>

                {ADMIN_ROLE && <RightContent>
                    <SectionBox title="Criar novo time" icon={<Plus />}>
                        <SalesTeamCreate />
                    </SectionBox>
                </RightContent>}
            </ContentWrapper>
        </DashboardContent>
    );
}