import React, { useContext, useEffect } from 'react';
import AuthService from '../../services/AuthService';
import { UserContext } from '../../context/User/UserContext';
import { useNavigate } from 'react-router-dom';

function Code() {

    const navigate = useNavigate();

    const userContext = useContext(UserContext);

    useEffect(() => {
        async function configureLogin() {
            let parameters = window.location.hash.split('&');
            
            if(parameters.length > 1) {
                let accessToken = parameters[0].split('=')[1];
                let expireIn = parameters[2].split('=')[1];

                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('expireIn', Date.now() + parseInt(expireIn * 1000));
                
                let response = await AuthService.get('/account/me');

                localStorage.setItem('me', JSON.stringify(response.data));
                userContext.setLoggedUserData(response.data);

                let redirectTo = localStorage.getItem("redirectTo");

                if(redirectTo && redirectTo !== '') {
                    console.log("REDIRECT TO " + redirectTo);
                    localStorage.removeItem("redirectTo");

                    if(redirectTo.includes('tour')) {
                        window.location.replace(redirectTo);
                    } else {
                        navigate(redirectTo);
                    }
                } else {
                    let isUser = false;

                    response?.data?.roles?.forEach(role => {
                        if(role.name === "USER")
                            isUser = true;
                    });

                    if(isUser) {
                        console.log("REDIRECT TO CONNECT");
                        navigate('/connect');
                    } else {
                        console.log("REDIRECT TO HOME");
                        navigate('/');
                    }
                    
                }
            } else {
                navigate('/');
            }
        }

        configureLogin();
    // eslint-disable-next-line
    }, [history]);

    return (<></>);
}

export default Code;