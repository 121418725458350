import { Button, Text } from "@mantine/core";
import React, { useContext } from "react";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Trash, X } from "tabler-icons-react";
import { PlayerContext } from "context/Player/PlayerContext";

export function TeleportPhotoDeleteAllButton({idTeleport}) {

    const queryClient = useQueryClient();

    const playerContext = useContext(PlayerContext);

    const modals = useModals();

    const deletePhoto = useMutation(() => {
        return BackendServiceV2.delete('/teleport/' + idTeleport + '/photos')
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-data');
            playerContext?.player?.reload();

            showNotification({
                title: 'Fotos deletadas com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const deleteAllPhotoConfirmation = () => modals.openConfirmModal({
        title: 'Deletar todas as fotos',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja deletar <b>TODAS</b> as foto?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            deletePhoto.mutate();
        }
    });

    return (
        <Button variant="subtle" onClick={deleteAllPhotoConfirmation} leftIcon={<Trash />}>Deletar todas</Button>
    )
}