import { Button, Group, Modal, Stack, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import UploadArea from "components/v2/UploadArea";
import React, { useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Plus, X } from "tabler-icons-react";

export function TeleportObjectCreateButton({teleport}) {

    const queryClient = useQueryClient();

    const [opened, openedHandler] = useDisclosure(false);

    const form = useForm({
        initialValues: {
            name: ''
        }
    });

    const uploadArea = useRef(null);

    const {mutate: createObject} = useMutation((data) => {
        uploadArea.current.validate();
        return BackendServiceV2.post('/teleport/object', {...data, idTeleport: teleport.id})
    }, {
        onSuccess: (response) => {
            uploadArea.current.upload({
                mutate: async (files, config) => {
                    let formData = new FormData();
                    formData.append('data', files[0]);

                    return BackendServiceV2.put('/teleport/object/' + response.id + '/file', formData, config)
                },
                success: () => {
                    queryClient.invalidateQueries('teleport-objects');
                    form.reset();
                    
                    showNotification({
                        title: 'Objecto criado com sucesso',
                        message: '',
                        icon: <Check />,
                        color: 'green',
                        autoClose: 5000,
                    });

                    openedHandler.close();
                },
                error: (error) => {
                    showNotification({
                        title: 'Um erro ocorreu',
                        message: error?.response?.data?.message,
                        icon: <X />,
                        color: 'red',
                        autoClose: 5000,
                    });
        
                    BackendServiceV2.delete('/teleport/object/' + response.id);
                }
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (<>
        <Button leftIcon={<Plus />} onClick={openedHandler.open}>Novo objeto</Button>

        <Modal
            opened={opened}
            onClose={openedHandler.close}
            title={
                <Group><Plus /><Title order={2}>Novo objecto</Title></Group>
            }
            centered
            size="lg"
        >
            <form onSubmit={form.onSubmit(values => createObject(values))}>
                <Stack style={{width: '100%'}}>
                    <TextInput
                        required
                        label="Nome"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('name')}
                    />

                    <UploadArea 
                        rejectMessage="Erro"
                        maxSize={1024 * 5}
                        multiple={false}
                        ref={uploadArea}
                    />

                    <Button type="submit">Salvar</Button>
                </Stack>
            </form>
        </Modal>
    </>);

}