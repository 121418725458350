import React from 'react';
import Error404Img from '../../../assets/error404.png'
import './style.css';

function Error404() {

    return (
        <div className="main-content full-height">
            <div className="fullpage-error">
                <img src={Error404Img} alt="" />
                <div>PAGE NOT FOUND</div>
            </div>
        </div>
    );
}

export default Error404;