import { Badge, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

export function ExpressCard({expressProject}) {
    return (
        <Link className="list-entry" to={"/express/" + expressProject.id}>
            <Stack spacing={0} style={{flex: 1}}>
                <Group position='apart' noWrap>
                    <Text size="sm" lineClamp={1} weight={600}>
                        <span className="list-name">{expressProject.name}</span>
                    </Text>
                    
                    <Group spacing={3} noWrap>
                        <Badge variant='outline' size='sm'>{expressProject?.id}</Badge>
                    </Group>
                </Group>
            </Stack>
        </Link>
    )
}