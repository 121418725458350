import { Menu, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Trash, X } from "tabler-icons-react";

export function TotemPageDeleteButton({page}) {

    const queryClient = useQueryClient();
    const modals = useModals();

    const deletePage = useMutation(() => {
        return BackendServiceV2.delete('/totem/page/' + page?.id)
    }, {
        onSuccess: () => {
            showNotification({
                title: 'Página removida com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
            
            setTimeout(() => {
                queryClient.invalidateQueries('totem-data');
            }, 300);
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const deleteTotemPageConfirmation = () => modals.openConfirmModal({
        title: 'Deletar Página',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja deletar a página <b>{page.name}</b>?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            deletePage.mutate();
        }
    });

    return (
        <Menu.Item icon={<Trash />} onClick={deleteTotemPageConfirmation}>
            Deletar
        </Menu.Item>
    )
}