import { Menu, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Trash, X } from "tabler-icons-react";

export function TeleportCarouselGroupsDeleteButton({group}) {

    const queryClient = useQueryClient();

    const modals = useModals();

    const deleteCarouselGroup = useMutation(() => {
        return BackendServiceV2.delete(`/carousel/group/${group.id}`)
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-data');

            showNotification({
                title: 'Grupo deletado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 2000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 2000,
            });
        }
    });

    const deleteCarouselGroupConfirmation = () => modals.openConfirmModal({
        title: 'Deletar grupo do carrossel',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja deletar o grupo {group.title} e todas as suas entradas?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            deleteCarouselGroup.mutate();
        }
    });

    return (
        <Menu.Item icon={<Trash />} onClick={deleteCarouselGroupConfirmation}>Deletar</Menu.Item>
    )
}