import { Anchor, Badge, Breadcrumbs, Group, Stack, Text } from "@mantine/core";
import 'dayjs/locale/pt-br';
import dayjs from "dayjs";
import React, { useEffect } from "react";
import BackendServiceV2 from "services/v2/BackendService";
import { useQuery } from "react-query";

import './styles.css';
import Scrollbars from "react-custom-scrollbars-2";

export function DashboardContent(props) {

    useEffect(() => {
        if(props?.title)
            document.title = 'Dashboard - ' + props.title;

        return () => {
            document.title = 'iTeleport Dashboard';
        }
    }, [props?.title]);

    const {data: breadcrumbs} = useQuery(['page-breadcrumbs', props.resourceId, props.resourceClass], () => {
        if(!props.resourceId || !props.resourceClass) return;

        return BackendServiceV2.get('/breadcrumbs', {
            params: {
                resourceId: props.resourceId,
                resourceClass: props.resourceClass
            }
        });
    });

    return (
        <div className="main-page">
            <Group position="apart" className="page-header">
                <Stack spacing={0}>
                    {breadcrumbs?.breadcrumbs &&
                        <Breadcrumbs separator=">">
                            {breadcrumbs?.breadcrumbs?.map((item, index) => (
                                <Anchor href={item.href} key={index} size="xs">
                                    {item.title}
                                </Anchor>
                            ))}
                        </Breadcrumbs>
                    }
                    <Group mt={0}>
                        <Group noWrap>{props.icon}<Text size={30} weight="bold">{props.title}</Text></Group>
                        {props.description}
                    </Group>
                </Stack>

                <Group position="right">
                    {props.lastModified &&
                        <Badge variant="outline" color="gray">Modificado por último: {
                            dayjs(new Date(props.lastModified)).locale('pt-br').format('D[/]MM[/]YYYY HH:mm A').toString()
                        }</Badge>
                    }
                    {props.right}
                </Group>
            </Group>

            {props.children}
        </div>
    )
}

export function ContentWrapper(props) {
    return (
        <div className="content-wrapper">
            {props.children}
        </div>
    )
}

export function LeftContent(props) {
    return (
        <div className="page-left">
            {props.children}
        </div>
    )
}

export function RightContent(props) {
    return (
        <div className="page-right">
            {props.children}
        </div>
    )
}

export function FixedHeightContentWrapper({padding = '45px', children}) {
    return (
        <div 
            style={{
                width: '100%', 
                height:"100%",
                display: 'flex',
                paddingTop: padding
            }}
        >
            {children}
        </div>
    )
}

export function FixedHeightLeftContent({title, left = undefined, right = undefined, children}) {
    return (
        <Stack style={{backgroundColor: '#FFF', width: '300px', height:"100%", padding: '10px'}}>
            <Group align="center" position="apart">
                {left}
                <Text weight={600} size="xl" lineClamp={1} style={{flex: 1}}><abbr title={title}>{title}</abbr></Text>
                {right}
            </Group>

            <Scrollbars>
                {children}
            </Scrollbars>
        </Stack>
    )
}

export function FixedHeightRightContent({title, badges = undefined, right = undefined, padding = '30px', children}) {
    return (
        <Scrollbars>
            <Stack style={{width: '100%', padding: padding}}>

                <Group position="apart">
                    <Group>
                        <Text weight={700} size={30}>{title}</Text>
                        {badges}
                    </Group>

                    <Group position="right">
                        {right}
                    </Group>
                </Group> 

                {children}           
            </Stack>
        </Scrollbars>
    )
}