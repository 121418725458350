import { Button, Group, LoadingOverlay, Menu, Modal, Stack, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Edit, Plus, X } from "tabler-icons-react";

export function TotemPageRenameButton({page}) {
    
    const [isRenamePageVisible, setRenamePageVisibility] = useDisclosure(false);
    const queryClient = useQueryClient();

    const form = useForm({
        initialValues: {
            name: page?.name || ''
        },
        validate: {
            name: (value) => (!value ? 'Nome não pode ser vazio' : null)
        }
    });

    const {mutate: updatePage, isLoading} = useMutation((data) => {
        return BackendServiceV2.put('/totem/page/' + page?.id, data)
    }, {
        onSuccess: (response) => {
            queryClient.invalidateQueries('totem-data');
            showNotification({
                title: 'Página renomeada com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
            setRenamePageVisibility.close();
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (<>
        <Modal
            opened={isRenamePageVisible}
            onClose={setRenamePageVisibility.close}
            title={
                <Group><Plus /><Title order={2}>Renomear página {page?.name}</Title></Group>
            }
        >
            <LoadingOverlay visible={isLoading} />

            <form onSubmit={form.onSubmit(values => updatePage(values))}>
                <Stack>
                    <TextInput
                        required
                        label="Nome"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('name')}
                    />

                    <Button type="submit">Salvar</Button>
                </Stack>
            </form>
        </Modal>

        <Menu.Item icon={<Edit />} onClick={setRenamePageVisibility.open}>
            Renomear
        </Menu.Item>
    </>)
}