import { Anchor, Breadcrumbs, Group, Skeleton, Stack } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import BackendService from 'services/BackendService';
import './style.css';

function PageTop({  title, description, resourceId, resourceClass, right, icon }) {
    
    // BREADCRUMBS
    const [breadcrumbs, setBreadcrumbs] = useState();

    async function getBreadcrumbsList(resourceId, resourceClass) {
        let response = await BackendService.get('/breadcrumbs', {
            params: {
                resourceId,
                resourceClass
            }
        });

        if(response.status === 200) 
            setBreadcrumbs(response.data.breadcrumbs);
    }

    useEffect(() => {
        if(!resourceId) {
            setBreadcrumbs([]);
            return;
        }
        getBreadcrumbsList(resourceId, resourceClass);
    // eslint-disable-next-line
    }, [resourceId]);

    return (
        <div className="page-top">
            <div className="left">
                <Stack spacing={5}>
                    <Breadcrumbs separator=">">{
                        breadcrumbs ?
                            breadcrumbs?.map((item, index) => (
                                <Anchor href={item.href} key={index} size="xs">
                                    {item.title}
                                </Anchor>
                            ))
                        :
                            <Skeleton height={14} radius="xl" />
                    }</Breadcrumbs>
                    <Group spacing={0}>{icon} <h1>{title}</h1><br /></Group>
                    <span className="description">{description}</span>
                </Stack>
            </div>

            <div className="right">
                {right}
            </div>
        </div>
    );
}

export default PageTop;