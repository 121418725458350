import { Accordion, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedValue } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { SectionBox } from 'components/v2/SectionBox';
import React, { useEffect, useRef } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import BackendServiceV2 from 'services/v2/BackendService';
import { Check, X } from 'tabler-icons-react';
import equal from'fast-deep-equal';
import { MIME_TYPES } from '@mantine/dropzone';
import UploadArea from 'components/v2/UploadArea';

export function TotemRegionActionEdit({region, pages, select}) {

    const queryClient = useQueryClient();

    const form = useForm({
        initialValues: {
            type: region?.action?.type,
            content: region?.action?.content
        }
    });
    const [debouncedForm] = useDebouncedValue(form.values, 1000)

    const uploadArea = useRef();
    function onDrop() {
        updateAction(debouncedForm);
    }  

    useEffect(() => {
        if(equal({
            type: region?.action?.type,
            content: region?.action?.content
        }, debouncedForm))
            return;
        
            updateAction(debouncedForm)
    // eslint-disable-next-line
    }, [debouncedForm])

    useEffect(() => {
        form.setFieldValue('type', region.action.type);
        form.setFieldValue('content', region.action.content);
    // eslint-disable-next-line
    }, [region]);

    const {mutate: updateAction} = useMutation((values) => {
        return BackendServiceV2.put('/totem/page/content/region/action/' + region.action.id, values)
    }, {
        onSuccess: (newRegion) => {
            if(uploadArea.current.hasFile()) {
                uploadIcon();
            } else {
                queryClient.invalidateQueries('totem-data');
                showNotification({
                    title: 'Região atualizada com sucesso',
                    message: '',
                    icon: <Check />,
                    color: 'green',
                    autoClose: 5000,
                });
            }
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    function uploadIcon() {
        uploadArea.current.upload({
            mutate: async (files, config) => {
                let formData = new FormData();
                formData.append('image', files[0]);

                return BackendServiceV2.put('/totem/page/content/region/' + region.id + '/icon', formData)
            },
            success: (response) => {
                uploadArea.current.reset();
                queryClient.invalidateQueries('totem-data');
                showNotification({
                    title: 'Região atualizada com sucesso',
                    message: '',
                    icon: <Check />,
                    color: 'green',
                    autoClose: 5000,
                });
                select(response);
            },
            error: (error) => {
                showNotification({
                    title: 'Um erro ocorreu',
                    message: error?.response?.data?.message,
                    icon: <X />,
                    color: 'red',
                    autoClose: 5000,
                });
            }
        });
    }

    const {mutate: deleteRegionIcon} = useMutation(() => {
        return BackendServiceV2.delete('/totem/page/content/region/' + region.id + '/icon')
    }, {
        onSuccess: (response) => {
            queryClient.invalidateQueries('totem-data');
            showNotification({
                title: 'Região atualizada com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
            region.icon = undefined;
            select(region);
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (
        <form onSubmit={form.onSubmit(values => updateAction(values))}>
            <SectionBox title="Região" style={{width: '100%', overflow: 'inherit'}} withBorder={false} padding='0'>
                <Accordion multiple defaultValue={['events']} variant="separated" chevronPosition="left">
                    <Accordion.Item value="events">
                        <Accordion.Control>Eventos</Accordion.Control>
                        <Accordion.Panel>
                            <Select 
                                required
                                label="Tipo"
                                data={[
                                    {value: 'CLICK', label: 'Click'}
                                ]}
                                {...form.getInputProps('type')}
                            />

                            {form.values['type'] === 'CLICK' &&
                                <Select
                                    required
                                    searchable
                                    label="Ir para página"
                                    data={pages?.map(page => {return {value: page.id, label: page.name}})}
                                    {...form.getInputProps('content')}
                                />
                            }
                        </Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="icon">
                        <Accordion.Control>Ícone</Accordion.Control>
                        <Accordion.Panel>
                            <UploadArea 
                                label='Ícone'
                                size="md"
                                currentPreview={region?.icon ? region?.icon : undefined}
                                deleteCurrent={deleteRegionIcon}
                                maxSize={1024 * 2}
                                accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
                                multiple={false}
                                ref={uploadArea}
                                onDrop={onDrop}
                            />
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </SectionBox>
        </form>
    );
}