import { ActionIcon, Card, Group, Image, Progress, Stack, Text } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { useHover } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useMutation } from "react-query";
import { FileUpload, Trash, X } from "tabler-icons-react";

const UploadArea = forwardRef(function UploadArea({
    label = undefined, 
    size = "md", 
    currentPreview = undefined, 
    deleteCurrent = undefined,
    maxSize = undefined, 
    accept = [], 
    multiple = false,
    topPreview = false,
    onDrop
}, ref) {

    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);

    const {mutate: uploadFile, isLoading} = useMutation((data) => {
        // eslint-disable-next-line
        if(files.length === 0) throw {response: {data: {message: 'Nenhum arquivo selecionado'}}};

        return data.mutate(files, {
            onUploadProgress: progressEvent => {
                setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            },
            onProgress: (progressEvent) => {
                setUploadProgress(Math.round((progressEvent.loadedBytes * 100) / files[0].size));
            },
            progress: (progress) => {                
                setUploadProgress(Math.round(progress * 100));
            }
        });
    }, {
        onSuccess: (response, data) => {
            data.success(response);

            setFiles([]);
            setUploadProgress(0);
        },
        onError: (response, data) => {
            data.error(response);
        }
    });

    useImperativeHandle(ref, () => {
        return {
            upload(data) {
                uploadFile(data)
            },
            validate() {
                // eslint-disable-next-line
                if(files.length === 0) throw {response: {data: {message: 'Nenhum arquivo selecionado'}}};
            },
            hasFile() {
                return files.length !== 0
            },
            reset() {
                setFiles([]);
            },
            files() {
                return files;
            }
        }
    // eslint-disable-next-line
    }, [files]);

    // DELETE HOVER
    const { hovered, ref: previewRef } = useHover();

    return (
        <Stack 
            spacing={0} 
            style={{
                width: '100%',
            }}
        >
            <Text weight={500} size={size} style={{fontFamily: "'Inter', Helvetica, Arial, sans-serif"}}>{label}</Text>
            
            <Group 
                noWrap
                style={{
                    gap: currentPreview ? '16px' : '0px',
                    flexDirection: topPreview ? 'column' : 'row'
                }}
            >
                <div ref={previewRef}>
                    {currentPreview &&
                        <Card>
                            <Card.Section>
                                {currentPreview.includes('.mp4') ?
                                    <video width="100%" controls>
                                        <source src={window.ENV.StaticURL + currentPreview} />
                                    </video>
                                :
                                    <Image
                                        width="100%"
                                        src={window.ENV.StaticURL + currentPreview}
                                        fit="contain"
                                    />
                                }
                                
                                {hovered && 
                                    <ActionIcon 
                                        variant="filled" 
                                        color="#ff5e00"
                                        title="Deletar" 
                                        onClick={deleteCurrent}
                                        style={{
                                            position: 'absolute', 
                                            top: '10px',
                                            right: '10px',
                                            display: 'flex'
                                        }}
                                    >
                                        <Trash color="white" />
                                    </ActionIcon>
                                }
                            </Card.Section>

                            
                        </Card>
                    }
                </div>

                <Dropzone
                    style={{width: '100%'}}
                    onDrop={files => {
                        setFiles(files);

                        if(onDrop)
                            onDrop();
                    }}
                    onReject={(files) => {
                        showNotification({
                            color: 'red',
                            title: files[0]?.errors[0]?.message,
                            message: '',
                            autoClose: 5000,
                            icon: <X />
                        });
                    }}
                    maxSize={maxSize ? maxSize * 1024  ** 2 : undefined}
                    accept={accept}
                    multiple={multiple}
                >
                    {isLoading ? 
                        <Progress value={uploadProgress} size="xl" label={(uploadProgress ? uploadProgress : 0) + "%"} />
                    :
                        <Group spacing="xs" position="center" style={{ minHeight: 60, pointerEvents: 'none' }} noWrap>
                            <Stack align="center">
                                <Group noWrap>
                                    <FileUpload size={20} />
                                    <Text size="sm" inline align="center">
                                        Solte o arquivo aqui ou clique para selecionar
                                    </Text>
                                </Group>

                                {files.map(file => (
                                    <Text key={file.name} size="sm" inline color="dimmed">{file.name}</Text>
                                ))}
                            </Stack>
                        </Group>
                    }
                </Dropzone>
            </Group>
        </Stack>
    )
});

export default UploadArea;