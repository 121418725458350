import { ActionIcon, Button, Checkbox, Group, Menu, Modal, NumberInput, Stack, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React, { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Edit, Plus, SquarePlus, X } from "tabler-icons-react";

export function TeleportCarouselAddAndEditGroupButton({idCarousel = undefined, group = undefined}) {

    const queryClient = useQueryClient();

    const [opened, openedHandler] = useDisclosure(false);

    const form = useForm({
        initialValues: {
            title: '',
            botTour: false,
            betweenWalkTime: 5
        }
    });

    useEffect(() => {
        if(group) {
            form.setFieldValue('title', group?.title || '');
            form.setFieldValue('botTour', group?.botTour || false);
            form.setFieldValue('betweenWalkTime', group?.betweenWalkTime || 5);
        }
    // eslint-disable-next-line
    }, [group]);

    const {mutate: createCarouselGroup} = useMutation((data) => {
        if(group) {
            return BackendServiceV2.put(`/carousel/group/${group.id}`, data);
        } else {
            return BackendServiceV2.post(`/carousel/${idCarousel}/group`, data);
        }
    }, {
        onSuccess: (response) => {
            form.reset();
            queryClient.invalidateQueries('teleport-data');
            openedHandler.close();

            showNotification({
                title: group ? 'Grupo atualizado com sucesso' : 'Grupo criado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 2000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 2000,
            });
        }
    });

    return (<>
        {group ? 
            <Menu.Item icon={<Edit />} onClick={openedHandler.open}>Editar</Menu.Item>
        :
            <ActionIcon color="#ff5e00" size={30} style={{alignSelf: 'center'}} onClick={openedHandler.open}><SquarePlus size={30} /></ActionIcon>
        }
        
        <Modal
            opened={opened}
            onClose={openedHandler.close}
            title={
                <Group>{group ? <Edit /> : <Plus />}<Title order={2}>{group ? 'Editando ' + group?.title : 'Novo grupo'}</Title></Group>
            }
            centered
            size="lg"
        >
            <form id="general-teleport-data-form" onSubmit={form.onSubmit(values => createCarouselGroup(values))}>
                <Stack style={{width: '100%'}}>
                    <TextInput
                        required
                        label="Título"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('title')}
                    />

                    {false && <NumberInput
                        label="Tempo de transição das skyboxes de caminho"
                        size="md"
                        description="Tempo em segundos"
                        {...form.getInputProps('betweenWalkTime')}
                    />}

                    {false && <Checkbox
                        label="Definir como apresentação do chatbot"
                        size="md"
                        checked={form.getInputProps('botTour').value}
                        {...form.getInputProps('botTour')}
                    />}

                    <Button type="submit">Salvar</Button>
                </Stack>
            </form>
        </Modal>
    </>)
}