import { Badge, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

export function DevelopmentCard({ development }) {

    return (
        <Link className="list-entry" to={"/development/" + development.idDevelopment}>
            <Stack spacing={0} style={{flex: 1}}>
                <Group position='apart' noWrap>
                    <Text size="sm" lineClamp={1} weight={600}>
                        <span className="list-name">{development.name}</span>
                    </Text>
                    
                    <Group spacing={3} noWrap>
                        <Badge variant='outline' size='sm'>{development?.idDevelopment}</Badge>
                    </Group>
                </Group>
                
                <Text size="xs" color="dimmed">
                    <span>{development?.site ? development?.site : 'Sem site'}</span>
                </Text>
            </Stack>
        </Link>
    )
}