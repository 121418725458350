import React from "react";
import { PlayerContext } from "./PlayerContext";

export function PlayerContextProvider({children, player}) {
    return (
        <PlayerContext.Provider 
            value={{
                player: player.current
            }}
        >
            {children}
        </PlayerContext.Provider>
    )
}