import { Button, Group, LoadingOverlay, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { X } from "tabler-icons-react";

export function SalesTeamCreate() {

    const form = useForm({
        initialValues: {
          name: ''
        },
    
        validate: {
          name: (value) => value.length <= 250 ? null : 'O nome não pode ser maior que 250 caracteres',
        },
    });

    const {mutate: createSalesTeam, isLoading} = useMutation((data) => {
        return BackendServiceV2.post('/salesteam', data)
    }, {
        onSuccess: (response) => {
            window.location.href = window.ENV.BaseURL + '/connect/' + response?.id;
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (
        <>
            <LoadingOverlay visible={isLoading} />
            <form onSubmit={form.onSubmit(values => createSalesTeam(values))}>
                <TextInput
                    required
                    label="Nome"
                    placeholder=""
                    size="lg"
                    {...form.getInputProps('name')}
                />

                <Group position="right" mt="md">
                    <Button type="submit">Salvar</Button>
                </Group>
            </form>
        </>
    )
}