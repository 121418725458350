import { LoadingOverlay, Pagination, Space } from "@mantine/core";
import { IconUserScreen } from "@tabler/icons-react";
import { SearchBox } from "components/v2/SearchBox";
import { SectionBox } from "components/v2/SectionBox";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { TotemCard } from "../TotemCard";

export function TotemList() {

    const queryClient = useQueryClient();

    // TELEPORT LIST
    const [currentPage, setCurrentPage] = useState(1);
    const [title, setTitle] = useState(undefined);

    useEffect(() => {
        setCurrentPage(0);
    }, [title]);

    const {data: totems, isLoading} = useQuery(['totem-list-data', currentPage], () => {
        return BackendServiceV2.get(`/totem/?page=${currentPage}`);
    });
    
    return (
        <SectionBox 
            title="Showcases" 
            icon={<IconUserScreen />}
        >
            <LoadingOverlay visible={isLoading} />

            {false && <SearchBox onSearch={setTitle} />}

            {totems?.result?.map(totem => (
                <TotemCard key={totem.id} totem={totem} />
            ))}

            {totems?.result?.length > 0 && <>
                <Space h="lg" />
                <Pagination 
                    value={totems?.pageNumber + 1} 
                    onChange={(page) => {setCurrentPage(page - 1); queryClient.invalidateQueries('totem-list-data')}} 
                    total={totems?.totalPages} 
                />
            </>}
        </SectionBox>
    )
}