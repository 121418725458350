import { LoadingOverlay, Pagination, Space } from "@mantine/core";
import { TeleportCard } from "components/TeleportCard";
import { SearchBox } from "components/v2/SearchBox";
import { SectionBox } from "components/v2/SectionBox";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Building } from "tabler-icons-react";

export function TeleportList({idDevelopment = undefined}) {

    const queryClient = useQueryClient();

    // TELEPORT LIST
    const [currentPage, setCurrentPage] = useState(1);
    const [title, setTitle] = useState(undefined);

    useEffect(() => {
        setCurrentPage(0);
    }, [title]);

    const {data: teleports, isLoading} = useQuery(['teleport-list-data', currentPage, title, idDevelopment], () => {
        let url = `/teleport/?page=${currentPage}`;
        
        if(idDevelopment) {
            url = `/development/${idDevelopment}/teleport?page=${currentPage}`
        }
        
        if(title) {
            url += `&title=${title}`
        }

        return BackendServiceV2.get(url);
    });
    
    return (
        <SectionBox 
            title="Teleports" 
            icon={<Building />}
        >
            <LoadingOverlay visible={isLoading} />

            <SearchBox onSearch={setTitle} />

            {teleports?.result?.map(teleport => (
                <TeleportCard key={teleport.id} teleport={teleport} />
            ))}

            {teleports?.result?.length > 0 && <>
                <Space h="lg" />
                <Pagination 
                    value={teleports?.pageNumber + 1} 
                    onChange={(page) => {setCurrentPage(page - 1); queryClient.invalidateQueries('teleport-list-data')}} 
                    total={teleports?.totalPages} 
                />
            </>}
        </SectionBox>
    )
}