import React, {useState, useEffect, useCallback} from 'react';
import SectionBox from '../../../components/SectionBox';
import MediaForm from '../../../components/MediaForm';
import MediaBox from '../../../components/MediaBox';
import Pagination from '../../../components/Pagination';
import UserService from '../../../services/UserService';
import PageTop from 'components/PageTop';
import { Photo } from 'tabler-icons-react';
import BackendServiceV2 from 'services/v2/BackendService';

function Media({ location, history }) {

    // PERMISSIONS
    const [MEDIA_READ, setMediaRead] = useState(false);
    const [MEDIA_CREATE, setMediaCreate] = useState(false);

    useEffect(() => {
        async function getPermissions() {            
            setMediaRead(await UserService.hasAuthority("MEDIA_READ"));
            setMediaCreate(await UserService.hasAuthority("MEDIA_CREATE"));
        }

        getPermissions();
    }, []);

    const [currentPage, setCurrentPage] = useState(undefined);
    const [totalPages, setTotalPages] = useState(undefined);
    const [medias, setMedias] = useState([]);

    const getAllMedia = useCallback(async(page) => {
        if(page !== undefined) {
            setCurrentPage(page);
            let response = await BackendServiceV2.get('/media?page=' + page);
            setMedias(response.data.result);
            setTotalPages(response.data.totalPages);
        }
    }, []);

    function notify() {
        getAllMedia(currentPage);
    }

    return (
        <div className="main-content">

            <PageTop title="Media" icon={<Photo />} />

            <div className="left-content">
                {MEDIA_READ &&
                <SectionBox title="All media">
                    <div className="grid">
                        {medias.map(media => (
                            <MediaBox key={media.id} media={media} notify={notify} />
                        ))}
                    </div>

                    <Pagination location={location} totalPages={totalPages} history={history} onPageChange={getAllMedia} pathname="/media" />
                </SectionBox>}
            </div>  

            <div className="right-content">
                <div>
                    {MEDIA_CREATE &&
                    <MediaForm notify={notify} />}
                </div>
            </div>           
        </div>
    );
}

export default Media;