import { ActionIcon, Badge, Button, Group, Modal, Stack, TextInput, Title } from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import UploadArea from "components/v2/UploadArea";
import React, { useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Edit, X } from "tabler-icons-react";

export function LayerSkyboxEditButton({skybox}) {

    const queryClient = useQueryClient();

    const [opened, openedHandler] = useDisclosure(false);

    const form = useForm({
        initialValues: {
            ambient: skybox?.ambient || ''
        }
    });

    // FORM SUBMIT
    const uploadArea = useRef(null);

    const {mutate: editSkybox} = useMutation((data) => {
        return BackendServiceV2.put('layer/skybox/' + skybox?.id, data);
    }, {
        onSuccess: (response) => {
            if(uploadArea.current.hasFile()) {
                uploadArea.current.upload({
                    mutate: async (files, config) => {
                        let formData = new FormData();
                        formData.append('data', files[0]);
    
                        return BackendServiceV2.put(`layer/skybox/${skybox?.id}/image`, formData, config)
                    },
                    success: () => {
                        queryClient.invalidateQueries('teleport-layer-data');

                        showNotification({
                            title: 'Skybox salva com sucesso',
                            message: '',
                            icon: <Check />,
                            color: 'green',
                            autoClose: 5000,
                        });
                    },
                    error: (error) => {
                        showNotification({
                            title: 'Um erro ocorreu',
                            message: error?.response?.data?.message,
                            icon: <X />,
                            color: 'red',
                            autoClose: 5000,
                        });
                    }
                });
            } else {
                queryClient.invalidateQueries('teleport-layer-data');

                showNotification({
                    title: 'Skybox atualizada com sucesso',
                    message: '',
                    icon: <Check />,
                    color: 'green',
                    autoClose: 5000,
                });
            }
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (<>
        <ActionIcon variant='transparent' onClick={openedHandler.open}><Edit color='white' /></ActionIcon>

        <Modal
            opened={opened}
            onClose={openedHandler.close}
            title={
                <Group><Edit /><Title order={2}>Editando {skybox?.originalName}</Title><Badge>{skybox?.id}</Badge></Group>
            }
            centered
            size="lg"
        >
            {skybox?.texture && 
                <div 
                    style={{
                        width: '100%', 
                        height: '400px', 
                        marginBottom: '10px'
                    }}
                >
                    <a-scene embedded vr-mode-ui="false" loading-screen="enabled: false">
                        <a-assets>
                            <img id="skybox" src={window.ENV.StaticURL + skybox?.texture?.webp?.high} alt="360 VR" crossOrigin="anonymous" />
                        </a-assets>

                        <a-sky src="#skybox"></a-sky>
                        <a-camera reverse-mouse-drag></a-camera>
                    </a-scene>
                </div>
            }

            <form onSubmit={form.onSubmit(values => editSkybox(values))}>
                <Stack style={{width: '100%'}}>
                    <TextInput
                        label="Ambiente"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('ambient')}
                    />

                <UploadArea 
                        rejectMessage="Erro"
                        maxSize={1024 * 5}
                        accept={[MIME_TYPES.jpeg, MIME_TYPES.png, 'image/tiff', 'image/tif']}
                        multiple={false}
                        ref={uploadArea}
                    />

                    <Button type="submit">Salvar</Button>
                </Stack>
            </form>
        </Modal>
    </>)
}