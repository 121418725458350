import { ActionIcon, Badge, Button, Group, Menu, Stack, Text, TextInput } from "@mantine/core";
import { useClipboard, useDisclosure } from "@mantine/hooks";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { GridEntry } from "components/v2/Grid";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import React, { useContext } from "react";
import { useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Copy, Dots, Trash } from "tabler-icons-react";
import { SalesTeamEventEdit } from "../SalesTeamEventEdit";

export function SalesTeamEventCard({event, salesTeam}) {

    // CLIPBOARD
    const clipboard = useClipboard({ timeout: 500 });

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    // QUERY CLIENT
    const queryClient = useQueryClient();

    function processStatusText(status) {
        switch (status) {
            case 'OPEN':
                return 'Aberto'
            case 'FINISHED':
                return 'Encerrado';
            default:
                return null;
        }
    }

    // UPDATE MODAL
    const [editModal, editModalHandler] = useDisclosure(false);

    // DELETE
    const modals = useModals();
    const deleteEventModal = () => modals.openConfirmModal({
            title: 'Deletar evento',
            centered: true,
            children: (
                <Text size="sm">
                    Você tem certeza que deseja deletar esse evento?
                </Text>
            ),
            labels: { confirm: 'Sim', cancel: 'Não' },
            onConfirm: async () => {
                await BackendServiceV2.delete('/salesteam/event/' + event.id);
                queryClient.invalidateQueries('sales-team-events');
            }
    });

    return (
        <GridEntry 
            title={event.id}
            right={<>
                <Badge variant="outline">{processStatusText(event.status)}</Badge>
                {resourcePermission.containsGroup(['ADMIN']) && 
                    <Menu>
                        <Menu.Target>
                            <ActionIcon><Dots /></ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                            <Menu.Item icon={<Trash />} onClick={deleteEventModal}>
                                Excluir
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                }
            </>}
            actions={<>
                <Stack spacing={5}>
                    <TextInput
                        label="URL"
                        readOnly
                        value={window.ENV.BaseURL + '/tour/connect/' + event.connect.id}
                        rightSection={
                            <ActionIcon 
                                onClick={e => {
                                    clipboard.copy(window.ENV.BaseURL + '/tour/connect/' + event.connect.id);
                                    showNotification({
                                        color: "green",
                                        title: 'URL copiada com sucesso',
                                        message: '',
                                        autoClose: 5000,
                                    });
                                }}
                            ><Copy /></ActionIcon>
                        }
                        onClick={e => {
                            clipboard.copy(window.ENV.BaseURL + '/tour/connect/' + event.connect.id);
                            showNotification({
                                color: "green",
                                title: 'URL copiada com sucesso',
                                message: '',
                                autoClose: 5000,
                            });
                        }}
                        styles={{
                            input: {
                                cursor: 'pointer',
                                userSelect: 'none',
                                backgroundColor: '#f6f7f8',
                                color: '#c3c4c6',
                                '&:focus': {
                                    border: '1px solid #ced4da'
                                }
                            }
                        }}
                    />

                    {event.status === 'OPEN' && 
                        <Group noWrap>
                            <Button fullWidth component="a" href={'/tour/connect/' + event.connect.id} target="_blank" rel="noopener noreferrer">Entrar</Button>
                        </Group>
                    }

                    <SalesTeamEventEdit 
                        event={event} 
                        salesTeam={salesTeam}
                        opened={editModal} 
                        openedHandler={editModalHandler}
                    />

                    <Button variant="subtle" onClick={e => {editModalHandler.open();}}>
                        Editar Evento
                    </Button>
                </Stack>
            </>}
        >
            {event.teleportName && <>
                <Text weight={600}>Teleport</Text>
                <Text>{event.teleportName}</Text>
            </>}
        </GridEntry>
    )
}