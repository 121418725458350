import { Badge, Button, Card, Group, Modal, Stack, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { FixedHeightContentWrapper, FixedHeightLeftContent, FixedHeightRightContent } from "components/v2/Dashboard/Layout";
import React, { useState } from "react";
import { useQuery } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { AdjustmentsHorizontal, Tags } from "tabler-icons-react";
import { TagsManagerAddCategory } from "./TagsManagerAddCategory";
import { TagsManagerCategoryAddTag } from "./TagsManagerCategoryAddTag";
import { TagsManagerDeleteCategory } from "./TagsManagerDeleteCategory";
import { TagsManagerEditCategory } from "./TagsManagerEditCategory";
import { TagsManagerTagCard } from "./TagsManagerTagCard";
import './style.css';

export function TagsManager({type}) {

    const [opened, openedHandler] = useDisclosure(false);

    const [activeCategory, setActiveCategory] = useState();

    const {data: categories} = useQuery('metadata-category-list-data', () => {                
        return BackendServiceV2.get('/resource/metadata/category?type=' + type);
    }, {
        onSuccess: (response) => {
            if(response.length > 0) {
                if(activeCategory) {
                    let categoryFind = response.find(category => category.id === activeCategory.id);

                    if(categoryFind) {
                        setActiveCategory(categoryFind);
                    } else {
                        setActiveCategory(response[0]);
                    }
                } else {
                    setActiveCategory(response[0]);
                }
            }
                
        }
    });

    return (<>
        <Button variant="subtle" leftIcon={<AdjustmentsHorizontal />} onClick={openedHandler.open}>Gerenciar</Button>

        <Modal
            className="custom-modal"
            opened={opened}
            onClose={openedHandler.close}
            title={
                <Group grow position="apart">
                    <Group><Tags /><Title order={2}>Gerenciar Tags</Title><Badge>{type}</Badge></Group>
                </Group>
            }
            centered
            fullScreen
            styles={theme => ({
                inner: {
                    backgroundColor: 'rgba(0, 0, 0, 0.7)'
                },
                content: {
                    width: '95vw !important',
                    height: '95vh !important',
                    borderRadius: theme.defaultRadius + ' !important',
                    margin: 'auto',
                    overflow: 'hidden',
                    flex: 'initial !important'
                },
                title: {
                    width: '100%'
                },
                body: {
                    height: '100%'
                }
            })}
        > 
            <FixedHeightContentWrapper padding="0">
                <FixedHeightLeftContent 
                    title="Categorias" 
                    right={
                        <TagsManagerAddCategory type={type}/>
                    }
                >
                    <Stack>
                        {categories?.map(category => (
                            <Card 
                                withBorder 
                                onClick={e => {setActiveCategory(category)}} 
                                style={{
                                    cursor: 'pointer'
                                }}
                            >
                                <Group position="apart">
                                    <Text>{category.name}</Text>
                                </Group>
                            </Card>
                        ))}
                    </Stack>
                </FixedHeightLeftContent>
                    
                {activeCategory &&
                    <FixedHeightRightContent 
                        title={activeCategory.name} 
                        right={<>
                            <TagsManagerEditCategory category={activeCategory} />
                            <TagsManagerDeleteCategory category={activeCategory} />
                            <TagsManagerCategoryAddTag idCategory={activeCategory.id} />
                        </>}
                        padding="0 30px 0 30px"
                    >
                        {activeCategory?.metadata?.length === 0 && 
                            <Text color="dimmed" size="sm" align="center">Nenhuma tag adicionada</Text>
                        }

                        <Stack>
                            {activeCategory?.metadata?.map((tag) => (
                                <TagsManagerTagCard tag={tag} key={tag.id} />
                            ))}
                        </Stack>
                    </FixedHeightRightContent>
                }
            </FixedHeightContentWrapper>
        </Modal>
    </>)
}