import { Badge, Group, Text } from "@mantine/core";
import React from "react";

export function TeleportSelectCard({value, label, description, reference = undefined, ...others}) {
    
    return (
        <div key={value} ref={reference} {...others.others} style={{padding: '5px'}}>
            <Group noWrap>      
              <div>
                <Group noWrap>
                    <Badge variant="outline">{value}</Badge>
                    <Text size="sm" lineClamp={1} weight={600}>{label}</Text></Group>
                <Text size="xs" color="dimmed">
                    {description  ? description : 'Sem descrição'}
                </Text>
              </div>
            </Group>
        </div>
    )
}