import { Badge, Card, Group, Text } from "@mantine/core";
import React from "react";
import { TeleportObjectDeleteButton } from "../TeleportObjectDeleteButton";

export function TeleportObjectCard({object}) {
    return (
        <Card key={object.id} withBorder>
            <Group position="apart">
                <Group>
                    <Badge>{object.id}</Badge>
                    <Text>{object.name}</Text>
                </Group>

                <Group>
                    <TeleportObjectDeleteButton object={object} />
                </Group>
            </Group>
        </Card>
    )
}