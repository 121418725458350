import React from 'react';
import './style.css';
import logo from '../../assets/logo.png';


function FullPageWidget(props) {
    return (
        <div className="page-wrapper">
            <div className="page">
                <div className="logo-wrapper"><img src={logo} alt="iTeleport"/></div>
                {props.children}
            </div>
        </div>
    );
}

export default FullPageWidget;