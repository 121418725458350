import { Button, Select, Stack } from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import UploadArea from "components/v2/UploadArea";
import React, { useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import FileService from "services/FileService";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";

export function TeleportRenderUpload({teleport}) {

    const queryClient = useQueryClient();

    const [isLoading, setIsLoading] = useState(false);

    const form = useForm({
        initialValues: {
            phase: 'SKYBOX_VALIDATION'
        }
    });

    const uploadArea = useRef(null);

    const {mutate: createRender} = useMutation((data) => {
        uploadArea.current.validate();
        setIsLoading(true);
        return BackendServiceV2.post('/teleport/' + teleport.id + '/render/process/input', data);
    }, {
        onSuccess: (response) => {
            uploadArea.current.upload({
                mutate: async (files, config) => {
                    return FileService.uploadFile(response?.url, files, config);
                },
                success: () => {
                    startProcessing(response);
                },
                error: (error) => {
                    setIsLoading(false);
                    showNotification({
                        title: 'Um erro ocorreu',
                        message: error?.response?.data?.message,
                        icon: <X />,
                        color: 'red',
                        autoClose: false
                    });
                }
            });
        },
        onError: (error) => {
            setIsLoading(false);
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: false
            });
        }
    });
    
    const {mutate: startProcessing} = useMutation((render) => {
        return BackendServiceV2.post('/render/' + render.id + '/queue')
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-render-list');
            form.reset();
            setIsLoading(false);
            
            showNotification({
                title: 'Renderização iniciada com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: false
            });
        },
        onError: (error) => {
            setIsLoading(false);
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: false
            });
        }
    })


    return (
        <SectionBox
            title="Novo Render"
        >
            <form onSubmit={form.onSubmit(values => createRender(values))}>
                <Stack>
                    {!isLoading &&
                        <Select
                            required
                            label="Etapa"
                            placeholder="Selecione um"
                            data={[
                                {value: 'SKYBOX_VALIDATION', label: 'Skybox - Validação'},
                                {value: 'SKYBOX_PRODUCTION', label: 'Skybox - Produção'},
                                {value: 'BAKE_PRODUCTION', label: 'Bake - Produção'},
                                {value: 'VIDEO_PRODUCTION', label: 'Video - Produção'},
                            ]}
                            {...form.getInputProps('phase', { type: 'select' })}
                        />
                    }

                    <UploadArea 
                        accept={[MIME_TYPES.zip, 'application/x-zip-compressed']}
                        multiple={false}
                        ref={uploadArea}
                    />

                    {!isLoading && <Button type="submit">Renderizar</Button>}
                </Stack>
            </form>
        </SectionBox>
    )
}