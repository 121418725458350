import React from 'react';
import Modal from '..';

function ConfirmationModal({openModal, setOpenModal, title, callback}) {

    return (
        <Modal openModal={openModal} title={title} setOpenModal={setOpenModal}>
            <div className="button-options">
                <button onClick={() => {setOpenModal(undefined)}}>No</button>
                <button className="secundary" onClick={async (e) => {callback(e); setOpenModal(undefined)}}>Yes</button>
            </div>
        </Modal>
    );
}

export default ConfirmationModal;