import { Button, Group, Modal, Stack, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { CopyTextInput } from "components/v2/Form/CopyTextInput";
import React from "react";
import { useQuery } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Share } from "tabler-icons-react";

export function TeleportShareButton({teleport}) {

    const [opened, openedHandler] = useDisclosure(false);

    const {data: publication} = useQuery('publication-data', () => {
        return BackendServiceV2.get(`/publication?type=TELEPORT&idResource=${teleport.id}`);
    });

    return (<>
        <Button leftIcon={<Share />} onClick={openedHandler.open}>Compartilhar</Button>

        <Modal
            opened={opened}
            onClose={openedHandler.close}
            title={<Group><Share /><Title order={2}>Compartilhar</Title></Group>}
            centered
            size="lg"
        >
            <Stack style={{width: '100%'}}>
                <CopyTextInput
                    label="Portal"
                    value={window.ENV.PortalURL + '/tour/' + publication?.slug}
                />

                <CopyTextInput
                    label="Código para embed"
                    value={'<iframe src="' + window.ENV.PortalURL + '/tour/' + publication?.slug + '/fullscreen" width="100%" height="480" frameborder="0" allowfullscreen="allowfullscreen" allow="fullscreen; camera; microphone; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay"></iframe>'}
                />

                <CopyTextInput
                    label="Link direto"
                    value={window.ENV.PlayerURL + teleport?.id}
                /> 
            </Stack>
        </Modal>
    </>)
}