import { ActionIcon, Button, Group, Modal, Pagination, Space, Stack, Text, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Link, Plus, Trash, X } from "tabler-icons-react";

export function SalesTeamFavoriteLinksList({salesTeam}) {

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // GET LINKS
    const [currentPage, setCurrentPage] = useState(1);

    const {data: links} = useQuery(['sales-team-links', currentPage], () => {
        let requestURL = `/salesteam/${salesTeam.id}/link?page=` + (currentPage - 1);
        return BackendServiceV2.get(requestURL);
    })

    // CONTROL MODAL OPENING
    const [opened, openedHandler] = useDisclosure(false);

    // CREATE NEW LINK
    const form = useForm({
        initialValues: {
            link: ''
        },
    
        validate: {},
    });

    const createNewLink = useMutation((values) => {
        return BackendServiceV2.post('/salesteam/' + salesTeam.id + '/link', values);
    }, {
        onSuccess: response => {
            showNotification({
                title: <>Link criado com sucesso</>,
                message: ``,
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })

            form.reset();
            openedHandler.close();
            queryClient.invalidateQueries('sales-team-links')
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    const deleteLink = useMutation((idFavoriteLink) => {
        return BackendServiceV2.delete('/salesteam/' + salesTeam.id + '/link/' + idFavoriteLink);
    }, {
        onSuccess: response => {
            showNotification({
                title: <>Link removido com sucesso</>,
                message: ``,
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })

            form.reset();
            queryClient.invalidateQueries('sales-team-links')
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    return (
        <SectionBox
            title="Links favoritos"
            fullWidth
            icon={<Link />}
            right={<Button variant="subtle" leftIcon={<Plus />} className="no-text" onClick={openedHandler.open}></Button>}
        >
            <Modal
                opened={opened}
                onClose={openedHandler.close}
                title={<Group><Link /><Title order={2}>Adicionar Links Favoritos</Title></Group>}
                centered
                size="lg"
            >
                <form onSubmit={form.onSubmit(values => createNewLink.mutate(values))}>
                    <TextInput
                        required
                        label="Link"
                        placeholder="https://..."
                        size="md"
                        {...form.getInputProps('link')}
                    />

                    <Group position="right" mt="md">
                        <Button type="submit">Adicionar</Button>
                    </Group>
                </form>
            </Modal>

            <Stack>
                {links && links.result.map(link => (
                    <Group 
                        key={link.id}
                        position="apart" 
                        style={{
                            border: '1px solid #EEE',
                            padding: '15px',
                            borderRadius: '10px'
                        }}
                    >
                        <Text component="a" href={link.link} target="_blank" style={{textDecoration: 'underline'}}>{link.link}</Text>
                        <ActionIcon title="Remover" onClick={e => {deleteLink.mutate(link.id)}}><Trash /></ActionIcon>
                    </Group>
                ))}
            </Stack>

            {links?.result?.length > 0 && <>
                <Space h="lg" />
                <Pagination 
                    value={links.pageNumber + 1} 
                    onChange={(page) => {setCurrentPage(page); queryClient.invalidateQueries('sales-team-links')}} 
                    total={links.totalPages} 
                />
            </>}
        </SectionBox>
    );
}