import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';
import { Button, Checkbox, Group, Modal, Select, SimpleGrid, Space, Text, TextInput, Title } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { At, Building, Check, Clock, Phone, PhoneCall, Plus, User, X } from "tabler-icons-react";
import { updateValue, valueOf } from 'hooks/usePhoneMask';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import BackendServiceV2 from 'services/v2/BackendService';
import { ResourcePermissionContext } from 'context/Permission/ResourcePermissionContext';
import { useDisclosure } from '@mantine/hooks';
import { TeleportSelectCard } from 'components/v2/Teleport/TeleportSelectCard';

export function SalesTeamLeadCreate({salesTeam}) {

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    // MODAL
    const [openCreateModal, openCreateModalHandler] = useDisclosure(false);

    // CREATE NEW LEAD
    const createNewLead = useMutation((values) => {
        return BackendServiceV2.post('/salesteam/lead', {...values});
    }, {
        onSuccess: response => {
            showNotification({
                title: <>Chamada criada</>,
                message: ``,
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })

            form.reset();
            setSchedule(undefined);
            setScheduleDate(undefined);
            openCreateModalHandler.close();
            queryClient.invalidateQueries('sales-team-leads')
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    // LEAD FORM DATA
    const form = useForm({
        initialValues: {
            name: '',
            phone : '',
            email: '',
            idTeleport: '',
            scheduleTo: undefined,
            assignedOnly: true
        },
    
        validate: {
            name: (value) => value.length <= 250 ? null : 'O nome não pode ser maior que 250 caracteres',
            scheduleTo: (value) => schedule ? (value === undefined ? 'A data não pode ser vazia' : null) : null
        },
    });

    // TELEPORT DATA
    const {data: linkedTeleports} = useQuery('sales-team-linked-teleports', () => {
        return BackendServiceV2.get('/salesteam/' + salesTeam.id + '/teleport/?size=100&page=0');
    })

    const SelectItem = forwardRef(
        ({ label, description, value, ...others }, ref) => (
            <TeleportSelectCard value={value} label={label} description={description} others={others} reference={ref} />
        )
    );

    // SCHEDULED
    const [schedule, setSchedule] = useState(false);
    const [scheduleDate, setScheduleDate] = useState(undefined);

    const dayTimeOptions = [
        {value: 9},
        {value: 10},
        {value: 11},
        {value: 12},
        {value: 13},
        {value: 14},
        {value: 15},
        {value: 16},
        {value: 17}
    ];

    useEffect(() => {
        if(!schedule) {
            form.getInputProps('scheduleTo').onChange(undefined);
            setScheduleDate(undefined)
        }
    // eslint-disable-next-line
    }, [schedule])
    
    return (<>
        <Button leftIcon={<Plus />} onClick={openCreateModalHandler.open}>Criar nova chamada</Button>

        <Modal
            opened={openCreateModal}
            onClose={openCreateModalHandler.close}
            title={<Group><PhoneCall /><Title order={2}>Criar nova chamada</Title></Group>}
            centered
            size="lg"
        >
            <form onSubmit={form.onSubmit(values => {createNewLead.mutate(values)})}>
                <TextInput
                    required
                    label="Nome"
                    placeholder=""
                    size="md"
                    icon={<User />}
                    {...form.getInputProps('name')}
                />

                <TextInput
                    label="Telefone"
                    placeholder=""
                    size="md"
                    value={valueOf(form.getInputProps('phone').value)}
                    onChange={e => {
                        e.preventDefault();
                        updateValue(e.target.value, form.getInputProps('phone').onChange);
                    }}
                    maxLength={15}
                    minLength={14}
                    icon={<Phone />}
                />

                <TextInput
                    label="Email"
                    placeholder=""
                    size="md"
                    {...form.getInputProps('email')}
                    icon={<At />}
                />

                {linkedTeleports?.result &&
                    <Select
                        required
                        label="Teleport"
                        placeholder=""
                        size="md"
                        itemComponent={SelectItem}
                        data={linkedTeleports?.result?.map(teleport => {
                            return {
                                label: teleport?.metadata?.title, 
                                description: teleport?.metadata?.subtitle, 
                                value: teleport?.id
                            }
                        })}
                        searchable
                        nothingFound="Nenhum Teleport encontrado"
                        {...form.getInputProps('idTeleport')}
                        icon={<Building />}
                    />
                }

                {resourcePermission.containsGroup(['ADMIN', 'COORDENADOR']) &&  <>
                    <Space h="md" />

                    <Checkbox
                        label="Me definir como atendente"
                        size="md"
                        checked={form.getInputProps('assignedOnly').value}
                        {...form.getInputProps('assignedOnly')}
                    />
                </>}

                <Space h="md" />

                <Checkbox
                    label="Agendar"
                    size="md"
                    checked={schedule} 
                    onChange={(event) => setSchedule(event.currentTarget.checked)}
                />

                {schedule && <SimpleGrid cols={2}>
                    <DatePicker  
                        value={scheduleDate} 
                        onChange={value => {
                            form.getInputProps('scheduleTo').onChange(undefined); setScheduleDate(value);
                        }} 
                        firstDayOfWeek={0}
                        hideOutsideDates={true}
                        minDate={dayjs(new Date()).add(0, 'days').toDate()}
                        locale="pt-br"
                        excludeDate={(date) => date.getDay() === 0} 
                    />

                    <div>
                        {scheduleDate &&
                            <Group grow>
                                <b>{dayjs(scheduleDate).locale('pt-br').format('dddd, D [de] MMMM')}</b>

                                <TimeInput 
                                    label=""
                                    size="md"
                                    icon={<Clock />}
                                    onChange={e => {
                                        let date = e.target.value.split(':');
                                        console.log(date);
                                        form.getInputProps('scheduleTo').onChange(dayjs(scheduleDate).add(date[0], 'hour').add(date[1], 'minute').toDate())
                                    }}
                                />

                                {false && <SimpleGrid cols={2}>
                                    {dayTimeOptions.map(dayTime => (
                                        <Button 
                                            key={dayTime.value} 
                                            onClick={e => {
                                                form.getInputProps('scheduleTo').onChange(dayjs(scheduleDate).add(dayTime.value, 'hour').toDate())
                                            }}
                                            variant={
                                                (dayjs(scheduleDate).add(dayTime.value, 'hour').toDate()?.getTime() === form?.getInputProps('scheduleTo')?.value?.getTime()) ? 'filled' : 'outline'
                                            }
                                        >
                                            {dayjs(scheduleDate).add(dayTime.value, 'hour').locale('pt-br').format('HH:mm')}
                                        </Button>
                                    ))}
                                </SimpleGrid>}
                            </Group>
                        }
                    </div>
                </SimpleGrid>}

                <Text size="md" color="#f03e3e">
                    {form?.errors?.scheduleTo}
                </Text>

                <Group position="right" mt="md">
                    <Button type="submit">Criar</Button>
                </Group>
            </form>
        </Modal>
    </>)    
}