import { Menu } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Home, X } from "tabler-icons-react";

export function TotemPageSetHome({page}) {

    const queryClient = useQueryClient();

    const setHome = useMutation(() => {
        return BackendServiceV2.put('/totem/page/' + page?.id, {home: true})
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('totem-data');
            showNotification({
                title: 'Home alterada com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    if(page.home)
        return <></>

    return (
        <Menu.Item icon={<Home />} onClick={setHome.mutate}>
            Definir como Home
        </Menu.Item>
    )
}