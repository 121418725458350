import { Button } from '@mantine/core';
import React from 'react';
import {  Check, SquarePlus, X } from 'tabler-icons-react';
import BackendServiceV2 from 'services/v2/BackendService';
import { useMutation, useQueryClient } from 'react-query';
import { showNotification } from '@mantine/notifications';

export function TotemRegionAddButton({ content, setSelectedRegion}) {

    const queryClient = useQueryClient();

    const {mutate: addRegion} = useMutation(() => {
        return BackendServiceV2.post('/totem/page/content/' + content.id + '/region', {})
    }, {
        onSuccess: (newRegion) => {
            queryClient.invalidateQueries('totem-data');
            showNotification({
                title: 'Região adicionada com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
            setSelectedRegion(newRegion);
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (
        <Button variant='subtle' leftIcon={<SquarePlus />} onClick={addRegion}>Nova Região</Button>
    );
}