import { Stack } from "@mantine/core";
import { SectionBox } from "components/v2/SectionBox";
import React from "react";
import { useQuery } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { TeleportObjectCard } from "./TeleportObjectCard";
import { TeleportObjectCreateButton } from "./TeleportObjectCreateButton";

export function TeleportObject({teleport}) {

    // GET VIDEO TEXTURES
    const {data: objects} = useQuery(['teleport-objects'], () => {
        return BackendServiceV2.get(`/teleport/${teleport.id}/object`);
    })

    return (<>
        <SectionBox
            title="Objectos cadastrados"
            right={<TeleportObjectCreateButton teleport={teleport} />}
        >
            <Stack>
                {objects?.map(object => (
                    <TeleportObjectCard key={object.id} object={object} />
                ))}
            </Stack>
        </SectionBox>
    </>);
}