import React, {useState, useEffect, useCallback} from 'react';
import UserService from '../../services/UserService';
import AuthService from '../../services/AuthService';
import { SectionBox } from 'components/v2/SectionBox';
import { Button, Checkbox, Group, Image, PasswordInput, Progress, Select, Space, Text, TextInput } from '@mantine/core';
import { At, Bell, Check, FileUpload, Lock, User, UserCircle, Users, Video, X } from 'tabler-icons-react';
import { useForm } from '@mantine/form';
import { useMutation } from 'react-query';
import { showNotification } from '@mantine/notifications';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { useModals } from '@mantine/modals';

function UserEditForm({ user, notify, updateMe = false }) {

    const modals = useModals();

    // PERMISSIONS
    const [ADMIN_ROLE, setAdminRole] = useState(false);
    const [me, setMe] = useState({});

    useEffect(() => {
        async function getPermissions() {
            setAdminRole(await UserService.hasRole("ADMIN"));
        }

        getPermissions();
        if(localStorage.getItem('me'))
            setMe(JSON.parse(localStorage.getItem('me')));
    }, []);

    useEffect(() => {
        form.setValues({
            email: user?.email ? user?.email : '',
            name: user?.name ? user?.name : '',
            password: undefined,
            passwordConfirm: undefined,
            emailNotification: user?.emailNotification ? user?.emailNotification : '',
            enabled: user?.enabled ? user?.enabled : '',
            alwaysUseVirtualBackground: user?.alwaysUseVirtualBackground ? user?.alwaysUseVirtualBackground : '',
            idRole: user?.roles[0]?.id ? user?.roles[0]?.id : ''
        });
    // eslint-disable-next-line
    }, [user]);
    
    // ROLES
    const [roles, setRoles] = useState([]);

    const getRoles = useCallback(async () => {
        let response = await AuthService.get('/roles');
        setRoles(response.data); 
    }, []);

    useEffect(() => {
        getRoles();
    // eslint-disable-next-line
    }, [getRoles]);

    // USER PROFILE
    const form = useForm({
        initialValues: {
            email: user?.email ? user?.email : '',
            name: user?.name ? user?.name : '',
            password: undefined,
            passwordConfirm: undefined,
            emailNotification: user?.emailNotification ? user?.emailNotification : '',
            enabled: user?.enabled ? user?.enabled : '',
            alwaysUseVirtualBackground: user?.alwaysUseVirtualBackground ? user?.alwaysUseVirtualBackground : '',
            idRole: user?.roles[0]?.id ? user?.roles[0]?.id : ''
        },
    
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'E-mail inválido'),
        },
    });

    const editUser = useMutation(values => {
        return AuthService.put('/account/' + user.id, values);
    }, {
        onSuccess: (response) => {
            showNotification({
                title: <>Usuário atualizado com sucesso</>,
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            });
            notify();
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            });
        }
    })

    // UPLOAD PROFILE PICTURE
    const [ profilePictureUploadProgress, setProfilePictureUploadProgress] = useState(undefined);

    const uploadProfilePicture = useMutation(files => {
        if(files.length !== 1) return Promise.reject();

        const config = {
            onUploadProgress: progressEvent => {
                setProfilePictureUploadProgress(Math.round((progressEvent.loadedBytes * 100) / files[0].size));
            }
        }

        let formData = new FormData();
        formData.append('image', files[0]);

        return AuthService.put('/account/' + user.id + '/profile/picture', formData, config);        
    }, {
        onSuccess: (response) => {
            showNotification({
                title: 'Imagem de perfil atualizada com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            });
            notify();
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    });

    // DELETE PROFILE PICTURE
    const deleteProfilePictureModal = () => modals.openConfirmModal({
        title: 'Você tem certeza que deseja remover a imagem de perfil?',
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            await AuthService.delete('/account/' + user.id + '/profile/picture');
            showNotification({
                title: 'Imagem de perfil deletada com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            });
            notify();
        }
    });

    // UPLOAD VIRTUAL BACKGROUND
    const [ virtualBackgroundUploadProgress, setVirtualBackgroundUploadProgress] = useState(undefined);

    const uploadVirtualBackground = useMutation(files => {
        if(files.length !== 1) return Promise.reject();

        const config = {
            onUploadProgress: progressEvent => {
                setVirtualBackgroundUploadProgress(Math.round((progressEvent.loadedBytes * 100) / files[0].size));
            }
        }

        let formData = new FormData();
        formData.append('image', files[0]);

        return AuthService.put('/account/' + user.id + '/profile/virtualbackground', formData, config);        
    }, {
        onSuccess: (response) => {
            showNotification({
                title: 'Fundo personalizado atualizado com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            });
            notify();
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    // DELETE VIRTUAL BACKGROUND
    const deleteVirtualBackgroundModal = () => modals.openConfirmModal({
        title: 'Você tem certeza que deseja remover o fundo personalizado?',
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            await AuthService.delete('/account/' + user.id + '/profile/virtualbackground');
            showNotification({
                title: 'Fundo personalizado deletado com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            });
            notify();
        }
    });
    
    return (
        <>
            {user &&
                <form onSubmit={form.onSubmit(values => {editUser.mutate(values)})} style={{width: '100%'}}>
                    <SectionBox 
                        title={<Group spacing={3}><User />Conta</Group>} 
                        tooltip=""
                    >
                        <TextInput 
                            readOnly
                            label="Email"
                            size="md"
                            icon={<At />}
                            name="email"
                            placeholder=""
                            {...form.getInputProps('email')}
                        />

                        {ADMIN_ROLE && user.id !== me.id && <>
                            <Select
                                required
                                label="Cargo"
                                icon={<Users />}
                                placeholder="Selecione um"
                                size="md"
                                data={roles.map(role => {return {value: role.id, label: role.name}})}
                                {...form.getInputProps('idRole')}
                            />
                        </>}

                        <TextInput 
                            label="Nome"
                            size="md"
                            icon={<User />}
                            name="name"
                            placeholder=""
                            required
                            {...form.getInputProps('name')}
                        />

                        <PasswordInput  
                            label="Nova senha"
                            size="md"
                            icon={<Lock />}
                            name="password"
                            placeholder=""
                            value={form.getInputProps('password').value}
                            onChange={e => {
                                if(e.target.value === '') {
                                    form.getInputProps('password').onChange(undefined);
                                } else {
                                    form.getInputProps('password').onChange(e.target.value);
                                }
                            }}
                        />

                        <PasswordInput  
                            label="Confirmar nova senha"
                            size="md"
                            icon={<Lock />}
                            name="passwordConfirm"
                            placeholder=""
                            value={form.getInputProps('passwordConfirm').value}
                            onChange={e => {
                                if(e.target.value === '') {
                                    form.getInputProps('passwordConfirm').onChange(undefined);
                                } else {
                                    form.getInputProps('passwordConfirm').onChange(e.target.value);
                                }
                            }}
                        />

                        <Space h="lg" />

                        {ADMIN_ROLE && <>
                            <Checkbox
                                label="Conta ativa"
                                name="enabled"
                                size="md"
                                checked={form.getInputProps('enabled').value}
                                {...form.getInputProps('enabled')}
                            />
                        </>}
                    </SectionBox>

                    <Space h="lg" />

                    <SectionBox 
                        title={<Group spacing={3}><UserCircle />Imagem de perfil</Group>}
                        tooltip="Envie uma imagem em formato quadrado, com até 5Mb e 800x800 pixels"
                        right={
                            <Button variant="subtle" onClick={deleteProfilePictureModal}>Deletar imagem de perfil</Button>
                        }>
                            <Dropzone
                                onDrop={(files) => {uploadProfilePicture.mutate(files)}}
                                onReject={(files) => {
                                    showNotification({
                                        color: 'red',
                                        title: 'O arquivo precisa ser do formato .png ou .jpg e ter menos que 5MB de tamanho',
                                        message: '',
                                        autoClose: 5000,
                                        icon: <X />
                                    });
                                }}
                                maxSize={5 * 1024 ** 2}
                                accept={[MIME_TYPES.jpeg, MIME_TYPES.png]}
                                multiple={false}
                            >
                                {uploadProfilePicture.isLoading ? 
                                    <Progress value={profilePictureUploadProgress} size="xl" label={(profilePictureUploadProgress ? profilePictureUploadProgress : 0) + "%"} />
                                :
                                    <Group position="center" spacing="xs" style={{ minHeight: 50, pointerEvents: 'none' }}>
                                        <FileUpload size={20} />
                                        <Text size="sm" inline align="center">
                                            Solte sua imagem ou clique aqui para selecionar um arquivo
                                        </Text>
                                    </Group>
                                }
                            </Dropzone>
                    </SectionBox>

                    <Space h="lg" />

                    <SectionBox 
                        title={<Group spacing={3}><Bell />Notificação</Group>}
                        tooltip=""
                    >
                        <Checkbox
                            label="Notificação por e-mail"
                            name="emailNotification"
                            size="md"
                            checked={form.getInputProps('emailNotification').value}
                            {...form.getInputProps('emailNotification')}
                        />
                    </SectionBox>

                    <Space h="lg" />

                    <SectionBox 
                        title={<Group spacing={3}><Video />Vídeo Chamada</Group>}
                        tooltip=""
                        right={<>
                            {user?.virtualBackground?.webp &&
                                <Button variant="subtle" onClick={deleteVirtualBackgroundModal}>Deletar fundo personalizado</Button>
                            }
                        </>}
                    >
                        {user?.virtualBackground?.webp && <>
                            <Image src={window.ENV.StaticURL + user.virtualBackground.webp} height={150} width="auto" 
                                styles={(theme) => ({
                                    image: {
                                        transform: 'scaleX(-1)'
                                    }
                                })} 
                            />
                            <Space h="lg" />
                        </>}  

                        <Dropzone
                            onDrop={(files) => {uploadVirtualBackground.mutate(files)}}
                            onReject={(files) => {
                                showNotification({
                                    color: 'red',
                                    title: 'O arquivo precisa ser do formato .png ou .jpg e ter menos que 5MB de tamanho',
                                    message: '',
                                    autoClose: 5000,
                                    icon: <X />
                                });
                            }}
                            maxSize={5 * 1024 ** 2}
                            accept={[MIME_TYPES.jpeg, MIME_TYPES.png]}
                            multiple={false}
                        >
                            {uploadVirtualBackground.isLoading ? 
                                <Progress value={virtualBackgroundUploadProgress} size="xl" label={(virtualBackgroundUploadProgress ? virtualBackgroundUploadProgress : 0) + "%"} />
                            :
                                <Group position="center" spacing="xs" style={{ minHeight: 50, pointerEvents: 'none' }}>
                                    <FileUpload size={20} />
                                    <Text size="sm" inline align="center">
                                        Solte sua imagem ou clique aqui para selecionar um arquivo
                                    </Text>
                                </Group>
                            }
                        </Dropzone>

                        <Space h="lg" />

                        <Checkbox
                            label="Sempre usar fundo personalizado"
                            name="alwaysUseVirtualBackground"
                            size="md"
                            checked={form.getInputProps('alwaysUseVirtualBackground').value}
                            {...form.getInputProps('alwaysUseVirtualBackground')}
                        />
                    </SectionBox>

                    <Space h="lg" />

                    <Group position="right">
                        <Button type="submit">Salvar</Button>
                    </Group>
                </form>
            }
        </>
    )
}

export default UserEditForm;