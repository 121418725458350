import { LoadingOverlay, Tabs } from "@mantine/core";
import { ContentWrapper, DashboardContent, LeftContent, RightContent } from "components/v2/Dashboard/Layout";
import { PermissionMenu } from "components/v2/PermissionMenu";
import { SalesTeamLeadList } from "components/v2/SalesTeam/SalesTeamLeadList";
import { SalesTeamLeadScheduleList } from "components/v2/SalesTeam/SalesTeamLeadScheduleList";
import { SalesTeamEdit } from "components/v2/SalesTeam/SalesTeamEdit";
import { SalesTeamOnlineButton } from "components/v2/SalesTeam/SalesTeamOnlineButton";
import { SalesTeamOnlineUserList } from "components/v2/SalesTeam/SalesTeamOnlineUserList";
import { SalesTeamTeleportList } from "components/v2/SalesTeam/SalesTeamTeleportList";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { BuildingCommunity, PhoneCall, Settings, SmartHome, Subtask } from "tabler-icons-react";
import { SalesTeamCalendar } from "components/v2/SalesTeam/SalesTeamCalendar";
import { useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import notificationSound from '../../../../assets/sounds/notification.mp3';
import { UserContext } from "context/User/UserContext";
import { SalesTeamFavoriteLinksList } from "components/v2/SalesTeam/SalesTeamFavoriteLinksList";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import { SalesTeamEmailConfiguration } from "components/v2/SalesTeam/SalesTeamEmailConfiguration";
import { WebSocketContext } from "context/WebSocket/WebSocketContext";
import { SalesTeamLeadCreate } from "components/v2/SalesTeam/SalesTeamLeadCreate";
import { SalesTeamEventList } from "components/v2/SalesTeam/SalesTeamEventList";
import { Error404 } from "components/v2/Dashboard/404";

export function SalesTeamEditPage() {

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // PARAMS
    const idSalesTeam = useParams().idSalesTeam;

    // USER CONTEXT
    const userContext = useContext(UserContext);

    // SALES TEAM
    const {data: salesTeam, isLoading, isError} = useQuery(['sale-team', idSalesTeam], () => {
        return BackendServiceV2.get('/salesteam/' + idSalesTeam);
    })

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    useEffect(() => {
        resourcePermission.setResource('SALES_TEAM', idSalesTeam);
    // eslint-disable-next-line
    }, [idSalesTeam]);

    // ASSIGN USER
    const {data: userData } = useQuery(['sale-team-assign-user', resourcePermission], () => {
        if(resourcePermission.containsGroup(['ADMIN', 'COORDENADOR'])) {
            return BackendServiceV2.get('/resource/group?page=0&size=100', {
                params: {resourceClass: 'SALES_TEAM', resourceId: idSalesTeam}
            });
        }

        return null;
    })

    // WEBSOCKET
    const webSocket = useContext(WebSocketContext);
    const notification = useRef(new Audio());
    notification.current.src = notificationSound;
    const [notificationNumber, setNotificationNumber] = useState(0);
    const notificationInterval = useRef();
    const showNotificationIcon = useRef(false);

    useEffect(() => {
        window.addEventListener('focus', clearNotifications);
        window.addEventListener('click', clearNotifications);

        return () => {
            window.removeEventListener('focus', clearNotifications);
            window.removeEventListener('click', clearNotifications);
            
            if(notificationInterval.current)
                clearInterval(notificationInterval.current)
        }
    // eslint-disable-next-line
    }, [salesTeam]);

    useEffect(() => {
        if(notificationNumber > 0) {
            document.title = '(' + notificationNumber + ') ' + document.title;

            if(!notificationInterval.current)
                startFaviconNotification();
        }
    // eslint-disable-next-line
    }, [notificationNumber]);

    async function clearNotifications() {
        if(notificationInterval.current)
            clearInterval(notificationInterval.current)
        
        setNotificationNumber(0);
        document.title = 'Dashboard - ' + salesTeam?.name;

        showNotificationIcon.current = false;
        updateFavicon();
    }

    async function startFaviconNotification() {
        let interval = setInterval(() => {
            showNotificationIcon.current = !showNotificationIcon.current;
            updateFavicon();
        }, 1000);

        notificationInterval.current = interval;
    }

    async function updateFavicon() {
        let URL;
        if(showNotificationIcon.current) {
            URL = process.env.PUBLIC_URL + '/notification-icon.svg';
        } else {
            URL = process.env.PUBLIC_URL + '/iteleport-icon.svg';
        }

        let favicon = document.getElementById('favicon');
        favicon.href = URL;
    }

    useEffect(() => {
        if(!salesTeam || !webSocket.client.connected) return;

        let coordNotification = webSocket.client.subscribe('/salesteam/' + salesTeam.id, (message) => {
            if(!message.body)
                return;

            let data = JSON.parse(message.body);

            if(data.type === 'SOUND_ONLY' && resourcePermission.containsGroup(['ADMIN', 'COORDENADOR'])) {
                notification.current.play();
                setNotificationNumber(old => {return old + 1});
                queryClient.invalidateQueries('sales-team-leads');
                queryClient.invalidateQueries('sales-team-calendar');
            }
        });

        let userNotification = webSocket.client.subscribe('/salesteam/' + salesTeam.id + '/user/' + userContext.loggedUserData?.email, (message) => {
            if(!message.body)
                return;

            let data = JSON.parse(message.body);

            if(data.type === 'SOUND_ONLY') {
                notification.current.play();
                setNotificationNumber(old => {return old + 1});
                queryClient.invalidateQueries('sales-team-leads');
                queryClient.invalidateQueries('sales-team-calendar');
            }
        });

        return () => {
            coordNotification.unsubscribe();
            userNotification.unsubscribe();
        }
    // eslint-disable-next-line
    }, [salesTeam, webSocket]);

    if(isLoading) return (
        <DashboardContent>
            <LoadingOverlay visible={isLoading} />
        </DashboardContent>
    )

    if(isError) return (
        <Error404 />
    )

    return (
            <DashboardContent 
                title={salesTeam?.name} 
                icon={<BuildingCommunity size={30} />}
                right={<>
                    {resourcePermission.containsGroup(['ADMIN', 'COORDENADOR']) && 
                        <PermissionMenu resourceClass='SALES_TEAM' resourceId={idSalesTeam} invalidateQueryName="sale-team-assign-user" />
                    }
                    <SalesTeamLeadCreate salesTeam={salesTeam} />
                    <SalesTeamOnlineButton salesTeam={salesTeam} />
                </>}
            >
                <Tabs defaultValue="connect">
                    <Tabs.List>
                        <Tabs.Tab value="connect" icon={<PhoneCall />}>Meu Connect</Tabs.Tab>

                        {resourcePermission.containsGroup(['ADMIN', 'COORDENADOR']) && 
                            <Tabs.Tab value="coordinate" icon={<Subtask />}>Coordenar</Tabs.Tab>
                        }
                        
                        {resourcePermission.containsGroup(['ADMIN']) && 
                            <Tabs.Tab value="events" icon={<SmartHome />}>Eventos</Tabs.Tab>
                        }

                        {resourcePermission.containsGroup(['ADMIN', 'COORDENADOR']) && 
                            <Tabs.Tab value="configuration" icon={<Settings />}>Configurações</Tabs.Tab>
                        }
                    </Tabs.List>

                    <Tabs.Panel value="connect">
                        <ContentWrapper>
                            <LeftContent>
                                <SalesTeamLeadList salesTeam={salesTeam} userData={userData} assignedOnly />
                                <SalesTeamCalendar salesTeam={salesTeam} userData={userData} assignedOnly />
                            </LeftContent>

                            <RightContent>
                                <SalesTeamOnlineUserList salesTeam={salesTeam} />
                                <SalesTeamLeadScheduleList salesTeam={salesTeam} userData={userData} assignedOnly />
                            </RightContent>
                        </ContentWrapper>
                    </Tabs.Panel>

                    {resourcePermission.containsGroup(['ADMIN', 'COORDENADOR']) && 
                        <Tabs.Panel value="coordinate">
                            <ContentWrapper>
                                <LeftContent>
                                    <SalesTeamLeadList salesTeam={salesTeam} userData={userData} />
                                    <SalesTeamCalendar salesTeam={salesTeam} userData={userData} />
                                </LeftContent>

                                <RightContent>
                                    <SalesTeamOnlineUserList salesTeam={salesTeam} />
                                    <SalesTeamLeadScheduleList salesTeam={salesTeam} userData={userData} notAssignedOnly />
                                </RightContent>
                            </ContentWrapper>
                        </Tabs.Panel>
                    }

                    {resourcePermission.containsGroup(['ADMIN']) && 
                        <Tabs.Panel value="events">
                            <ContentWrapper>
                                <LeftContent>
                                    <SalesTeamEventList salesTeam={salesTeam} />
                                </LeftContent>

                                <RightContent>
                                    
                                </RightContent>
                            </ContentWrapper>
                        </Tabs.Panel>
                    }

                    {resourcePermission.containsGroup(['ADMIN', 'COORDENADOR']) && 
                        <Tabs.Panel value="configuration">
                            <ContentWrapper>
                                <LeftContent>
                                    <SalesTeamTeleportList salesTeam={salesTeam} />
                                    <SalesTeamFavoriteLinksList salesTeam={salesTeam} />
                                    <SalesTeamEmailConfiguration salesTeam={salesTeam} />
                                </LeftContent>

                                <RightContent>
                                    <SalesTeamEdit salesTeam={salesTeam} />
                                </RightContent>
                            </ContentWrapper>
                        </Tabs.Panel>
                    }
                </Tabs>
            </DashboardContent>
    )
}