import 'dayjs/locale/pt-br';
import { DatePicker } from "@mantine/dates";
import { Badge, Button, Stack, Text } from "@mantine/core";
import React, {  useContext, useState } from "react";
import { SectionBox } from 'components/v2/SectionBox';
import { CalendarEvent } from 'tabler-icons-react';
import { UserContext } from 'context/User/UserContext';
import dayjs from 'dayjs';
import { SalesTeamLeadScheduleList } from '../SalesTeamLeadScheduleList';
import { useQuery } from 'react-query';
import BackendServiceV2 from 'services/v2/BackendService';
import { ResourcePermissionContext } from 'context/Permission/ResourcePermissionContext';

var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

export function SalesTeamCalendar({salesTeam, userData, assignedOnly = false}) {

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    // USER DATA
    const userContext = useContext(UserContext);

    // CALENDAR
    const [month, setMonth] = useState(new Date());
    const [assigned, setAssigned] = useState(assignedOnly);

    const {data: schedules} = useQuery(['sales-team-calendar', month, assigned], () => {
        let requestURL = '/salesteam/' + salesTeam.id + '/leads/?page=0&size=1000&status=SCHEDULED' +
        '&startDate=' + dayjs(month).startOf('month').toDate() + '&endDate=' + dayjs(month).endOf('month').toDate();
        
        if(assigned) {
            requestURL += '&assignedUser=' + userContext.loggedUserData.id;
        }
        
        return BackendServiceV2.get(requestURL);
    })
    
    // CALENDAR LEVEL CONTROL
    const [date, setDate] = useState(undefined)

    if(date) {
        return (
            <SectionBox
                title="Agenda"
                icon={<CalendarEvent />}
                right={
                    <Button variant='subtle' onClick={e => setDate(undefined)}>Voltar</Button>
                }
            >
                <SalesTeamLeadScheduleList salesTeam={salesTeam} userData={userData} onDate={date} />
            </SectionBox>
        )
    }

    return (
        <SectionBox
            title={assignedOnly ? 'Agenda' : 'Agenda'}
            icon={<CalendarEvent />}
            right={<>
                {false && resourcePermission.containsGroup(['ADMIN', 'COORDENADOR']) && <>
                    {assigned ?
                        <Button variant='subtle' onClick={e => setAssigned(false)}>Todos agendamentos</Button>
                    :
                        <Button variant='subtle' onClick={e => setAssigned(true)}>Meus agendamentos</Button>
                    }
                </>}
            </>}
        >
            <DatePicker
                miw="100%"
                onChange={setDate}
                size="xl"
                locale="pt-br"
                firstDayOfWeek={0}
                hideOutsideDates={true}
                level="month"
                onNextMonth={setMonth}
                onPreviousMonth={setMonth}
                renderDay={(date) => {
                    let totalSchedules = 0;
                       
                    if(schedules) {
                        schedules.result.forEach(scheduleDate => {
                            if(dayjs(new Date(scheduleDate.connect.createdAtDate)).isBetween(dayjs(date).startOf('day'), dayjs(date).endOf('day')))
                                totalSchedules++;
                        })
                    }

                    return (
                        <Stack spacing={0} align="center">
                            <Text weight={600}>{date.getDate()}</Text>
                            {totalSchedules > 0 ? <Badge variant='outline'>{totalSchedules} agendamentos</Badge> : null}
                        </Stack>
                    )
                }}
                styles={(theme) => ({
                    monthLevel: {
                        width: '100%'
                    },
                    calendarHeader: {
                        width: '100%',
                        maxWidth: 'initial'
                    },
                    month: {
                        width: '100%'
                    },
                    cell: {
                        border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]}`,
                    },
                    day: { 
                        width: '100%',
                        borderRadius: 0, 
                        height: 70, 
                        fontSize: theme.fontSizes.lg 
                    },
                    weekday: { 
                        fontSize: theme.fontSizes.lg 
                    },
                    weekdayCell: {
                        fontSize: theme.fontSizes.xl,
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
                        border: `1px solid ${
                            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
                        }`,
                        height: 70,
                    },
                })}
            />
        </SectionBox>
    )
}