import { Badge, Group, Stack, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UserService from 'services/UserService';

export function TeleportCard({ teleport }) {

    const [ADMIN_ROLE, setAdminRole] = useState(false);

    useEffect(() => {
        async function getPermissions() {
            setAdminRole(await UserService.hasRole('ADMIN'));
        }

        getPermissions();
    }, []);

    return (
        <Link className="list-entry" to={"/teleport/" + teleport.id}>
            <Stack spacing={0} style={{flex: 1}}>
                <Group position='apart' noWrap>
                    <Text size="sm" lineClamp={1} weight={600}>
                        <span className="list-name">{teleport.metadata.title}</span>
                    </Text>
                    
                    <Group spacing={3} noWrap>
                        {ADMIN_ROLE && teleport?.metadata?.internalName && <Badge variant='outline' size='sm'>{teleport?.metadata?.internalName}</Badge>}

                        <Badge variant='outline' size='sm'>{teleport?.id}</Badge>

                        <Badge variant='filled' size='sm'>{teleport?.type}</Badge>
                    </Group>
                </Group>
                
                <Text size="xs" color="dimmed">
                    <span>{teleport?.metadata?.subtitle ? teleport.metadata.subtitle : 'Sem descrição'}</span>
                </Text>
            </Stack>
        </Link>
    )
}