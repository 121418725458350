import { Button, Image, LoadingOverlay, Select, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import React, { useContext, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Eye, X } from "tabler-icons-react";
import equal from'fast-deep-equal';
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';

export function Publication({publicationType, idResource}) {

    const resourcePermission = useContext(ResourcePermissionContext);

    const queryClient = useQueryClient();

    const form = useForm({
        initialValues: {
            slug: ' ',
            status: ''
        },
        validate: {
            slug: (value) => value.includes(' ') ? 'A URL personalizada não pode conter espaços' : null,
        },
    });

    const {data: publication, isFetching} = useQuery('publication-data', () => {
        return BackendServiceV2.get(`/publication?type=${publicationType}&idResource=${idResource}`);
    }, {
        onSuccess: (response) => {
            form.setFieldValue('slug', response?.slug);
            form.setFieldValue('status', response?.status);
        }
    });

    const {mutate: updatePublication, isLoading: isUpdating} = useMutation((data) => {
        if(!publication) return;

        return BackendServiceV2.put(`/publication/${publication?.id}`, data)
    }, {
        onSuccess: (response) => {
            queryClient.invalidateQueries('publication-data');

            showNotification({
                title: 'Publicação atualizada com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            });
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    const [debouncedFormValues] = useDebouncedValue(form.values, 3000);

    useEffect(() => {
        console.log("HERE");
        if(!publication || isFetching) return;

        form.validate();

        if(!form.isValid()) return;

        if(!equal({slug: publication?.slug, status: publication?.status}, debouncedFormValues))
            updatePublication(debouncedFormValues);
    // eslint-disable-next-line
    }, [debouncedFormValues])

    return (
        <SectionBox
            title="Publicação"
            description="Configura a exibição do Teleport no portal"
            isLoading={isUpdating}
        >
            <LoadingOverlay visible={isFetching} />

            <Stack>
                {resourcePermission.containsGroup(['ADMIN']) &&
                    <form>
                        <Stack>
                            <TextInput
                                required
                                label="URL personalizada"
                                size="md"
                                {...form.getInputProps('slug')}
                                styles={(theme) => ({
                                    input: {
                                        textTransform: 'lowercase'
                                    }
                                })}
                            />

                            {publication?.status === 'PRIVATE' ? 
                                <Button 
                                    onClick={e => {
                                        updatePublication({...form.values, status: 'PUBLIC'});
                                    }}
                                >
                                    Publicar
                                </Button>
                            :
                                <Select
                                    required
                                    withAsterisk={false}
                                    label="Visibilidade"
                                    placeholder="Selecione um"
                                    size="md"
                                    data={[
                                        {value: 'PRIVATE', label: 'Privado', description: ''},
                                        {value: 'NOT_LISTED', label: 'Não listado', description: ''},
                                        {value: 'PUBLIC', label: 'Público', description: ''}
                                    ]}
                                    {...form.getInputProps('status', { type: 'select' })}
                                />
                            }
                        </Stack>
                    </form>
                }

                {publication?.status !== 'PRIVATE' && publication?.publishedAt &&
                    <Text align="center" size="xs">
                        Publicado em {dayjs(new Date(publication?.publishedAt)).locale('pt-br').format('D/MM/YYYY HH[h]mm[m]')}
                    </Text>
                }

                {publication?.status !== 'PRIVATE' && publication?.qrcode &&
                    <Image
                        src={window.ENV.StaticURL + publication.qrcode}
                    />
                }

                {publication?.status !== 'PRIVATE' &&
                    <Button 
                        variant="subtle" 
                        leftIcon={<Eye />} 
                        component="a" 
                        href={(window.ENV.IsDev ? '/portal/' : 'https://portal.iteleport.com.br/') + (publicationType === 'TELEPORT' ? 'tour/' : 'empreendimento/') + publication?.slug} 
                        target="_blank"
                    >
                        Visualizar no portal
                    </Button>
                }
            </Stack>
        </SectionBox>
    )
}