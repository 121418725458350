import { LoadingOverlay, Pagination, Space } from "@mantine/core";
import { SearchBox } from "components/v2/SearchBox";
import { SectionBox } from "components/v2/SectionBox";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Bolt } from "tabler-icons-react";
import { ExpressCard } from "../ExpressCard";

export function ExpressList() {

    const queryClient = useQueryClient();

    // TELEPORT LIST
    const [currentPage, setCurrentPage] = useState(1);
    const [title, setTitle] = useState(undefined);

    useEffect(() => {
        setCurrentPage(0);
    }, [title]);

    const {data: expressProjects, isLoading} = useQuery(['express-project-list-data', currentPage], () => {
        return BackendServiceV2.get(`/express/project/?page=${currentPage}`);
    });
    
    return (
        <SectionBox 
            title="Projetos" 
            icon={<Bolt />}
        >
            <LoadingOverlay visible={isLoading} />

            {false && <SearchBox onSearch={setTitle} />}

            {expressProjects?.result?.map(expressProject => (
                <ExpressCard key={expressProject.id} expressProject={expressProject} />
            ))}

            {expressProjects?.result?.length > 0 && <>
                <Space h="lg" />
                <Pagination 
                    value={expressProjects?.pageNumber + 1} 
                    onChange={(page) => {setCurrentPage(page - 1); queryClient.invalidateQueries('express-project-list-data')}} 
                    total={expressProjects?.totalPages} 
                />
            </>}
        </SectionBox>
    )
}