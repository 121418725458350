import { Space, Switch } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import { PlayerContext } from "context/Player/PlayerContext";
import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";

export function TeleportCover({teleport}) {

    const queryClient = useQueryClient();

    const playerContext = useContext(PlayerContext);

    const {mutate: updateTeleportCover, isLoading} = useMutation((data) => {
        return BackendServiceV2.put('/teleport/' + teleport.id, {
            metadata: {
                cover: data
            }
        });
    }, {
        onSuccess: (success, data) => {
            queryClient.invalidateQueries('teleport-data');
            playerContext?.player?.reload();
            
            showNotification({
                title: 'Configurações da capa atualizada com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error, data) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    })

    return (
        <SectionBox title="Capa" isLoading={isLoading} description="Configura a exibição e carregamento da capa">
            <Switch 
                label="Usar capa"
                size="md"
                checked={teleport?.metadata?.cover?.coverEnabled || ''} 
                onChange={(e) => {
                    updateTeleportCover({'coverEnabled': e.currentTarget.checked})
                }} 
            />

            <Space h="md" />

            <Switch 
                label="Carregar Teleport em segundo plano"
                size="md"
                checked={teleport?.metadata?.cover?.coverBackgroundLoadEnabled || ''} 
                onChange={(e) => {
                    updateTeleportCover({'coverBackgroundLoadEnabled': e.currentTarget.checked})
                }}  
            />

            <Space h="md" />

            <Switch 
                label="Exibir somente botão de Play"
                size="md"
                checked={teleport?.metadata?.cover?.coverPlayOnly || ''} 
                onChange={(e) => {
                    updateTeleportCover({'coverPlayOnly': e.currentTarget.checked})
                }}  
            />
        </SectionBox>
    )
}