import React, { useEffect, useRef, useState } from "react"
import { TeleportCarouselContext } from "../TeleportCarouselContext"

export function TeleportCarouselContextProvider({children, idTeleport}) {

    // IFRAME CONTEXT
    const iframeRef = useRef();

    function setIframeRef(iframe) {
        iframeRef.current = iframe;
    }

    // IFRAME SEND POST MESSAGE
    function sendIframeMessage(messageType) {
        iframeRef?.current?.contentWindow?.postMessage({type: messageType}, '*');
    }

    // IFRAME READ POST MESSAGE
    const [iFrameMessage, setIFrameMessage] = useState();
    const [cameraState, setCameraState] = useState('DollHouse');

    useEffect(() => {
        if(!iframeRef?.current) return;

        window.addEventListener("message", postMessageSubscribe);

        return () => {
            window.removeEventListener("message", postMessageSubscribe);
        }
    }, []);

    function postMessageSubscribe(event) {        
        let message = event.data;
        setIFrameMessage(message);

        if(message.type === 'CAMERA_STATE_CHANGE') {
            setCameraState(message.data.newState);
        }
    }

    return (
        <TeleportCarouselContext.Provider value={{idTeleport, setIframeRef, sendIframeMessage, iFrameMessage, cameraState}}>
            {children}
        </TeleportCarouselContext.Provider>
    )
}