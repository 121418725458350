import { ActionIcon, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Trash, X } from "tabler-icons-react";

export function TotemDeleteButton({totem}) {

    const modals = useModals();

    const deleteTotem = useMutation(() => {
        return BackendServiceV2.delete('/totem/' + totem?.id)
    }, {
        onSuccess: () => {            
            showNotification({
                title: 'Remoção do showcase iniciada',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
            window.location.replace('/showcase');
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const deleteTotemConfirmation = () => modals.openConfirmModal({
        title: 'Deletar Totem',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja deletar o showcase <b>{totem?.name}</b>?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            deleteTotem.mutate();
        }
    });

    return (<>
        <ActionIcon color="#ff5e00" variant="subtle" onClick={deleteTotemConfirmation} title="Deletar"><Trash /></ActionIcon>
    </>)
}