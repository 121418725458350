import React, { useState, useEffect, useContext } from 'react';
import { SectionBox } from 'components/v2/SectionBox';
import { SimpleGrid } from '@mantine/core';
import { Check, X } from 'tabler-icons-react';
import { DraggrableCard } from 'components/v2/Draggrable/DraggrableCard';
import { useMutation, useQueryClient } from 'react-query';
import BackendServiceV2 from 'services/v2/BackendService';
import { showNotification } from '@mantine/notifications';
import { TeleportPhotoDeleteButton } from 'components/v2/Teleport/TeleportPhoto/TeleportPhotoDeleteButton';
import { TeleportPhotoDeleteAllButton } from 'components/v2/Teleport/TeleportPhoto/TeleportPhotoDeleteAllButton';
import { TeleportPhotoCreateButton } from './TeleportPhotoCreateButton';
import { TeleportPhotoEditButton } from './TeleportPhotoEditButton';
import { DndContext, closestCenter,KeyboardSensor, PointerSensor, useSensor,useSensors } from '@dnd-kit/core';
import { arrayMove,SortableContext,sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { PlayerContext } from 'context/Player/PlayerContext';

export function TeleportPhoto({photos, idTeleport}) {

    const queryClient = useQueryClient();

    const playerContext = useContext(PlayerContext);
    
    const {mutate: updateTeleportPhotoOrder, isLoading: isPhotoOrderLoading} = useMutation((data) => {
        console.log("SETTING TO ORDER " + data.order);
        return BackendServiceV2.put('/photo/' + data.idPhoto, {
            photoOrder: data.order
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-data');
            playerContext?.player?.reload();
            
            showNotification({
                title: 'Teleport atualizado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    })

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    )

    const [sortableItems, setSortableItems] = useState(photos?.map(photo => (photo?.idPhoto)))

    useEffect(() => {
        setSortableItems(photos?.map(photo => (photo?.idPhoto)));
    }, [photos])

    function onDragEnd(event) {
        const {active, over} = event;

        if (active.id !== over.id) {
            setSortableItems((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);
                return arrayMove(items, oldIndex, newIndex);
            });

            updateTeleportPhotoOrder({idPhoto: active.id, order: over.data.current.sortable.index + 1});
        }
    }

    return (
        <SectionBox 
            title="Galeria" 
            description="Arraste e solte para mudar a ordem" 
            isLoading={isPhotoOrderLoading}
            right={(
                <>
                    {photos.length > 0 && <TeleportPhotoDeleteAllButton idTeleport={idTeleport} />}
                    <TeleportPhotoCreateButton idTeleport={idTeleport} />
                </>
            )}
        >
            <DndContext collisionDetection={closestCenter} sensors={sensors} onDragEnd={onDragEnd}>
                <SortableContext items={sortableItems}>
                    <SimpleGrid cols={3}>
                        {sortableItems?.map((idPhoto) => {
                            let photo = photos?.find(photo => photo?.idPhoto === idPhoto);

                            if(!photo) return <></>

                            return (
                                <DraggrableCard 
                                    key={photo.idPhoto}
                                    id={photo.idPhoto}
                                    title={photo.subtitle}
                                    image={window.ENV.StaticURL + photo.thumbnail}
                                    actions={[
                                        <TeleportPhotoEditButton photo={photo} />,
                                        <TeleportPhotoDeleteButton photo={photo} />
                                    ]}
                                />
                            )
                        })}
                    </SimpleGrid>
                </SortableContext>
            </DndContext>
        </SectionBox>
    )
}