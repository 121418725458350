import { ActionIcon, Badge, Button, Group, LoadingOverlay, Modal, Stack, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Plus, SquarePlus, X } from "tabler-icons-react";

export function TagsManagerAddCategory({type}) {

    const queryClient = useQueryClient();
    
    const [opened, openedHandler] = useDisclosure(false);

    const form = useForm({
        initialValues: {
            name: '',
            type: type
        },
        validate: {
            
        }
    });

    const {mutate: createCategory, isLoading} = useMutation((data) => {
        return BackendServiceV2.post('/resource/metadata/category', data);
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries('metadata-category-list-data');
            openedHandler.close();
            form.reset();
            showNotification({
                title: 'Categoria criada com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    return (<>
        <ActionIcon color="#ff5e00" onClick={openedHandler.open} size={30} title="Adicionar Categoria"><SquarePlus size={30} /></ActionIcon>

        <Modal
            opened={opened}
            onClose={openedHandler.close}
            title={
                <Group grow position="apart">
                    <Group><Plus /><Title order={2}>Adicionar Categoria</Title><Badge>{type}</Badge></Group>
                </Group>
            }
            centered
            size="lg"
            styles={theme => ({
                title: {
                    width: '100%'
                }
            })}
        >
            <LoadingOverlay visible={isLoading} />
            <form id="metadata-category-create" onSubmit={form.onSubmit(values => createCategory(values))}>
                <Stack>
                    <TextInput 
                        required
                        label="Nome"
                        {...form.getInputProps('name')}
                    />

                    <Button type="submit">Salvar</Button>
                </Stack>
            </form>
        </Modal>
    </>)
}