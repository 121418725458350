import { LoadingOverlay, Pagination, Space } from "@mantine/core";
import { SearchBox } from "components/v2/SearchBox";
import { SectionBox } from "components/v2/SectionBox";
import { CompanyCard } from "pages/Dashboard/Company/CompanyCard";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { BuildingStore } from "tabler-icons-react";

export function CompanyList() {

    const queryClient = useQueryClient();

    // TELEPORT LIST
    const [currentPage, setCurrentPage] = useState(1);
    const [name, setName] = useState(undefined);

    useEffect(() => {
        setCurrentPage(0);
    }, [name]);

    const {data: companies, isLoading} = useQuery(['company-list-data', currentPage, name], () => {
        let url = `/company/?page=${currentPage}`;
                
        if(name) {
            url += `&name=${name}`
        }

        return BackendServiceV2.get(url);
    });

    return (
        <SectionBox 
            title="Empresas" 
            icon={<BuildingStore />}
        >
            <LoadingOverlay visible={isLoading} />

            <SearchBox onSearch={setName} />

            {companies?.result?.map(company => (
                <CompanyCard key={companies.idCompany} company={company} />
            ))}

            {companies?.result?.length > 0 && <>
                <Space h="lg" />
                <Pagination 
                    value={companies?.pageNumber + 1} 
                    onChange={(page) => {setCurrentPage(page - 1); queryClient.invalidateQueries('company-list-data')}} 
                    total={companies?.totalPages} 
                />
            </>}
        </SectionBox>
    )
}