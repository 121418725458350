import { Group, Select } from "@mantine/core";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";

const TeleportPlayer = forwardRef(function TeleportPlayer({teleport, visible}, ref) {

    const resourcePermission = useContext(ResourcePermissionContext);

    const [token, setToken] = useState(undefined);
    const [key, setKey] = useState(0);

    useEffect(() => {
        setToken(localStorage.getItem('accessToken'));
    }, []);

    useImperativeHandle(ref, () => {
        return {
            reload() {
                setKey(k => k + 1);
            }
        }
    }, []);

    const [showEditors, setShowEditors] = useState(true);

    useEffect(() => {
        if(resourcePermission.containsGroup(['STUDIO']) && !resourcePermission.containsGroup(['ADMIN']))
            setShowEditors(false);
    }, [resourcePermission]);

    // SKYBOX SET VIEWER
    const [towers, setTowers] = useState([])
    const [floors, setFloors] = useState([]);

    const [selectedTower, setSelectedTower] = useState();
    const [selectedFloor, setSelectedFloor] = useState();

    useEffect(() => {
        if(towers.length !== 0)
            return;

        let _towers = [];
        let _floors = [];

        teleport?.layers?.forEach(layer => {
            layer?.skyboxesSet?.forEach(skyboxSet => {
                if(!_towers.includes(skyboxSet.tower))
                    _towers.push(skyboxSet.tower);

                if(!_floors.includes(skyboxSet.floor))
                    _floors.push(skyboxSet.floor);
            })
        })

        setTowers(_towers.sort());
        setFloors(_floors.sort());
        
        if(_towers.length > 0)
            setSelectedTower(_towers[0]);

        if(_floors.length > 0)
            setSelectedFloor(_floors[0]);
    // eslint-disable-next-line
    }, [])

    if(!visible || teleport?.layers?.length === 0) return <></>

    return (<>
        <iframe 
            className="player"
            key={key}
            title="player" 
            allowFullScreen={true}
            allow="fullscreen; camera; microphone; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay"
            src={window.ENV.PlayerURL + teleport?.id +
                "?dev=" + window.ENV.IsDev +
                (showEditors ? "&editor=true" : "") +
                (showEditors ? "&parameter-editor=true" : "") +
                "&token=" + token + 
                "&share-button=true" + 
                "&screenshot=true" + 
                "&connect=false" + 
                "&background-load=false" +
                "&help=false" +
                (teleport?.metadata && teleport?.metadata?.urlParameters ? teleport?.metadata?.urlParameters : '') +
                (selectedTower && selectedFloor ? `&u=G_T${selectedTower}_${selectedFloor}` : '')
            }
        >
        </iframe>

        {towers.length > 0 && floors.length > 0 && <Group grow noWrap style={{width: '100%'}}>
            <Select
                required
                label="Torre"
                placeholder="Selecione um"
                size="md"
                data={towers.map(tower => {return {value: tower, label: tower}})}
                onChange={(value) => setSelectedTower(value)}
                value={selectedTower}
            />

            <Select
                required
                label="Andar"
                placeholder="Selecione um"
                size="md"
                data={floors.map(floor => {return {value: floor, label: floor}})}
                onChange={(value) => setSelectedFloor(value)}
                value={selectedFloor}
            />
        </Group>}
    </>)
});

export default TeleportPlayer;