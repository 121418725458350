import { Badge, Button, Card, Container, Group, Image, Modal, ScrollArea, Stack, Text } from '@mantine/core';
import { useDisclosure, useHover } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { Edit } from 'tabler-icons-react';
import { TotemRegion } from '../TotemRegion';
import { TotemRegionActionEdit } from '../TotemRegionActionEdit';
import { TotemRegionAddButton } from '../TotemRegionAddButton';
import { TotemRegionDeleteButton } from '../TotemRegionDeleteButton';
import { TotemPageContentEdit } from '../TotemPageContentEdit';
import { useQueryClient } from 'react-query';

export function TotemContentPreview({label, content, pages, type, showcaseType, currentPage}) {

    const queryClient = useQueryClient();

    const { hovered, ref } = useHover();
    const [isEditVisible, setEditVisibility] = useDisclosure(false);

    const [selectedRegion, setSelectedRegion] = useState(undefined);
    const [embedPreview, setEmbedPreview] = useState(undefined);

    useEffect(() => {
        async function getMetaData() {
            const response = await fetch(window.ENV.BackendURL + '/tools/url/meta', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    url: content?.media?.src
                }),
                mode : 'cors'
            })

            let body = await response.text();
            if(response.status !== 200 || await body === '')
                return

            let metadata = await JSON.parse(body);
            setEmbedPreview(metadata.image);
        }

        if(content?.media?.type !== 'EMBED')
            return;

        getMetaData();
    // eslint-disable-next-line
    }, []);

    return (<>
        <Card 
            withBorder 
            style={{
                aspectRatio: showcaseType === 'CAVE' && type === 'SINGLE' ? '16/3' : '16/9',
                maxHeight: '207px'
            }} 
            ref={ref}
            onClick={setEditVisibility.open}
        >
            <Image
                fit='cover'
                src={
                    content?.media?.type === "IMAGE" ? 
                        window.ENV.StaticURL + content?.media?.thumbWebp
                    : 
                        content?.media?.type === "EMBED" && embedPreview ? 
                            embedPreview
                        : 
                            ''
                }
                style={{
                    position: 'absolute',
                    pointerEvents: 'none',
                    width: '100%',
                    height: '100%'
                }}
                styles={{
                    figure: {
                        height: '100%'
                    },
                    imageWrapper: {
                        height: '100%'
                    },
                    image: {
                        height: '100% !important'
                    }
                }}
            />
            {content?.media?.src &&
                <Text
                    color="white"
                    size="xs"
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        padding: '5px 15px'
                    }}
                >
                    {content?.media?.src}
                </Text>
            }
            {hovered ? 
                <Button leftIcon={<Edit />} onClick={setEditVisibility.open}>Editar</Button>
            :
                <>
                {label === 'LEFT' && <Badge size="lg">Esquerda</Badge>}
                {label === 'MAIN' && <Badge size="lg">{type === 'SINGLE' ? 'Tela' : 'Centro'}</Badge>}
                {label === 'RIGHT' && <Badge size="lg">Direita</Badge>}
                </>
            }
        </Card>

        <Modal
            opened={isEditVisible}
            onClose={() => {
                setEditVisibility.close(); 
                setSelectedRegion(undefined);
                queryClient.invalidateQueries('totem-data');            
            }}
            size="auto"
            styles={{
                inner: {
                },
                content: {
                },
                body: {
                    paddingTop: '15px !important'
                },
                header: {
                    display: 'none'
                }
            }}
        >
            <Group noWrap align='start'>
                <ScrollArea mah={660} maw={1088} styles={{viewport: {height: '660px'}}} offsetScrollbars>
                    <Stack w={350}>
                        <TotemPageContentEdit content={content} pages={pages} currentPage={currentPage} />
                        {selectedRegion &&
                            <TotemRegionActionEdit region={selectedRegion} pages={pages} select={setSelectedRegion} />
                        }
                    </Stack>
                </ScrollArea>

                <Stack>
                    <Group>
                        <TotemRegionAddButton content={content} setSelectedRegion={setSelectedRegion} />
                        {selectedRegion && <TotemRegionDeleteButton region={selectedRegion} setSelectedRegion={setSelectedRegion} />}
                    </Group>
                
                    <ScrollArea mah={612} maw={1088} type='always' styles={{viewport: {height: '612px'}}}>
                        <Container 
                            style={{
                                backgroundSize: 'contain', 
                                backgroundColor: '#CCC',
                                width: type === 'SINGLE' && showcaseType === 'CAVE' ? '5760px' : '1920px',
                                height: '1080px',
                                minWidth: type === 'SINGLE' && showcaseType === 'CAVE' ? '5760px' : '1920px',
                                minHeight: '1080px',
                                maxHeight: '1080px',
                                position: 'relative',
                                padding: 0,
                            }}
                            
                            onClick={() => setSelectedRegion(undefined)}
                        >
                            {content?.media?.webp && 
                                <Image
                                    src={window.ENV.StaticURL + content?.media?.webp}
                                    style={{
                                        pointerEvents: 'none',
                                        width: '100%',
                                        height: '100%'
                                    }}
                                />
                            }

                            {content?.media?.type === "EMBED" && <>
                                <iframe 
                                    src={content?.media?.src} 
                                    title="embed_preview" 
                                    style={{
                                        pointerEvents: 'none',
                                        width: '100%',
                                        height: '100%'
                                    }}
                                />
                            </>}

                            {content?.media?.type === "VIDEO" && <>
                                <video 
                                    src={window.ENV.StaticURL + content?.media?.src} 
                                    autoPlay={true}
                                    loop={true}
                                    muted={false}
                                    controls={false}
                                    style={{
                                        pointerEvents: 'none',
                                        width: '100%',
                                        height: '100%'
                                    }} />
                            </>}
                            
                            {content?.regions?.map(region => (
                                <TotemRegion key={region.id} region={region} selected={selectedRegion} select={setSelectedRegion} />
                            ))}

                            {type === 'SINGLE' && showcaseType === 'CAVE' &&
                                <div style={{
                                    position: 'absolute', 
                                    height: '100%', 
                                    width: "33.33%", 
                                    borderRight: '2px dashed red',
                                    borderLeft: '2px dashed red',
                                    top: 0,
                                    left: '33.33%',
                                    pointerEvents: 'none'
                                }}></div>
                            }
                        </Container>
                        
                    </ScrollArea>
                </Stack>
            </Group>
        </Modal>
    </>);
}