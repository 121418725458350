import React, { useEffect } from "react";
import equal from'fast-deep-equal';
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { showNotification } from "@mantine/notifications";
import { Check, X } from "tabler-icons-react";
import { Badge, Checkbox, Group, LoadingOverlay, NumberInput, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";

export function TagValueCard({tag, values, resourceClass, idResource}) {

    const queryClient = useQueryClient();

    const form = useForm({
        initialValues: {
            value: values ? values[tag?.name]?.value : '',
        },
        validate: {
            
        }
    });

    useEffect(() => {
        form.setFieldValue('value', values ? values[tag?.name]?.value : '');
    // eslint-disable-next-line
    }, [values]);

    const {mutate: putTagValueToResource, isLoading} = useMutation((data) => {
        
        let requestBody = {
            resourceClass: resourceClass,
            idResource: idResource,
            idResourceMetadata: tag.id,
            value: data?.value ? '' + data.value : null
        }

        return BackendServiceV2.put('/resource/metadata/value', requestBody);
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries('metadata-category-list-data');
            queryClient.invalidateQueries('metadata-values-list-data');
            showNotification({
                title: 'Tag atualizada com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    });

    const [debouncedFormValues] = useDebouncedValue(form.values, 3000);

    useEffect(() => {
        if(!values) 
            return;

        form.validate();

        if(!form.isValid()) 
            return;

        if(!equal({value: values ? values[tag?.name]?.value : ''}, debouncedFormValues))
            putTagValueToResource(debouncedFormValues);
    // eslint-disable-next-line
    }, [debouncedFormValues])

    function getTypeLabel(type) {
        switch (type) {
            case 'NUMBER':
                return 'Número';
            case 'TEXT':
                return 'Texto'
            default:
                return '';
        }
    }

    return (
        <form id="metadata-category-create" onSubmit={form.onSubmit(values => putTagValueToResource(values))}>
            <LoadingOverlay visible={isLoading} />

            {tag.type === 'NUMBER' &&
                <NumberInput
                    label={<Group>{tag.name}<Badge variant="outline" size="xs">{getTypeLabel(tag.type)}</Badge></Group>}
                    {...form.getInputProps('value')}
                />
            }

            {tag.type === 'TEXT' &&
                <TextInput
                    label={<Group>{tag.name}<Badge variant="outline" size="xs">{getTypeLabel(tag.type)}</Badge></Group>}
                    type="text"
                    {...form.getInputProps('value')}
                />
            }

            {tag.type === 'BOOLEAN' &&
                <Checkbox
                    label={tag.name}
                    {...form.getInputProps('value', {type: "checkbox"})}
                />
            }
        </form>
    )
}