import React, {useState} from 'react';
import { Button, Group, PasswordInput, Space, Text, TextInput } from '@mantine/core';
import { At, Lock, User, X } from 'tabler-icons-react';
import { useForm } from '@mantine/form';
import { useMutation } from 'react-query';
import { showNotification } from '@mantine/notifications';
import AuthService from 'services/AuthService';

function OnboardForm({code, email}) {

    // USER ONBOARDING
    const form = useForm({
        initialValues: {
            email: email ? email : '',
            name: '',
            password: '',
            passwordConfirm: ''
        },
    
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'E-mail inválido'),
            passwordConfirm: (value, values) => value === values.password ? null : 'As senhas não são iguais'
        },
    });

    // ONBOARD SUCCESS
    const [accountCreated, setAccountCreated] = useState(false);
    const [timer, setTimer] = useState(10);

    async function showTimer(timeout) {
        setTimeout(() => {
            if(timeout <= 0)
                window.location.replace(window.ENV.BaseURL + '/id/login');

            setTimer(oldValue => {
                return oldValue - 1;
            });
            showTimer(timeout - 1);
        }, 1000);
    }

    // SUBMIT FORM
    const onboardUser = useMutation(values => {
        return AuthService.post('/account/onboard/' + code, values);
    }, {
        onSuccess: (response) => {
            setAccountCreated(true);
            showTimer(9);
        },
        onError: (error) => {
            showNotification({
                title: error?.response?.data?.message,
                message: '',
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    if (accountCreated) {
        return (
            <div className="onboard" style={{textAlign: 'center'}}>
                <Text weight={600} size="xl">Bem-vindo(a)!</Text>
                <Text>Sua conta foi criada com sucesso. Você será 
                redirecionado automaticamente para a página de login. 
                Caso isso não ocorra nos próximos {timer} segundos, <Text component="a" href={window.ENV.BaseURL + '/id/login'}>clique aqui</Text></Text>
            </div>
        )
    }

    return (
        <form className="onboard" onSubmit={form.onSubmit(values => {onboardUser.mutate(values)})}>
            <Text weight={600} size="lg">Olá, {email}</Text>
            <Text>Bem-vindo(a) à Dashboard iTeleport. Este é o seu primeiro acesso, por isso, precisamos de algumas informações para finalizar o seu cadastro.</Text>

            <Space h="md" />

            <TextInput 
                required
                readOnly
                label="Email"
                size="lg"
                icon={<At />}
                name="email"
                placeholder=""
                {...form.getInputProps('email')}
            />

            <TextInput 
                required
                label="Nome"
                size="lg"
                icon={<User />}
                name="name"
                placeholder=""
                {...form.getInputProps('name')}
            />

            <PasswordInput  
                required
                label="Senha"
                size="lg"
                icon={<Lock />}
                name="password"
                placeholder=""
                {...form.getInputProps('password')}
            />

            <PasswordInput  
                required
                label="Confirmar senha"
                size="lg"
                icon={<Lock />}
                name="passwordConfirm"
                placeholder=""
                {...form.getInputProps('passwordConfirm')}
            />

            <Space h="md" />

            <Group position="right">
                <Button type="submit">Criar conta</Button>
            </Group>
        </form>
    );
}

export default OnboardForm;