import { showNotification } from "@mantine/notifications";
import FileUploader from "components/FileUploader";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import SectionBox from "components/SectionBox";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import { PlayerContext } from "context/Player/PlayerContext";
import React, { useCallback, useContext, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";

// TODO refactor
export function TeleportPersonalization({teleport}) {

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    const playerContext = useContext(PlayerContext);

    // QUERY CLIENT
    const queryClient = useQueryClient();

    const getTeleport = useCallback(async () => {
        queryClient.invalidateQueries('teleport-data');
        playerContext?.player?.reload();
    // eslint-disable-next-line
    }, []);

    // Personalization Ref
    const musicInputRef = useRef(null);
    const [musicProgress, setMusicProgress] = useState(undefined);

    const tagInputRef = useRef(null);
    const [tagProgress, setTagProgress] = useState(undefined);

    function showMessage(message, isError) {
        if(isError) {
            showNotification({
                title: 'Um erro ocorreu',
                message: message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        } else {
            showNotification({
                title: message,
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        }
    }

    function cleanMessage() {
        
    }

    const onSubmitMusicHandler = useCallback(async (e) => {
        e.preventDefault();
        try {
            cleanMessage();

            if(musicInputRef.current.files.length === 1) {
                const config = {
                    onUploadProgress: progressEvent => {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setMusicProgress(percentCompleted);
                    }
                }

                let formData = new FormData();
                formData.append('music', musicInputRef.current.files[0]);
                await BackendServiceV2.put('/teleport/' + teleport?.id + '/music', formData, config);

                showMessage('Music updated with success!');
                getTeleport();
                musicInputRef.current.disabled = false;
            }
        } catch (error) {
            error.response !== undefined ? showMessage(error.response.data.message, true) : showMessage(error.message, true);
            musicInputRef.current.disabled = false;
        }
    }, [getTeleport, teleport?.id]);

    async function deleteMusicHandler() {
        let response = await BackendServiceV2.delete('/teleport/' + teleport?.id + '/music');
        showMessage(response.data.message, false);
        getTeleport();
    }

    const onSubmitTagIconHandler = useCallback(async (e) => {
        e.preventDefault();
        try {
            cleanMessage();

            if(tagInputRef.current.files.length === 1) {
                const config = {
                    onUploadProgress: progressEvent => {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setTagProgress(percentCompleted);
                    }
                }

                let formData = new FormData();
                formData.append('tag', tagInputRef.current.files[0]);
                await BackendServiceV2.put('/teleport/' + teleport?.id + '/tag', formData, config);

                showMessage('Tag updated with success!');
                getTeleport();
                tagInputRef.current.disabled = false;
            }
        } catch (error) {
            error.response !== undefined ? showMessage(error.response.data.message, true) : showMessage(error.message, true);
            tagInputRef.current.disabled = false;
        }
    }, [getTeleport, teleport?.id]);

    async function deleteTagHandler() {
        let response = await BackendServiceV2.delete('/teleport/' + teleport?.id + '/tag');
        showMessage(response.data.message, false);
        getTeleport();
    }
    
    // CONFIRMATIONS MODAL
    const [musicDeleteModal, setMusicDeleteModal] = useState(undefined);
    const [tagIconDeleteModal, setTagIconDeleteModal] = useState(undefined);

    return (<>
        {resourcePermission.containsPermission('TELEPORT_UPDATE') && <>
            <SectionBox 
                title="Personalization" 
                description="Change the Teleport experience" 
                right={undefined}
                className="full-width">
                    <>
                        <SectionBox 
                            title="Music" 
                            description="" 
                            right={resourcePermission.containsPermission('TELEPORT_UPDATE') && teleport?.personalization && teleport?.personalization?.music && 
                                <button className="secundary" onClick={() => setMusicDeleteModal(true)}>Delete</button>
                            }>
                                <ConfirmationModal 
                                    openModal={musicDeleteModal} 
                                    setOpenModal={setMusicDeleteModal} 
                                    title="Are you sure you want to delete the music?" 
                                    callback={deleteMusicHandler} />

                                <form onSubmit={onSubmitMusicHandler}>
                                    { teleport?.personalization && teleport?.personalization?.music &&
                                        <audio controls>
                                            <source src={window.ENV.StaticURL + teleport?.personalization?.music} type="audio/mpeg"></source>
                                            Your browser does not support the audio tag.
                                        </audio>
                                    }
                                    {resourcePermission.containsPermission('TELEPORT_UPDATE') && <>
                                    <input className="file-upload-input" type="file" name="file" ref={musicInputRef} accept=".mp3" required />
                                    <FileUploader hiddenInput={musicInputRef} progress={musicProgress} autoSubmit={true} /></>}
                                </form>
                        </SectionBox>

                        <SectionBox 
                            title="Tag Icon" 
                            description="A 200px square .png image" 
                            right={resourcePermission.containsPermission('TELEPORT_UPDATE') && teleport?.personalization && teleport?.personalization?.tag && teleport?.personalization?.tag?.icon &&
                                <button className="secundary" onClick={() => setTagIconDeleteModal(true)}>Delete</button>
                            }>
                                <ConfirmationModal 
                                    openModal={tagIconDeleteModal} 
                                    setOpenModal={setTagIconDeleteModal} 
                                    title="Are you sure you want to delete the tag icon?" 
                                    callback={deleteTagHandler} />

                                <form onSubmit={onSubmitTagIconHandler}>
                                    { teleport?.personalization && teleport?.personalization?.tag && teleport?.personalization?.tag?.icon &&
                                        <img src={window.ENV.StaticURL + teleport?.personalization?.tag?.icon} alt="Tag preview" />
                                    }
                                    {resourcePermission.containsPermission('TELEPORT_UPDATE') && <>
                                    <input className="file-upload-input" type="file" name="file" ref={tagInputRef} accept=".png" required />
                                    <FileUploader hiddenInput={tagInputRef} progress={tagProgress} autoSubmit={true} /></>}
                                </form>
                        </SectionBox>
                    </>
            </SectionBox>
        </>}
    </>)
}