import React, { useContext, useState } from "react";
import { Button, Center, Pagination, Space } from "@mantine/core";
import { SectionBox } from "components/v2/SectionBox";
import { Check, PhoneCall, X } from "tabler-icons-react";
import { Grid } from "components/v2/Grid";
import { SalesTeamLeadCard } from "../SalesTeamLeadCard";
import { UserContext } from "context/User/UserContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { showNotification, updateNotification } from "@mantine/notifications";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";

export function SalesTeamLeadList({salesTeam, userData, assignedOnly = false}) {

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // USER DATA
    const userContext = useContext(UserContext);

    // LEADS
    const [currentPage, setCurrentPage] = useState(1);
    const [statusFilter, setStatusFilter] = useState('OPEN');

    const {data: leads} = useQuery(['sales-team-leads', currentPage, statusFilter, assignedOnly], () => {
        let requestURL;
        if(assignedOnly) {
            requestURL = '/salesteam/' + salesTeam.id + '/leads/?page=' + (currentPage - 1) + '&status=' + statusFilter + '&assignedUser=' + userContext.loggedUserData.id;
        } else {
            requestURL = '/salesteam/' + salesTeam.id + '/leads/?page=' + (currentPage - 1) + '&status=' + statusFilter;
        }
        
        return BackendServiceV2.get(requestURL);
    });

    const exportLeads = useMutation(()  => {
        showNotification({
            id: 'exporting-lead',
            loading: true,
            title: 'Exportando leads',
            message: '',
            autoClose: false,
            disallowClose: true,
        });

        return BackendServiceV2.get('/salesteam/' + salesTeam.id + '/export');
    }, {
        onSuccess: (response) => {
            window.open(window.ENV.StaticURL + response, '_blank');

            updateNotification({
                id: 'exporting-lead',
                title: <>Leads exportados com sucesso</>,
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            });
        }
    })
    
    return (
        <SectionBox
            title={assignedOnly ? "Chamadas" : "Chamadas abertas"}
            icon={<PhoneCall />}
            fullWidth
            right={<>
                {statusFilter === "OPEN" ? (
                    <Button variant="subtle" 
                        onClick={e => { e.preventDefault(); setStatusFilter('FINISHED') }}
                    >Exibir chamadas encerradas</Button>
                ) : (<>
                    {!assignedOnly && resourcePermission.containsGroup(['ADMIN, COORDENADOR']) && 
                        <Button 
                            variant="subtle"
                            onClick={e => exportLeads.mutate()}
                        >Exportar leads</Button>
                    }
                    <Button variant="subtle" 
                        onClick={e => { e.preventDefault(); setStatusFilter('OPEN') }}
                    >Exibir chamadas abertas</Button>
                </>)}
            </>}
        >

            {leads && leads.result.length === 0 && <Center>Nenhuma chamada encontrada</Center>}

            <Grid>
                {leads && leads.result.map(lead => (
                    <SalesTeamLeadCard key={lead.id} lead={lead} userData={userData} />
                ))}
            </Grid>

            {leads && leads.result.length > 0 && <>
                <Space h="lg" />
                <Pagination 
                    value={leads.pageNumber + 1} 
                    onChange={(page) => {setCurrentPage(page); queryClient.invalidateQueries('sales-team-leads')}} 
                    total={leads.totalPages} 
                />
            </>}
        </SectionBox>
    )
}