import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';
import { Button, Checkbox, Group, Modal, SimpleGrid, Space, Text, TextInput, Title } from "@mantine/core";
import { Calendar } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { Check, Copy, PhoneCall, X } from "tabler-icons-react";
import { useMutation } from 'react-query';
import BackendServiceV2 from 'services/v2/BackendService';
import { useClipboard, useDisclosure } from '@mantine/hooks';

export function ConnectCreate() {

    // QUERY CLIENT
    //const queryClient = useQueryClient();

    const clipboard = useClipboard({ timeout: 500 });

    // CREATE NEW LEAD
    const [joinRoom, joinRoomHandle] = useDisclosure(false);
    const [connect, setConnect] = useState();

    const createNewConnect = useMutation((values) => {
        return BackendServiceV2.post('/connect', values);
    }, {
        onSuccess: response => {
            form.reset();
            setConnect(response);
            joinRoomHandle.open();
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    // LEAD FORM DATA
    const form = useForm({
        initialValues: {
            url: '',
            scheduleTo: undefined
        },
    
        validate: {
            scheduleTo: (value) => schedule ? (value === undefined ? 'A data não pode ser vazia' : null) : null
        },
    });

    // SCHEDULED
    const [schedule, setSchedule] = useState(false);
    const [scheduleDate, setScheduleDate] = useState(undefined);

    const dayTimeOptions = [
        {value: 9},
        {value: 10},
        {value: 11},
        {value: 12},
        {value: 13},
        {value: 14},
        {value: 15},
        {value: 16},
        {value: 17}
    ];

    useEffect(() => {
        if(!schedule) {
            //form.getInputProps('scheduleTo').onChange(undefined);
            setScheduleDate(undefined)
        }
    // eslint-disable-next-line
    }, [schedule])
    
    return (
        <>
            <Modal
                opened={joinRoom}
                onClose={joinRoomHandle.close}
                title={<Group><PhoneCall /><Title order={2}>Compartilhar chamada {connect?.id}</Title></Group>}
                centered
                size="lg"
            >
                
                <div
                    style={{
                        width: '100%',
                        padding: '15px',
                        fontSize: '14px',
                        backgroundColor: '#f1f3f4',
                        userSelect: 'none',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        clipboard.copy(window.ENV.BaseURL + '/tour/connect/' + connect?.id);
                        showNotification({
                            autoClose: 5000,
                            title: "Link copiado com sucesso",
                            message: '',
                            color: 'green',
                            icon: <Check />
                        });
                    }} 
                >
                    <Group position='apart'>
                        <Text lineClamp={1}>{window.ENV.BaseURL + '/tour/connect/' + connect?.id}</Text>
                        <Copy />
                    </Group>
                </div>
                <Button 
                    fullWidth 
                    component="a" 
                    href={'/tour/connect/' + connect?.id} 
                    target="_blank" 
                    rel="noopener noreferrer"
                >Entrar</Button>
            </Modal>

            <form onSubmit={form.onSubmit(values => createNewConnect.mutate(values))}>
                <TextInput
                    required
                    label="URL"
                    placeholder="https://"
                    size="md"
                    {...form.getInputProps('url')}
                />

                {false && <>
                    <Space h="md" />

                    <Checkbox
                        label="Agendar"
                        size="md"
                        checked={schedule} 
                        onChange={(event) => setSchedule(event.currentTarget.checked)}
                    />
                </>}

                {false && schedule && <SimpleGrid cols={2}>
                    <Calendar 
                        value={scheduleDate} 
                        onChange={value => {form.getInputProps('scheduleTo').onChange(undefined); setScheduleDate(value);}} 
                        firstDayOfWeek="sunday"
                        allowLevelChange={false}
                        hideOutsideDates={true}
                        minDate={dayjs(new Date()).add(0, 'days').toDate()}
                        locale="pt-br"
                        excludeDate={(date) => date.getDay() === 0} 
                    />

                    <div>
                        {scheduleDate &&
                            <Group direction='column' grow>
                                <b>{dayjs(scheduleDate).locale('pt-br').format('dddd, D [de] MMMM')}</b>

                                <SimpleGrid cols={2}>
                                    {dayTimeOptions.map(dayTime => (
                                        <Button 
                                            key={dayTime.value} 
                                            onClick={e => {
                                                form.getInputProps('scheduleTo').onChange(dayjs(scheduleDate).add(dayTime.value, 'hour').toDate())
                                            }}
                                            variant={
                                                (dayjs(scheduleDate).add(dayTime.value, 'hour').toDate()?.getTime() === form?.getInputProps('scheduleTo')?.value?.getTime()) ? 'filled' : 'outline'
                                            }
                                        >
                                            {dayjs(scheduleDate).add(dayTime.value, 'hour').locale('pt-br').format('HH:mm')}
                                        </Button>
                                    ))}
                                </SimpleGrid>
                            </Group>
                        }
                    </div>
                </SimpleGrid>}

                <Text size="md" color="#f03e3e">
                    {form?.errors?.scheduleTo}
                </Text>

                <Group position="right" mt="md">
                    <Button type="submit">Nova reunião</Button>
                </Group>
            </form>
        </>
    )    
}