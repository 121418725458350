import { Button, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { SectionBox } from 'components/v2/SectionBox';
import React from 'react';
import { useMutation } from 'react-query';
import BackendServiceV2 from 'services/v2/BackendService';
import { Plus, X } from 'tabler-icons-react';

export function DevelopmentCreate({ company }) {

    // FORM
    const form = useForm({
        initialValues: {
            name: '',
            site: '',
            idCompany: company.idCompany
        },
        validate: {
            
        }
    });

    // CREATE DEVELOPMENT
    const {mutate: createDevelopment, isLoading} = useMutation((data) => {
        return BackendServiceV2.post('/development', data)
    }, {
        onSuccess: (response) => {
            window.location.href = window.ENV.BaseURL + '/development/' + response?.idDevelopment;
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (
        <SectionBox title="Novo Empreendimento" icon={<Plus />} isLoading={isLoading}>
            <form id="development-create-form" onSubmit={form.onSubmit(values => createDevelopment(values))}>
                <Stack>
                    <TextInput 
                        label="Nome"
                        size="md"
                        required
                        {...form.getInputProps('name')}
                    />

                    <TextInput 
                        label="Site"
                        size="md"
                        {...form.getInputProps('site')}
                    />

                    <Button type="submit">Criar</Button>
                </Stack>
            </form>
        </SectionBox>
    );
}

export default DevelopmentCreate;