import React, {useState, useRef} from 'react';
import BackendService from '../../services/BackendService';
import SectionBox from '../SectionBox';
import FileUploader from '../FileUploader';
import Message from '../Message';

function MediaForm({ notify }) {

    const [resultMessage, setResultMessage] = useState(undefined);
    const [typeMessage, setTypeMessage] = useState(undefined);

    const [progress, setProgress] = useState(undefined);
    const mediaInputRef = useRef(null);

    async function onSubmitHandler(e) {
        e.preventDefault();

        setResultMessage(undefined);
        setTypeMessage(undefined);

        try {
            if(mediaInputRef.current.files.length === 1) {
                setProgress(0);
                const config = {
                    onUploadProgress: progressEvent => {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress(percentCompleted);
                    }
                }

                let formData = new FormData();
                formData.append('image', mediaInputRef.current.files[0]);
                let response = await BackendService.post('/media/', formData, config);

                setTypeMessage('success')
                setResultMessage('Media ' + response.data.id + ' created with success' );
                notify();
            } else {
                setTypeMessage('error')
                setResultMessage('No file selected');
            }
        } catch (error) {
            if(error.response !== undefined) {
                setTypeMessage('error')
                setResultMessage(error.response.data.message);
            } else {
                setTypeMessage('error')
                setResultMessage(error.message);
            }
        }
    }

    return (
        <SectionBox title="Create media">
            <form onSubmit={onSubmitHandler}>
                <Message message={resultMessage} type={typeMessage} />

                <span className="form-input">
                    <span>Media</span>

                    <input className="file-upload-input" type="file" name="file" ref={mediaInputRef} accept=".jpg,.png" />
                    <FileUploader hiddenInput={mediaInputRef} progress={progress} />
                </span>
                
                <button type="submit">Upload</button>
            </form>
        </SectionBox>
    );
}

export default MediaForm;