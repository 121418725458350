import React, { useEffect, useState } from "react";
import { Space, TextInput } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";

export function SearchBox({onSearch, size = "md", placeholder = "Procurar por nome"}) {

    const [searchValue, setSearchValue] = useState(undefined);
    const [debouncedSearchValue] = useDebouncedValue(searchValue, 1000);

    useEffect(() => {
        if(onSearch)
            onSearch(debouncedSearchValue);
    // eslint-disable-next-line
    }, [debouncedSearchValue])

    return (
        <>
            <TextInput
                label=""
                placeholder={placeholder}
                size={size}
                value={searchValue ? searchValue : ''}
                onChange={(e) => {
                    setSearchValue(e.target.value);
                }}
            />

            <Space h="lg" />
        </>
    )
}