import { ActionIcon } from '@mantine/core';
import React from 'react';
import {  Check, Trash, X } from 'tabler-icons-react';
import BackendServiceV2 from 'services/v2/BackendService';
import { useMutation, useQueryClient } from 'react-query';
import { showNotification } from '@mantine/notifications';

export function TotemRegionDeleteButton({region, setSelectedRegion}) {

    const queryClient = useQueryClient();

    const {mutate: deleteRegion} = useMutation(() => {
        return BackendServiceV2.delete('/totem/page/content/region/' + region.id)
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('totem-data');
            showNotification({
                title: 'Região removida com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
            setSelectedRegion(undefined);
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (
        <ActionIcon title="Remover região" onClick={deleteRegion} color="#ff5e00"><Trash /></ActionIcon>
    );
}