import { ActionIcon, Avatar, Button, Center, Group, Stack, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Logout, PlugConnected, X } from "tabler-icons-react";

export function SalesTeamOnlineUserList({salesTeam}) {

    const queryClient = useQueryClient()

    const {data: onlineUsers} = useQuery('team-sales-online-users', () => {
        return BackendServiceV2.get('/salesteam/' + salesTeam.id + '/users');
    })

    const setUserOffline = useMutation(idUser => {
        return BackendServiceV2.post(`/salesteam/${salesTeam.id}/user/${idUser}/status`);
    }, {
        onSuccess: (response) => {
            showNotification({
                title: <>Usuário deslogado com sucesso</>,
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            });

            queryClient.invalidateQueries('team-sales-online-users');
            queryClient.invalidateQueries('sales-team-online-status');
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            });        
        }
    })

    const setAllUsersOffline = useMutation(() => {
        return BackendServiceV2.post(`/salesteam/${salesTeam.id}/user/status`);
    }, {
        onSuccess: (response) => {
            showNotification({
                title: <>Todos os usuários foram deslogado com sucesso</>,
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            });

            queryClient.invalidateQueries('team-sales-online-users');
            queryClient.invalidateQueries('sales-team-online-status');
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            });        
        }
    })

    return (
        <SectionBox title="Online agora" icon={<PlugConnected />}>
            {onlineUsers && onlineUsers.length === 0 && <Center>Nenhum usuário online</Center>}

            <Stack spacing={5}>
                {onlineUsers && onlineUsers.map((user) => (
                    <Group noWrap key={user?.id} position="apart">
                        <Group>
                            <Avatar src={user?.profilePicture?.webp ? window.ENV.StaticURL + user?.profilePicture?.webp : null}>
                                {user.name.split(' ').map((letter, i) => (
                                    i < 2 ? letter.charAt(0).toUpperCase() : ''
                                ))}
                            </Avatar>
                            <Stack spacing={0}>
                                <Text size="sm">{user.name}</Text>
                            </Stack >
                        </Group>
                        <ActionIcon 
                            title="Definir como Offline"
                            onClick={e => setUserOffline.mutate(user.id)}>
                            <Logout />
                        </ActionIcon>
                    </Group>
                ))}

                <Button variant="subtle" onClick={e => setAllUsersOffline.mutate()}>Definir todos como offline</Button>
            </Stack>
        </SectionBox>
    )
}