import { Center, Pagination, Space } from "@mantine/core";
import { Grid } from "components/v2/Grid";
import { SectionBox } from "components/v2/SectionBox";
import { UserContext } from "context/User/UserContext";
import React, { useContext, useState } from "react";
import { CalendarEvent } from "tabler-icons-react";
import { SalesTeamLeadCard } from "../SalesTeamLeadCard";
import dayjs from 'dayjs';
import { useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";

export function SalesTeamLeadScheduleList({salesTeam, userData, notAssignedOnly = false, assignedOnly = false, onDate = undefined}) {

    // USER DATA
    const userContext = useContext(UserContext);

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // LEADS
    const [currentPage, setCurrentPage] = useState(1);

    const {data: schedules} = useQuery(['sales-team-leads', currentPage, assignedOnly, onDate], () => {
        let requestURL = '/salesteam/' + salesTeam.id + '/leads/?page=' + (currentPage - 1) + '&status=SCHEDULED';
        
        if(assignedOnly)
            requestURL += '&assignedUser=' + userContext.loggedUserData.id;
        
        if(notAssignedOnly)
            requestURL += '&assignedUser=IS_NULL';

        if(onDate)
            requestURL += '&onDate=' + onDate;
        
        return BackendServiceV2.get(requestURL);
    });

    // SECTION LABEL
    function getSectionLabel() {
        if(assignedOnly)        
            return 'Agendamentos'

        if(notAssignedOnly)
            return 'Agendamentos pendentes'

        if(onDate)
            return dayjs(onDate).locale('pt-br').format('dddd, D [de] MMMM [de] YYYY');

        return 'Agendamentos'
    }

    return (
        <SectionBox
            fullWidth
            title={getSectionLabel()}
            icon={<CalendarEvent />}
        >
            {schedules && schedules.result.length === 0 && <Center>Nenhum agendamento encontrado</Center>}

            <Grid>
                {schedules && schedules.result.map(lead => (
                    <SalesTeamLeadCard key={lead.id} lead={lead} userData={userData} />
                ))}
            </Grid>

            {schedules && schedules.result.length > 0 && <>
                <Space h="lg" />
                <Pagination 
                    value={schedules.pageNumber + 1} 
                    onChange={(page) => {setCurrentPage(page); queryClient.invalidateQueries('sales-team-leads')}} 
                    total={schedules.totalPages} 
                />
            </>}
        </SectionBox>
    )
}