import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ResourcePermissionContext } from 'context/Permission/ResourcePermissionContext';
import { PermissionMenu } from 'components/v2/PermissionMenu';
import { ContentWrapper, DashboardContent, LeftContent, RightContent } from 'components/v2/Dashboard/Layout';
import { BuildingStore } from 'tabler-icons-react';
import { Badge, Group, LoadingOverlay } from '@mantine/core';
import BackendServiceV2 from 'services/v2/BackendService';
import { useQuery } from 'react-query';
import { Error404 } from 'components/v2/Dashboard/404';
import { CompanyEdit } from 'components/v2/Company/CompanyEdit';
import { DevelopmentList } from 'components/v2/Development/DevelopmentList';
import { CompanyDeleteButton } from 'components/v2/Company/CompanyDeleteButton';
import DevelopmentCreate from 'components/v2/Development/DevelopmentCreate';

export function CompanyEditPage() {

    // PARAMS
    const idCompany = useParams().idCompany;

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    useEffect(() => {
        resourcePermission.setResource('COMPANY', idCompany);
    // eslint-disable-next-line
    }, [idCompany]);

    // COMPANY
    const {data: company, isLoading, isError} = useQuery(['company-data', idCompany], () => {
        return BackendServiceV2.get(`/company/${idCompany}`);
    });

    // LOADING
    if(isLoading) return (
        <DashboardContent>
            <LoadingOverlay visible={isLoading} />
        </DashboardContent>
    )

    if(isError) return (
        <Error404 />
    )

    return (
        <DashboardContent 
            resourceId={company?.idCompany}
            resourceClass="COMPANY"
            title={company?.name} 
            icon={<BuildingStore size={30} />}
            description={
                <Group spacing={3}>
                    <Badge variant='outline'>{company?.idCompany}</Badge>
                </Group>
            }
            right={<>
                {resourcePermission.containsGroup(['ADMIN']) && <>
                    <CompanyDeleteButton company={company} />
                </>}
                <PermissionMenu resourceClass='COMPANY' resourceId={idCompany} />
            </>}
        >
            <ContentWrapper>
                <LeftContent>
                    {resourcePermission.containsPermission('COMPANY_UPDATE') &&
                        <CompanyEdit company={company} />
                    }

                    {resourcePermission.containsPermission('DEVELOPMENT_READ') &&
                        <DevelopmentList idCompany={company.idCompany} />
                    }
                </LeftContent>

                <RightContent>
                    {resourcePermission.containsPermission('DEVELOPMENT_CREATE') &&
                        <DevelopmentCreate company={company}  />
                    }
                </RightContent>
            </ContentWrapper>
        </DashboardContent>
    );
}