import React, {useEffect, useState} from 'react';
import BackendService from '../../services/BackendService';
import 'aframe';

import './style.css';

function MediaPlayer({ match }) {

    const [path, setPath] = useState(undefined);
    
    useEffect(() => {
        async function getImage() {
            let response = await BackendService.get('/media/' + match.params.media);
            setPath(window.ENV.StaticURL + response.data.path + '?t=' + new Date().getTime());
        }

        getImage();
    }, [match.params.media]);

    async function openFullscreen() {
        let elem = document.documentElement;

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
    }

    async function closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE/Edge */
            document.msExitFullscreen();
        }
    }

    async function switchFullscreen() {        
        if (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement
        ) {
            closeFullscreen();
        } else {
            openFullscreen();
        }
    }

    return (
        <>
            {path ? (
                <>
                    <button className="player-button" onClick={switchFullscreen}>FULLSCREEN</button>
                    <a-scene vr-mode-ui="false" loading-screen="enabled: false">
                        <a-assets>
                            <img id="skybox" src={path} alt="360 VR" crossOrigin="anonymous" />
                        </a-assets>

                        <a-sky src="#skybox"></a-sky>
                    </a-scene>
                </>
            ) : (
                ''
            )}
            
        </>
    );
}

export default MediaPlayer;