function maskPhone(phone) {
    let maskedPhone = '';

    if(phone.length > 2) {
        maskedPhone = '(' + phone.slice(0, 2) + ') ';
    } else {
        return phone;
    }

    let secondPart = phone.slice(2);

    if(secondPart.length === 4) {
        maskedPhone = maskedPhone + secondPart;
    } else if (secondPart.length > 4 && secondPart.length < 9) {
        maskedPhone = maskedPhone + secondPart.slice(0, 4) + '-' + secondPart.slice(4);
    } else if (secondPart.length === 9) {
        maskedPhone = maskedPhone + secondPart.slice(0, 5) + '-' + secondPart.slice(5);
    } else {
        maskedPhone = maskedPhone + secondPart;
    }

    return maskedPhone;
}

export function valueOf(input) {
    return input && input ? maskPhone(input) : '';
}

export function updateValue(inputValue, setValue, name) {
    let value = inputValue.replaceAll('(', '');
    value = value.replaceAll(')', '');
    value = value.replaceAll(' ', '');
    value = value.replaceAll('-', '');
        
    if(!/^\d+$/.test(value) && value.length !== 0)
        return;

    if(name) {
        setValue(oldValue => {
            return {...oldValue, [name] : value}
        });
    } else {
        setValue(value);
    }
}
