import { ActionIcon, Stack } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { ArrowLeft, Check, X } from "tabler-icons-react";
import { DndContext, closestCenter,KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove,SortableContext,sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { DraggrableCard } from "components/v2/Draggrable/DraggrableCard";
import Scrollbars from "react-custom-scrollbars-2";
import { restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers";
import { TeleportCarouselAddEntryButton } from "../TeleportCarouselAddEntryButton";
import BackendServiceV2 from "services/v2/BackendService";
import { useMutation, useQueryClient } from "react-query";
import { showNotification } from "@mantine/notifications";
import { TeleportCarouselEntriesDeleteButton } from "../TeleportCarouselEntriesDeleteButton";
import { FixedHeightLeftContent } from "components/v2/Dashboard/Layout";
import { TeleportCarouselEditEntryButton } from "../TeleportCarouselEditEntryButton";

export function TeleportCarouselEntries({group, setActiveGroup}) {

    const queryClient = useQueryClient();

    // UPDATE ORDER
    const {mutate: updateCarouselEntryOrder} = useMutation((data) => {
        console.log("SETTING TO ORDER " + data.order);
        return BackendServiceV2.put(`/carousel/group/entry/${data.idCarouselEntry}`, {
            order: data.order
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-data');
            
            showNotification({
                title: 'Teleport atualizado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 2000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 2000,
            });
        }
    })

    // DRAG 
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    )

    const [sortableItems, setSortableItems] = useState(group?.entries?.map(entry => (entry?.id)))

    useEffect(() => {
        setSortableItems(group?.entries?.map(entry => (entry?.id)));
    }, [group?.entries])

    function onDragEnd(event) {
        const {active, over} = event;

        if (active.id !== over.id) {
            setSortableItems((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);
                return arrayMove(items, oldIndex, newIndex);
            });

            updateCarouselEntryOrder({idCarouselEntry: active.id, order: over.data.current.sortable.index + 1});
        }
    }

    if(!group || !sortableItems) return <></>

    return (
        <FixedHeightLeftContent
            title={group?.title}
            left={<ActionIcon color="#ff5e00" style={{alignSelf: 'center'}} onClick={e => setActiveGroup(undefined)}><ArrowLeft /></ActionIcon>}
            right={<TeleportCarouselAddEntryButton group={group} />}
        >
            <DndContext collisionDetection={closestCenter} sensors={sensors} onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}>
                <SortableContext items={sortableItems}>
                    <Scrollbars height="100%">
                        <Stack>
                            {sortableItems?.map((id) => {
                                let entry = group?.entries?.find(entry => entry?.id === id);

                                if(!entry) return <></>

                                return (
                                    <DraggrableCard 
                                        key={entry.id}
                                        id={entry.id}
                                        title={entry.title}
                                        image={window.ENV.StaticURL + entry.thumbWebp}
                                        actions={[
                                            <TeleportCarouselEditEntryButton entry={entry} />,
                                            <TeleportCarouselEntriesDeleteButton entry={entry} />
                                        ]}
                                    />
                                )
                            })}
                        </Stack>
                    </Scrollbars>
                </SortableContext>
            </DndContext>
        </FixedHeightLeftContent>
    )
}