import axios from 'axios';

let BackendServiceV2 = axios.create({
    baseURL: window.ENV.BackendURL
});

BackendServiceV2.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    if(error && error.response)
        console.log(error.response.data.message);

    return Promise.reject(error);
});

BackendServiceV2.interceptors.request.use(requestConfig => { 
    if (requestConfig.baseURL === window.ENV.BackendURL && !requestConfig.headers.Authorization && localStorage.getItem('accessToken') != null) {
        requestConfig.headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
    }
    
    return requestConfig;
});

export default BackendServiceV2;