import React from 'react';
import { BuildingCommunity } from 'tabler-icons-react';
import { ContentWrapper, DashboardContent, LeftContent, RightContent } from 'components/v2/Dashboard/Layout';
import { DevelopmentList } from 'components/v2/Development/DevelopmentList';

export function DevelopmentListPage() {

    return (
        <DashboardContent title="Empreendimentos" icon={<BuildingCommunity size={30} />}>
            <ContentWrapper>
                <LeftContent>
                    <DevelopmentList />
                </LeftContent>

                <RightContent>
                    
                </RightContent>
            </ContentWrapper>
        </DashboardContent>
    );
}