import { LoadingOverlay, Pagination, Space } from "@mantine/core";
import { DevelopmentCard } from "components/v2/Development/DevelopmentCard";
import { SearchBox } from "components/v2/SearchBox";
import { SectionBox } from "components/v2/SectionBox";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { BuildingCommunity } from "tabler-icons-react";

export function DevelopmentList({idCompany = null}) {

    const queryClient = useQueryClient();

    // TELEPORT LIST
    const [currentPage, setCurrentPage] = useState(1);
    const [title, setTitle] = useState(undefined);

    useEffect(() => {
        setCurrentPage(0);
    }, [title]);

    const {data: developments, isLoading} = useQuery(['development-list-data', currentPage, title, idCompany], () => {
        let url = `/development/?page=${currentPage}`;
        
        if(idCompany) {
            url = `/company/${idCompany}/development?page=${currentPage}`
        }
        
        if(title) {
            url += `&name=${title}`
        }

        return BackendServiceV2.get(url);
    });

    return (
        <SectionBox 
            title="Empreendimentos" 
            icon={<BuildingCommunity />}
        >
            <LoadingOverlay visible={isLoading} />

            <SearchBox onSearch={setTitle} />

            {developments?.result?.map(development => (
                <DevelopmentCard key={development.idDevelopment} development={development} />
            ))}

            {developments?.result?.length > 0 && <>
                <Space h="lg" />
                <Pagination 
                    value={developments?.pageNumber + 1} 
                    onChange={(page) => {setCurrentPage(page - 1); queryClient.invalidateQueries('development-list-data')}} 
                    total={developments?.totalPages} 
                />
            </>}
        </SectionBox>
    )
}