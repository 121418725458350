import axios from 'axios';

let AuthService = axios.create({
    baseURL: window.ENV.AuthURL
});

AuthService.interceptors.request.use(requestConfig => { 
    if (requestConfig.baseURL === window.ENV.AuthURL && !requestConfig.headers.Authorization && localStorage.getItem('accessToken') != null) {
        requestConfig.headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
    }

    return requestConfig;
});

export default AuthService;