import { SectionBox } from "components/v2/SectionBox";
import React from "react";
import { useQuery } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { RenderFarmCard } from "../RenderFarmCard";
import { LoadingOverlay, SimpleGrid } from "@mantine/core";

export function RenderFarmList() {

    const {data: virtualmachines, isLoading} = useQuery(['renderfarm-virtualmachines-list-data'], () => {
        return BackendServiceV2.get('/renderfarm/machines');
    });

    return (
        <SectionBox title="Render Farm" className="full-width">
            <LoadingOverlay visible={isLoading} />

            <SimpleGrid cols={3}>
                {virtualmachines?.map(vm => (
                    <RenderFarmCard key={vm.id} vm={vm} />
                ))}
            </SimpleGrid>
        </SectionBox>
    )
}