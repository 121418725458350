import { Button, Group, Image, Menu, Modal, Stack, TextInput, Title } from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import UploadArea from "components/v2/UploadArea";
import { PlayerContext } from "context/Player/PlayerContext";
import React, { useContext, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Edit, Plus, X } from "tabler-icons-react";

export function TeleportPhotoEditButton({photo}) {

    const queryClient = useQueryClient();

    const playerContext = useContext(PlayerContext);

    const [opened, openedHandler] = useDisclosure(false);

    const form = useForm({
        initialValues: {
            subtitle: photo.subtitle
        }
    });

    const uploadArea = useRef(null);

    const {mutate: createPhotoMetadata} = useMutation((data) => {
        return BackendServiceV2.put('/photo/' + photo.idPhoto, data);
    }, {
        onSuccess: (response) => {
            if(uploadArea.current.hasFile()) {
                uploadArea.current.upload({
                    mutate: async (files, config) => {
                        let formData = new FormData();
                        formData.append('data', files[0]);
    
                        return BackendServiceV2.put('/photo/' + response.idPhoto + '/image', formData, config)
                    },
                    success: () => {
                        queryClient.invalidateQueries('teleport-data');
                        playerContext?.player?.reload();

                        showNotification({
                            title: 'Imagem salva com sucesso',
                            message: '',
                            icon: <Check />,
                            color: 'green',
                            autoClose: 5000,
                        });
                    },
                    error: (error) => {
                        showNotification({
                            title: 'Um erro ocorreu',
                            message: error?.response?.data?.message,
                            icon: <X />,
                            color: 'red',
                            autoClose: 5000,
                        });
                    }
                });
            } else {
                queryClient.invalidateQueries('teleport-data');
                playerContext?.player?.reload();

                showNotification({
                    title: 'Imagem atualizada com sucesso',
                    message: '',
                    icon: <Check />,
                    color: 'green',
                    autoClose: 5000,
                });
            }
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (<>
        <Menu.Item icon={<Edit />} onClick={openedHandler.open}>Editar</Menu.Item>

        <Modal
            opened={opened}
            onClose={openedHandler.close}
            title={
                <Group><Plus /><Title order={2}>Atualizando {photo.subtitle}</Title></Group>
            }
            centered
            size="lg"
        >
            <form id="general-teleport-data-form" onSubmit={form.onSubmit(values => createPhotoMetadata(values))}>
                <Stack style={{width: '100%'}}>
                    <Image
                        src={window.ENV.StaticURL + photo.path}
                        height={250}
                    />
                    <TextInput
                        required
                        label="Title"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('subtitle')}
                    />

                    <UploadArea 
                        rejectMessage="Erro"
                        maxSize={1024 * 5}
                        accept={[MIME_TYPES.jpeg, MIME_TYPES.png]}
                        multiple={false}
                        ref={uploadArea}
                    />

                    <Button type="submit">Salvar</Button>
                </Stack>
            </form>
        </Modal>
    </>)
}