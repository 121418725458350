import { ActionIcon, Avatar, Badge, Center, Group, Pagination, Space, Stack, Text } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import React, {useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AuthService from "services/AuthService";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, DoorEnter, Lock, Mail, Trash, X } from "tabler-icons-react";

export function UserGroupList({resourceClass, resourceId, invalidateQueryName}) {
    
    // QUERY CLIENT
    const queryClient = useQueryClient();

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    // CLIPBOARD
    const clipboard = useClipboard({ timeout: 500 });

    // GET USERS WITH PERMISSIONS ON RESOURCE
    const [currentPage, setCurrentPage] = useState(1);

    const {data: userWithGroup} = useQuery(['sale-team-user-with-access', currentPage], () => {
        return BackendServiceV2.get('/resource/group?page=' + (currentPage - 1), {
            params: {resourceClass, resourceId}
        });
    }, { keepPreviousData : true });

    // REMOVE USER GROUP
    const mutation = useMutation(idUserGroup => {
        return BackendServiceV2.delete('/resource/group/user', {
            data: {
                idUserGroup, resourceClass, resourceId
            }
        });
    }, {
        onSuccess: (response) => {
            showNotification({
                title: 'Permissão excluída com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            });

            queryClient.invalidateQueries('sale-team-user-with-access');
            queryClient.invalidateQueries(invalidateQueryName);
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu ao carregar a lista de usuários',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    const resetPassword = useMutation(email => {
        return BackendServiceV2.post('/resource/group/user/reset', {
            email,
            resourceClass,
            resourceId
        });
    }, {
        onSuccess: (response) => {
            clipboard.copy(response?.data?.link);

            showNotification({
                title: 'Link copiado na área de transferencia',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            });
        },
        onError: (error) => {
            showNotification({
                title: error?.response?.data?.message,
                message: '',
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    const modals = useModals();

    const deleteLeadModal = (idUserGroup, email) => modals.openConfirmModal({
        title: 'Excluir permissão',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja excluir a permissão de {email}?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            mutation.mutate(idUserGroup);
        },
        zIndex: 1000
    });

    const resetPasswordModal = (email) => modals.openConfirmModal({
        title: 'Redefinir senha',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja gerar um link para redefinir a senha de {email}?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            resetPassword.mutate(email);
        },
        zIndex: 1000
    });

    // RESEND ONBOARD MAIL
    const resendOnboardMail = useMutation((email) => {
        return AuthService.post(`/account/${email}/onboard`);
    }, {
        onSuccess: data => {
            showNotification({
                title: 'Onboarding reenviado com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    const modal = useModals();

    const resendOnboardMailModal = (email) => modal.openConfirmModal({
        title: 'Você tem certeza que deseja reenviar o email de convite para ' + email + '?',
        children: (<></>),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: () => {
            resendOnboardMail.mutate(email);
        },
        zIndex: 1000
    });

    return (<>
        {userWithGroup?.result?.length === 0 && <Center>Nenhuma permissão adicionada</Center>}

        {userWithGroup?.result?.map((userGroup, i, data) => (
            <div key={userGroup.id}>
                <Group position="apart">
                    <Group noWrap>
                        <Avatar src={userGroup?.user?.profilePicture?.webp ? window.ENV.StaticURL + userGroup?.user?.profilePicture?.webp : null}>
                            {userGroup?.user?.name?.split(' ').map((letter, i) => (
                                i < 2 ? letter.charAt(0).toUpperCase() : ''
                            ))}
                        </Avatar>
                        <Stack spacing={0}>
                            <Group><span>{userGroup.user.name}</span>{userGroup.inherited && <Badge size="xs" variant="outline">Herdado</Badge>} <Badge>{userGroup.user?.enabled ? userGroup.group.name : 'PENDENTE'}</Badge></Group>
                            <span style={{color: "#a4a4a4"}}>{userGroup.user.email}</span>
                        </Stack >
                    </Group>

                    <Group>
                        {resourcePermission.containsPermission('ADD_USER_RESOURCE_GROUP') && !userGroup.user?.enabled && <>
                            <ActionIcon  
                                className="no-text" 
                                title="Reenviar email de onboarding"
                                onClick={e => resendOnboardMailModal(userGroup.user.email)}
                            ><Mail /></ActionIcon >

                            <ActionIcon  
                                className="no-text" 
                                component="a"
                                target="_blank"
                                href={"/onboard/" + userGroup.user?.id}
                                title="Onboarding"
                            ><DoorEnter /></ActionIcon >
                        </>}

                        {resourcePermission.containsPermission('RESET_PASSWORD_RESOURCE_GROUP') && userGroup.user?.enabled && <>
                            <ActionIcon 
                                title="Redefinir senha"
                                className="no-text" 
                                onClick={e => resetPasswordModal(userGroup.user.email)}
                            ><Lock /></ActionIcon>
                        </>}

                        {resourcePermission.containsPermission('REMOVE_USER_RESOURCE_GROUP') && !userGroup.inherited && 
                            <ActionIcon 
                                title="Remover permissão"
                                className="no-text" 
                                onClick={e => deleteLeadModal(userGroup.id, userGroup.user.email)}
                            ><Trash /></ActionIcon>
                        }
                    </Group>
                </Group>

                {(i + 1 !== data.length) ? <Space h="lg" /> : ''}
            </div>
        ))}

        {resourcePermission.containsPermission('READ_USER_RESOURCE_GROUP_LIST') && userWithGroup && userWithGroup.result.length > 0 && <>
            <Space h="lg" />
        
            <Pagination 
                value={userWithGroup && userWithGroup.pageNumber + 1} 
                onChange={(page) => {setCurrentPage(page); queryClient.invalidateQueries('sale-team-user-with-access')}} 
                total={userWithGroup && userWithGroup.totalPages} 
            />
        </>}
    </>);
}