import React, {useState, useEffect} from 'react';
import QueryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

import './style.css';

function Pagination({ totalPages, currentPageS, pathname, onPageChange, customQueryString = 'page' }) {

    const location = useLocation();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(currentPageS);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        onPageChangeHandler(currentPageS)
    // eslint-disable-next-line
    }, [currentPageS]);

    // Get current page from query string
    useEffect(() => {
        let queryString = QueryString.parse(location.search);
        if(queryString[customQueryString] !== undefined) {
            setCurrentPage(queryString[customQueryString] - 1);
        } else {
            setCurrentPage(0);
        }
    // eslint-disable-next-line
    }, [currentPage, location]);

    useEffect(() => {   
        onPageChange(currentPage);
    }, [currentPage, onPageChange]);

    // Generate list of pages from 1 to totalPages
    useEffect(() => {
        let pageGen = [];
        for(let i = 1; i <= totalPages; i++) {
            pageGen.push(i);
        }
        setPages(pageGen);
    }, [totalPages]);

    // Update current page
    function onPageChangeHandler(destinationPage) {
        if(destinationPage >= 0 && destinationPage < totalPages) {            
            navigate({
                pathname: pathname,
                search: `?${customQueryString}=` + (destinationPage + 1)
            })
        }
    }
    
    return (
    <div className="pagination">
        <button onClick={() => {onPageChangeHandler(currentPage - 1)}}>Previous</button>

        {pages.map(pageNumber => (
            <span key={pageNumber}>
                {pageNumber === (currentPage + 1) ? (
                    <button className="active">{pageNumber}</button>
                ) : (
                    <button onClick={() => {onPageChangeHandler(pageNumber - 1)}}>{pageNumber}</button>
                )}
            </span>
        ))}

        <button onClick={() => {onPageChangeHandler(currentPage + 1)}}>Next</button>
    </div>
    );
}

export default Pagination;