import { ActionIcon, Button, Card, Center, Group, Image, Menu, Modal, Pagination, Space, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { Grid } from "components/v2/Grid";
import { SectionBox } from "components/v2/SectionBox";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Building, Check, Dots, Plus, Trash, X } from "tabler-icons-react";
import { SalesTeamTeleportLinkList } from "../SalesTeamTeleportLinkList";

export function SalesTeamTeleportList({salesTeam}) {

    // QUERY CLIENT
    const queryClient = useQueryClient();

    // LINKED TELEPORTS
    const [currentPage, setCurrentPage] = useState(1);

    const {data: linkedTeleports} = useQuery(['sales-team-linked-teleports', currentPage], () => {
        return BackendServiceV2.get('/salesteam/' + salesTeam.id + '/teleport/?page=' + (currentPage - 1));
    })

    const unlinkTeleport = useMutation(idTeleport => {
        return BackendServiceV2.delete('/salesteam/teleport', {
            data: {idTeleport, idSalesTeam: salesTeam.id}
        });
    }, {
        onSuccess: success => {
            showNotification({
                title: <>Teleport removido</>,
                message: 'Teleport removido do time de vendas',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })

            queryClient.invalidateQueries('sales-team-linked-teleports');
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    // CONTROL MODAL OPENING
    const [opened, openedHandler] = useDisclosure(false);

    return (
        <SectionBox
            title="Teleports"
            icon={<Building />}
            right={<Button variant="subtle" leftIcon={<Plus />} className="no-text" onClick={openedHandler.open}></Button>}
        >
            <Modal
                opened={opened}
                onClose={openedHandler.close}
                title={<Group><Building /><Title order={2}>Gerenciar Teleports</Title></Group>}
                centered
                size="lg"
            >
                <SalesTeamTeleportLinkList salesTeam={salesTeam} />
            </Modal>

            {linkedTeleports && linkedTeleports.result.length === 0 && <Center>Nenhum teleport adicionado</Center>}

            <Grid>
                {linkedTeleports && linkedTeleports.result.map(teleport => (
                    <Card withBorder key={teleport.id}>
                        {teleport?.personalization?.loadBackground?.webp &&
                            <Card.Section>
                                <Image src={window.ENV.StaticURL + teleport.personalization.loadBackground.webp} height={160} />
                            </Card.Section>
                        }

                        {teleport?.personalization?.loadBackground?.webp && <Space h="md" />}

                        <Group position="apart" noWrap>
                            <Text weight={600} lineClamp={1} >{teleport.metadata.title}</Text>
                                <Menu>
                                    <Menu.Target>
                                        <ActionIcon><Dots /></ActionIcon>
                                    </Menu.Target>

                                    <Menu.Dropdown>
                                        <Menu.Item 
                                            icon={<Trash />} 
                                            onClick={e => {
                                                e.preventDefault(); 
                                                unlinkTeleport.mutate(teleport.id);
                                            }}
                                        >Remover</Menu.Item>
                                    </Menu.Dropdown>
                                </Menu>
                        </Group>

                        <Space h="md" />
                        <Group grow>
                            <Button component="a" href={window.ENV.BaseURL + '/teleport/' + teleport.id} target="_blank">Editar</Button>
                            <Button component="a" href={window.ENV.BaseURL + '/tour/' + teleport.id} target="_blank">Abrir</Button>
                        </Group>
                        
                    </Card>
                ))}
            </Grid>

            {linkedTeleports && linkedTeleports.result.length > 0 && <>
                <Space h="lg" />
                <Pagination 
                    value={linkedTeleports.pageNumber + 1} 
                    onChange={(page) => {setCurrentPage(page); queryClient.invalidateQueries('sales-team-linked-teleports')}} 
                    total={linkedTeleports.totalPages} 
                />
            </>}
        </SectionBox>
    )    
}