import React from 'react';
import { Building } from 'tabler-icons-react';
import { ContentWrapper, DashboardContent, LeftContent, RightContent } from 'components/v2/Dashboard/Layout';
import { TeleportList } from 'components/v2/Teleport/TeleportList';

export function TeleportListPage() {
    return (<>
        <DashboardContent title="Teleports" icon={<Building size={30} />}>
            <ContentWrapper>
                <LeftContent>
                    <TeleportList />
                </LeftContent>

                <RightContent>
                    
                </RightContent>
            </ContentWrapper>
        </DashboardContent>
    </>);
}