import { Button } from "@mantine/core";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { PhoneCall } from "tabler-icons-react";

export function SalesTeamOnlineButton({salesTeam}) {

    const queryClient = useQueryClient();

    const {data: onlineStatus} = useQuery('sales-team-online-status', () => {
        return BackendServiceV2.get("/salesteam/" + salesTeam.id + "/status");
    })
    
    async function changeStatus(status) {
        await BackendServiceV2.post('/salesteam/' + salesTeam.id + '/status', {status})
        queryClient.invalidateQueries('sales-team-online-status')
        queryClient.invalidateQueries('team-sales-online-users')
    }

    return (<>
        {onlineStatus && onlineStatus.status ? 
            <Button leftIcon={<PhoneCall />} onClick={e => {e.preventDefault(); changeStatus(false)}} color="green">Online</Button>
        : 
            <Button leftIcon={<PhoneCall />} onClick={e => {e.preventDefault(); changeStatus(true)}} color="red">Offline</Button>
        }
    </>)
}