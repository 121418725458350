import React, { useState, useEffect } from 'react';
import AuthService from '../../services/AuthService';
import FullPageWidget from '../../components/FullPageWidget';
import Message from '../../components/Message';
import { useParams } from 'react-router-dom';
import OnboardForm from 'components/v2/Onboard/OnboardForm';

function Onboard() {
    
    const params = useParams();

    const [email, setEmail] = useState(undefined);
    const [errorMessage] = useState(undefined);

    useEffect(() => {
        async function getCodeDetails() {
            try {
                let response = await AuthService.get('/account/onboard/' + params.code);
                setEmail(response.data.email);
            } catch (error) {
                window.location.replace(window.ENV.BaseURL);
            }
        }

        getCodeDetails();
    }, [params.code]);

    return (
        <FullPageWidget>
            {email === undefined ? <Message message={errorMessage} type="error" /> : <OnboardForm code={params.code} email={email} /> }
        </FullPageWidget>
    );
}

export default Onboard;