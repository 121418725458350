import { Stack, Text } from "@mantine/core";
import React from "react";
import { useQuery } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Tags } from "tabler-icons-react";
import { SectionBox } from "../../SectionBox";
import { TagsManager } from "../TagsManager";
import { TagValueCard } from "../TagValueCard";

export function TagsEdit({resourceClass, idResource}) {

    const {data: categories} = useQuery(['metadata-category-list-data', resourceClass], () => {                
        return BackendServiceV2.get('/resource/metadata/category?type=' + resourceClass);
    });

    const {data: values, isLoading} = useQuery(['metadata-values-list-data', resourceClass, idResource], () => {                
        return BackendServiceV2.get(`/resource/metadata/value?resourceClass=${resourceClass}&idResource=${idResource}`);
    });

    return (<>
        <SectionBox
            icon={<Tags />}
            title="Tags"
            right={
                <TagsManager type={resourceClass} />
            }
            isLoading={isLoading}
        >
            <Stack>
                {categories?.map(category => (<>
                    <Text
                        key={category.id}
                        weight={600}
                        size="lg"
                    >
                        {category.name}
                    </Text>

                    <Stack>
                        {category?.metadata?.map((tag) => (
                            <TagValueCard key={tag.id} tag={tag} values={values} resourceClass={resourceClass} idResource={idResource}  />
                        ))}
                    </Stack>
                </>))}
            </Stack>
        </SectionBox>
    </>)
}