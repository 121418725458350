import React, {useContext, useEffect} from 'react';
import equal from'fast-deep-equal';
import { useMutation, useQueryClient } from 'react-query';
import BackendServiceV2 from 'services/v2/BackendService';
import { showNotification } from '@mantine/notifications';
import { Check, InfoCircle, X } from 'tabler-icons-react';
import { useDebouncedValue } from '@mantine/hooks';
import { SectionBox } from 'components/v2/SectionBox';
import { Button, Group, HoverCard, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ResourcePermissionContext } from 'context/Permission/ResourcePermissionContext';
import { PlayerContext } from 'context/Player/PlayerContext';

export function TeleportScanEdit({teleport}) {
    
    const queryClient = useQueryClient();

    const resourcePermission = useContext(ResourcePermissionContext);

    const playerContext = useContext(PlayerContext);

    const form = useForm({
        initialValues: {
            metadata: teleport?.metadata,
            embed: teleport?.embed || ''
        },
        validate: {
            metadata: {
                title: (value) => (!value ? 'Título não pode ser vazio' : null),
                internalName: (value) => (!value ? 'Nome interno não pode ser vazio' : null),
            }
        }
    });

    const {mutate: updateTeleport, isLoading} = useMutation((data) => {
        return BackendServiceV2.put('/teleport/' + teleport.id, data)
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-data');
            playerContext?.player?.reload();
            
            showNotification({
                title: 'Teleport atualizado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const [debouncedFormValues] = useDebouncedValue(form.values, 3000);

    useEffect(() => {
        form.validate();

        if(!form.isValid()) return;

        if(!equal({metadata: teleport.metadata, embed: teleport.embed}, debouncedFormValues))
            updateTeleport(debouncedFormValues);
    // eslint-disable-next-line
    }, [debouncedFormValues])

    return (
        <SectionBox
            title="Informações" 
            description="Informações sobre o seu Teleport" 
            className="full-width"
            isLoading={isLoading}
            right={(
                <Button form="general-teleport-data-form" type="submit">Salvar</Button>
            )}
        >
            <form id="general-teleport-data-form" onSubmit={form.onSubmit(values => updateTeleport(values))}>
                <Stack spacing={15}>
                    <TextInput
                        required
                        label="Título"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('metadata.title')}
                    />

                    <TextInput
                        label="Subtítulo"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('metadata.subtitle')}
                    />

                    <TextInput
                        label="Embed"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('embed')}
                    />

                    {resourcePermission.containsGroup(['ADMIN']) && <>
                        <TextInput
                            label={
                                <Group style={{position: 'relative'}}>
                                    Parâmetros da URL
                                    <HoverCard width={280} shadow="md">
                                        <HoverCard.Target>
                                            <InfoCircle />
                                        </HoverCard.Target>
                                        
                                        <HoverCard.Dropdown>
                                            <Text size="sm">Para mais informações sobre parâmetros Matterport, <a href="https://support.matterport.com/s/article/URL-Parameters?language=en_US" target="_blank" rel="noreferrer">clique aqui</a></Text>
                                        </HoverCard.Dropdown>
                                    </HoverCard>
                                </Group>
                            }
                            placeholder=""
                            size="md"
                            {...form.getInputProps('metadata.urlParameters')}
                        />

                        <TextInput
                            required
                            label="Nome interno"
                            placeholder=""
                            size="md"
                            {...form.getInputProps('metadata.internalName')}
                        />
                    </>}
                </Stack>
            </form>
        </SectionBox>
    );
}